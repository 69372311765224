// import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import DoctorProfileNavigation from './components/doctorAppointmentManagerNavigation/DoctorAppointmentManagerNavigation';
import ErrorBoundary from '../common/components/errorBoundaries/ErrorBoundary';
import PendingAppointments from './components/pendingAppointments/PendingAppointments';
import AppointmentHistory from './components/appointmentHistory/AppointmentHistory';
import create from 'zustand';

export const useStore = create((set) => ({
  personal: true,
  info: false,
  tabs: [{ name: 'Citas Pendientes' }, { name: 'Historial' }],
  selectedTabIndex: 1,
  setSelectedTabIndex: (index) => set({ selectedTabIndex: index })
}));

export default function DoctorAppointmentManager(props) {
  const { user } = props;
  const { selectedTabIndex } = useStore((state) => state);

  return (
    <ErrorBoundary>
      <div className="lg:grid lg:grid-cols-12 gap-4">
        <DoctorProfileNavigation />
        {selectedTabIndex === 1 && <PendingAppointments />}
        {selectedTabIndex === 2 && <AppointmentHistory />}
      </div>
    </ErrorBoundary>
  );
}
