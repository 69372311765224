export const CxWrap = ({ children }) => <div className="comm-box-shadow mt-10 rounded-[24px]">{children}</div>;

export const CxCard = ({ children }) => <div className="py-4 px-10 text-black	text-lg	font-semibold ">{children}</div>;

export const Line = () => <hr className=" border-black w-full border-tabs" />;

export const CxTabButton = ({ label, variant, disabled, onClick }) =>
  variant === 'outlined' ? (
    <button type="button" className="border border-dl-primary-900 rounded-lg   py-2 px-8 text-dl-primary-900 text-xs mr-4" onClick={onClick}>
      {label}
    </button>
  ) : (
    <button
      type="submit"
      disabled={disabled}
      className="bg-dl-primary-900 border border-transparent rounded-lg shadow-sm py-2 px-8 inline-flex justify-center text-xs font-medium text-white hover:bg-dl-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-700"
    >
      {label}
    </button>
  );
