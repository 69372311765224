import { CalendarIcon, CollectionIcon } from '@heroicons/react/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const patientAppointmentManagerNavigation = [{ name: 'Historial', href: '#', icon: CollectionIcon, current: true }];

export default function PatientProfileNavigation(props) {
  const { selectedItem, updateNavigation } = props;

  patientAppointmentManagerNavigation.forEach((element, index) => {
    patientAppointmentManagerNavigation[index].current = element.name === selectedItem;
  });

  const _onLinkClick = (event, tabName) => {
    event.preventDefault();
    updateNavigation(tabName);
  };

  return (
    <aside className="lg:col-span-2 bg-white side-bar-profile">
      <nav className="space-y-1 ml-3 mt-5">
        <div className="btn-sidebar flex flex-col gap-2 w-9/12 my-[50px] mx-auto">
          {patientAppointmentManagerNavigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              onClick={(e) => _onLinkClick(e, item.name)}
              className={classNames(item.current ? 'btn-primary' : 'btn-out-line', 'flex items-center justify-center !w-full')}
              aria-current={item.current ? 'page' : undefined}
            >
              <span className="truncate">{item.name}</span>
            </a>
          ))}
        </div>
      </nav>
    </aside>
  );
}
