import Axios from './Axios';
const API_URL = '/documents';

export const Documentos = {
  getCategories: () => Axios.get(API_URL + `/categories/list`),
  add: (values) => Axios.post(API_URL + `/add`, values),
  list: ({ id, usertype, idPatient }) => Axios.get(API_URL + `/list?idUser=${id}&userType=${usertype}&idPatient=${idPatient}`),
  delete: (id) => Axios.delete(API_URL + `/${id}`),
  rename: (values) => Axios.put(API_URL + '/rename', values),
  update: (values) => Axios.put(API_URL + '/update', values),
  url: (Key, Action) => Axios.get(API_URL + `/url?Key=${Key}&Action=${Action}`),
  getAppointmentHistory: () => Axios.get(`/appointment-user/history`)
};