import WhiteCardContainer from '../../../../common/components/containers/forms/WhiteCardContainer';

import FormFieldsContainer from '../../../../common/components/containers/forms/FormFieldsContainer';

import FormInputField from '../../../../common/components/formElements/FormInputField';
import HalfRow from '../../../../common/components/formElements/HalfRow';
import FullRow from '../../../../common/components/formElements/FullRow';
import FormTextArea from '../../../../common/components/formElements/FormTextArea';
import FormButton from '../../../../common/components/formElements/FormButton';
import FormFieldsFooter from '../../../../common/components/containers/forms/FormFieldsFooter';
import FormSelectDrop from '../../../../common/components/formElements/FormSelectDrop';
import CustomRedAlert from '../../../../common/components/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../../common/components/customSuccessModal/CustomSuccessModal';
import FormResetButton from '../../../../common/components/formElements/FormResetButton';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MotivesS } from '../../../services/DoctorProfile';
import ProfileFormHeader from '../../../../doctorUser/components/ProfileFormHeader/ProfileFormHeader';
import MotivesList from '../../../../doctorUser/components/doctorOwnProfile/motives/components/motivesList/MotivesList';
import { CxCard, CxTabButton, CxWrap, Line } from '../../../components/Sx';
import Loader from '../../../components/Loader';
const statusOptions = [
  { id: true, label: 'Activo' },
  { id: false, label: 'Inactivo' }
];

export default function Motives(props) {
  const [motives, setMotives] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorBody, setErrorBody] = useState('');
  const [successBody, setSuccessBody] = useState('');
  const [defaultValues, setDefaultValues] = useState({});
  const [selectedMotives, setSelectedMotives] = useState(null);
  // const isEditing = JSON.stringify(defaultValues) !== '{}';
  const [isEditing, setEditing] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { register, handleSubmit, formState, getValues, reset, setValue } = useForm(defaultValues);
  const { errors, isDirty } = formState;

  useEffect(() => {
    setIsLoading(true);
    MotivesS.getAllMotives()
      .then((res) => {
        setMotives(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });

    // setErrorMessage('No se pudo completar la solicitud de educación. Inténtelo de nuevo.');
  }, [refresh]);

  useEffect(() => {
    setIsLoading(true);

    if (selectedMotives) {
      setDefaultValues(selectedMotives);
      setEditing(true)
      reset(selectedMotives);
      setEditing(true)
      setValue('status', selectedMotives.status ? 'true' : 'false', { shouldValidate: true });
    }

    setIsLoading(false);
  }, [reset, selectedMotives, setValue]);

  const resetState = () => {
    setShowSuccessModal(false);
    setShowErrorModal(false);
    setErrorBody('');
    setSuccessBody('');
    setDefaultValues({});
    setSelectedMotives(null);
    setEditing(false);
    reset({ motiveName: '', motiveDescription: '' });
    setValue('status', '', { shouldValidate: true });
    setRefresh(!refresh);
  };

  const _createErrorMessage = (errorMessage) => {
    setShowErrorModal(true);
    setErrorBody(errorMessage);
    setEditing(false)
  };

  const closeErrorMessage = () => {
    setShowErrorModal(false);
    setErrorBody('');
  };

  const _createSuccessMessage = (errorMessage) => {
    setShowSuccessModal(true);
    setSuccessBody(errorMessage);
    // resetState()
  };

  const saveMotives = (data) => {
    if (!data) {
      _createErrorMessage('No se pudo obtener información válida para guardar. Inténtelo de nuevo.');
      return;
    }

    if (JSON.stringify(defaultValues) === '{}') {
      executeCreateMotives(data);
    } else {
      const adjustedData = { ...data, idMotive: data.id, status: data.status === 'true' };
      doUpdateMotives(adjustedData);
    }
  };

  const executeCreateMotives = async (newMotives) => {
    const createNewMotives = await MotivesS.createMotives(newMotives);
    if (createNewMotives) {
      _createSuccessMessage('¡Se ha guardado el registro con éxito!');
    }

  };

  const doUpdateMotives = async (updatedMotivesObject) => {
    const executeUpdatedMotives = await MotivesS.updateMotives(updatedMotivesObject);
    if (executeUpdatedMotives) {
      _createSuccessMessage('¡Se ha actualizado el registro con éxito!');
    }
  };

  const onCloseSuccessMessage = () => {
    resetState();
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <form onSubmit={handleSubmit(saveMotives)}>
        <CxWrap>
          <CxCard>
            <ProfileFormHeader title={!isEditing ? 'Nuevo Motivo' : 'Actualizar Motivo'} initialText={'Ingresa posibles motivos de porque un usuario quisiera tener una consulta contigo.'} />
          </CxCard>
          <Line />
          <CxCard>
            <div className="grid grid-cols-6 gap-6">
              <HalfRow>
                <FormInputField
                  identifier={'motiveName'}
                  label={'Título del motivo'}
                  type={'text'}
                  placeholder={'Ej. Primera consulta'}
                  helperText={'Requerido'}
                  registration={register('motiveName', { required: true })}
                  errorMessage={errors.motiveName ? 'Este campo es requerido' : null}
                />
              </HalfRow>
              <FullRow>
                <FormTextArea
                  label={'Detalle'}
                  identifier={'motiveDescription'}
                  rows={3}
                  placeholder={'Ej. Consulta inicial para descubrir posibles causas de consulta'}
                  helperText={'Requerido'}
                  registration={register('motiveDescription', { required: true })}
                  errorMessage={errors.motiveDescription ? 'Este campo es requerido' : null}
                />
              </FullRow>
              {isEditing && (
                <HalfRow>
                  <FormSelectDrop
                    label={'Estado'}
                    identifier={'status'}
                    options={statusOptions}
                    defaultValue={defaultValues.status}
                    helperText={'Requerido'}
                    registration={register('status', { required: true })}
                    errorMessage={errors.status ? 'Este campo es requerido' : null}
                  />
                </HalfRow>
              )}
            </div>
         
          </CxCard>

          <hr className="mt-2  pt-2 "></hr>
          <div className="flex justify-end gap-2  px-10 py-4">
              <CxTabButton label="Cancelar" variant="outlined" onClick={resetState} />
              <CxTabButton label={isEditing ? 'Actualizar' : 'Guardar'} disabled={isEditing && !isDirty} />
            </div>
        </CxWrap>
        {/* <div className="px-4 py-3  text-right sm:px-6">
        <FormResetButton onClick={resetState} label={'Cancelar'} />
        <FormButton label={isEditing ? 'Actualizar' : 'Guardar'} type={'submit'} disabled={isEditing && !isDirty} />
      </div> */}
        {showSuccessModal && <CustomSuccessModal title={'Acción ejecutada con éxito!'} bodyText={successBody} buttonText={'Cerrar'} buttonAction={onCloseSuccessMessage} open={showSuccessModal} onClose={onCloseSuccessMessage} />}
        {showErrorModal && <CustomRedAlert open={true} title={'Ocurrió un error.'} bodyText={errorBody} onClose={closeErrorMessage} />}
      </form>
      <div className="ml-1">
        <div className="ml-5">
          <ProfileFormHeader title={'Motivos existentes'}  fontFamily="font-aristotelica" />
        </div>
        {motives.length === 0 && <p className="ml-4 mt-4">No hay motivos para mostrar</p>}
        {motives.length > 0 && <MotivesList items={motives} onClick={setSelectedMotives} />}
      </div>
    </>
  );
}
