import { ChevronDownIcon, ChevronUpIcon, SearchIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import HalfRow from '../../../../../common/components/formElements/HalfRow';

export default function SimpleDataTable(props) {
  const { tableInstance } = props;
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    data,
    columns,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    // gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    state,
    setGlobalFilter
  } = tableInstance;

  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(state.globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="px-4 sm:px-6 lg:px-8 mb-10">
      <div className="mt-4 flex flex-col">
    
            <div className=" -mx-4  sm:-mx-6 lg:-mx-8 mb-10 ">
              <HalfRow>
                <label htmlFor={'search'} className="block text-sm font-medium text-dl-accent">
                  Buscador
                </label>
                <div className="relative  rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <SearchIcon className="h-5 w-5" />
                  </div>
                  <input
                    type="text"
                    name={'search'}
                    id={'search'}
                    className={`mt-1 block w-full border border-gray-300 rounded-full shadow-sm py-2 px-3 
                    focus:outline-none focus:ring-dl-primary-700 focus:border-dl-primary-700 sm:text-sm pl-10`}
                    placeholder={`Escriba para buscar en ${count} registros`}
                    value={value || ''}
                    onChange={(e) => {
                      setValue(e.target.value);
                      onChange(e.target.value);
                    }}
                  />
                </div>
              </HalfRow>
            </div>
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full ">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-[24px] table-top">
              <table {...getTableProps()} className="min-w-full divide-y divide-gray-300 table-gredient ">
                <thead className="bg-gray-50">
                  {
                    // Loop over the header rows
                    headerGroups.map((headerGroup) => (
                      // Apply the header row props
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {
                          // Loop over the headers in each row
                          headerGroup.headers.map((column) => (
                            // Apply the header cell props
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              <div className="inline-flex items-center">
                                {
                                  // Render the header
                                  column.render('Header')
                                }
                                {/* Add a sort direction indicator */}
                                <span>{column.isSorted ? column.isSortedDesc ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" /> : ''}</span>
                              </div>
                            </th>
                          ))
                        }
                      </tr>
                    ))
                  }
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white" {...getTableBodyProps()}>
                  {
                    // Loop over the table rows
                    page.map((row) => {
                      // Prepare the row for display
                      prepareRow(row);
                      return (
                        // Apply the row props
                        <tr {...row.getRowProps()}>
                          {
                            // Loop over the rows cells
                            row.cells.map((cell) => {
                              // Apply the cell props
                              return (
                                <td {...cell.getCellProps()} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {
                                    // Render the cell contents
                                    cell.render('Cell')
                                  }
                                </td>
                              );
                            })
                          }
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
              <nav className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6" aria-label="Pagination">
                <div className="hidden sm:block">
                  {!columns.some((col) => col.isSorted) && !state.globalFilter && page[0] ? (
                    <p className="text-sm text-gray-700">
                      Mostrando desde <span className="font-medium">{page[0].index + 1}</span> hasta <span className="font-medium">{page[page.length - 1].index + 1}</span> de un total de <span className="font-medium">{data.length}</span> resultados
                    </p>
                  ) : (
                    <p className="text-sm text-gray-700">
                      Página <span className="font-medium">{state.pageIndex + 1}</span> de <span className="font-medium">{pageCount}</span>
                    </p>
                  )}
                </div>
                <div className="flex flex-1 justify-between sm:justify-end">
                  <select
                    id="displayOptions"
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                    className="btn-table-3"
                  >
                    <option value="10">Mostrar 10</option>
                    <option value="20">Mostrar 20</option>
                    <option value="50">Mostrar 50</option>
                  </select>
                  <button type="button" onClick={() => previousPage()} disabled={!canPreviousPage} className="btn-table-1">
                    Anterior
                  </button>
                  <button type="button" onClick={() => nextPage()} disabled={!canNextPage} className="btn-table-1">
                    Siguiente
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
