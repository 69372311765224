import Axios from './Axios';

export const Appointment = {
  getAvailability: ({ doctorId, startDate, endDate }) => Axios.get(`search-doctors/get-availability-doctor/doctorId/${doctorId}/startDate/${startDate}/endDate/${endDate}`),
  getAppointment: () => Axios.get(`/appointment-doctor/history`),
  cancelAppointment: (values) => Axios.patch('/appointment-doctor/cancel-appointment', values),
  createAppointment: (values) => Axios.post(`appointment-user/create`, values),
  blockSlot: (values) => Axios.post(`appointment-doctor/block-slots`, values),
  allblockSlot: () => Axios.get(`appointment-doctor/all-blocked-slots`),
  createAppointmentfromDoctor: (values) => Axios.post(`appointment-doctor/create`, values)
};
