// import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import PatientProfileNavigation from './components/patientAppointmentManagerNavigation/PatientAppointmentManagerNavigation';
import ErrorBoundary from '../common/components/errorBoundaries/ErrorBoundary';
import AppointmentHistory from './components/appointmentHistory/AppointmentHistory';
import { useLocation } from 'react-router-dom';

export default function DoctorAppointmentManager(props) {
    const { user } = props;
    const location = useLocation()

    const [currentComponent, setCurrentComponent] = useState('Historial');

    // const navigate = useNavigate();
    // const sendToHome = () => navigate('/', { replace: true });

    return (
        <ErrorBoundary>
            <div className="lg:grid lg:grid-cols-12">
                <PatientProfileNavigation
                    selectedItem={currentComponent}
                    updateNavigation={(newComponent) => setCurrentComponent(newComponent)}
                />
                {currentComponent === 'Historial' && <AppointmentHistory page={location?.state?.data}/>}
            </div>
        </ErrorBoundary>
    );
}
