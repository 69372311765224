import { format, parseISO } from 'date-fns';
import { Link, useLocation } from 'react-router-dom';
import { docStore } from './SelectAppointmentMotiveDoctor';
import { LocationMarkerIcon } from '@heroicons/react/outline';
import { formatDate } from '../../../../common/utils/formattedData';

const AppointmentSuccessful = () => {
  const { state } = useLocation();
  const { selectedItem } = state;
  const { docData, setDocData } = docStore((state) => state);

  const parsedAppointment = parseISO(`${selectedItem.date}T${selectedItem.appTime}`);
  return (
    <main className="">
      <div className=" mt-10 mb-20 flex">
        <div className="relative max-w-3xl mx-auto ">
          <div className="bg-white rounded-lg px-40 pt-12 pb-10 border-r-40 my-6">
            {/* <h1 className="text-2xl font-semibold uppercase tracking-wide text-blue-600">¡Listo!</h1> */}
            <figure class="text-center flex justify-center mb-5">
              <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M29.42 58.008C45.4061 58.008 58.3654 45.0487 58.3654 29.0626C58.3654 13.0765 45.4061 0.117203 29.42 0.117203C13.4339 0.117203 0.474609 13.0765 0.474609 29.0626C0.474609 45.0487 13.4339 58.008 29.42 58.008ZM40.216 21.83C40.8236 21.0754 40.7045 19.9711 39.9498 19.3635C39.1952 18.7558 38.0909 18.875 37.4832 19.6296L25.253 34.8188L21.1375 29.7156C20.5293 28.9614 19.4249 28.8431 18.6707 29.4513C17.9166 30.0595 17.7982 31.1639 18.4064 31.9181L23.8885 38.7159C24.2217 39.129 24.724 39.3691 25.2547 39.3689C25.7855 39.3687 26.2876 39.1282 26.6204 38.7148L40.216 21.83Z"
                  fill="#00B3FF"
                ></path>
              </svg>
            </figure>
            <p className="color-custom font-bold text-3xl text-center">La cita ha sido agendada exitosamente</p>
            <p className="mt-2 text-base text-gray-500">.</p>
            <div className="flex justify-center mb-12">
              <Link to="/appointment-manager-doctor" className="rounded-full bg-sky-50 text-center py-3 px-6 color-custom">
                Ir a mis citas pendientes
              </Link>

              <Link to="/" className="bg-sky-400 py-3 px-12 text-center rounded-full text-white ml-5">
                Finalizar
              </Link>
            </div>

            <div className="text-center  bg-green-100 border-green-500 border-l-8 py-5 rounded-lg mb-10">
              <small className="text-green-600 text-base">Fecha seleccionada:</small>
              <p className="text-green-600 font-bold text-lg">
                {formatDate(selectedItem.date)} {','}
                <time className="font-bold" dateTime={parsedAppointment}>
                  {format(parsedAppointment, 'h:mm a')}
                </time>
              </p>
            </div>

            {/* {parsedAppointment !== null ? (
              <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mt-8" role="alert">
                <p>Fecha seleccionada:</p>
                <span className="font-bold">{`${new Date(selectedAppointment.date).toLocaleString('es', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`}</span> {','}
                <time className="font-bold" dateTime={parsedAppointment}>
                  {format(parsedAppointment, 'h:mm a')}
                </time>
              </div>
            ) : null} */}

            <div className="mb-4">
              <div className="grid grid-cols-2 gap-4">
                <div class="col-span-1 ... border-r border-black pr-5 ">
                  <figure className="border-4 h-20 w-20 rounded-full border-sky-500 bg-sky-50 m-auto mb-4">{/* <img src={docData.personalImage} alt="doctor-image" className="flex-none h-14 w-14 rounded-full m-3" /> */}</figure>
                  <div className="flex justify-center">
                    <div className="flex flex-col gap-2">
                      <h3 className=" font-semibold text-gray-900 text-center xl:pr-0">{docData.fullName}</h3>
                      <span className="mt-2 text-sm   py-2 px-6 text-black  bg-cyan-50 rounded-full text-center block w-40 m-auto">{docData.speciality}</span>
                    </div>
                    {/* <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                      <div className="flex items-start space-x-3">
                        <dt className="mt-0.5">
                          <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </dt>
                        <dd>{docData.speciality}</dd>
                      </div> 
                    </dl>*/}
                  </div>
                </div>
                <div>
                  <div className="mt-2 flex items-start justify-start pl-5">
                    {/* <dt className="mt-0.5">
                      <LocationMarkerIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd>{docData.address}</dd> */}
                    <div class="flex items-center mb-4"><figure class="mr-4">   <svg width="21" height="29" viewBox="0 0 21 29" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.4211 15.6821C13.1648 15.6821 15.389 13.4227 15.389 10.6356C15.389 7.84844 13.1648 5.58902 10.4211 5.58902C7.67734 5.58902 5.45312 7.84844 5.45312 10.6356C5.45312 13.4227 7.67734 15.6821 10.4211 15.6821Z" fill="#00B3FF"></path><path d="M20.5831 10.6356C20.5831 4.93528 16.0329 0.313095 10.4214 0.313095C4.80994 0.313095 0.259766 4.93528 0.259766 10.6356C0.259766 15.3335 7.15841 24.4356 9.58818 27.4957H3.98571C3.67409 27.4957 3.42118 27.7526 3.42118 28.0691C3.42118 28.3857 3.67409 28.6426 3.98571 28.6426H10.1956C10.4892 28.6426 10.7285 28.4132 10.7556 28.1196C12.508 25.9565 20.5831 15.7166 20.5831 10.6356ZM10.4214 1.46004C15.4029 1.46004 19.454 5.57527 19.454 10.6356C19.454 14.5673 13.5648 22.7771 10.4214 26.7272C7.2781 22.7771 1.38884 14.5673 1.38884 10.6356C1.38884 5.57527 5.43996 1.46004 10.4214 1.46004Z" fill="black"></path></svg></figure><h4 class="text-black text-base">Dirección</h4></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AppointmentSuccessful;
