import img3 from '../images/young-doctor-supporting-his-patient.jpg';
import imgChainLogo from '../images/dl-chain-logo.png';
import imgWhodocter from '../images/who-we-are-doc.png';
import imgDocpettern from '../images/shape-5.svg';
const stats = [
{ label: 'Fundado', value: '2021' },
{ label: 'Empleados', value: '10' },
{ label: 'Doctores', value: '99' },
{ label: 'Usuarios Beta', value: '99' }
];
export default function WhoWeAre() {
return (
<>
<div className="who-we-are pt-20">
   <div className='wrapper-box'>
      <div className=" lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 ">
         <div className="relative who-we-are-L ">
            <div
               aria-hidden="true"
               className=""
               >
               <figure className='shape-6'><img className="" src={imgDocpettern} alt="" /></figure>
            </div>
            <div className="relative">
               {/* Testimonial card*/}
               <div className="relative pt-64 pb-10 bg-blue-gredient">
                  <img className="absolute inset-0 h-full w-full object-cover mix-blend-multiply" src={imgWhodocter} alt="" />
                  <div className="relative px-8">
                     <blockquote className="mt-8">
                        <div className="relative text-lg font-medium text-white md:flex-grow">
                           {/* <h2 className="who-we-are-title">
                              Lorem ipsum dolor.
                           </h2> */}
                           <p className="who-we-are-sub-title">
                            El equipo de Doctolink, con experiencia en el sector de los servicios médicos, quiere brindarle a todos los Salvadoreños una herramienta fácil, amigable y accesible para mejorar la interaccion entre el paciente y el doctor.

                           </p>
                        </div>
                        <footer className="mt-4">
                           <p className="footer-text-who">
                              Alejandra Duque, CEO at DoctoLink
                           </p>
                        </footer>
                     </blockquote>
                  </div>
               </div>
            </div>
         </div>
         <div className=" who-we-are-R">
            {/* Content area */}
            <div className="">
               <div className='pb-8'>
                  <h2 className="who-we-are-title primary-color">
                     Nuestra Misión:
                  </h2>
                  <p className="who-we-are-sub-title">
                     Ser la plataforma digital referente en la transformación del mundo de la salud.
                  </p>
               </div>
               <h2 className="who-we-are-title primary-color">
                  Nuestra Visión:
               </h2>
               <p className="who-we-are-sub-title">
                  Innovar la experiencia en el mundo de la salud de una manera amigable y accesible,
                  creando una plataforma digital en la que el paciente y el médico estén conectados.
               </p>
            </div>
            {/* Stats section */}
            <div className="mt-8 employee-info">
               <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                  {stats.map((stat) => (
                  <div key={stat.label} className="border-t-2 pt-6 employee-info-box">
                     <dt className="employee-info-dt">{stat.label}</dt>
                     <dd className="employee-info-dd">
                        {stat.value}
                     </dd>
                  </div>
                  ))}
               </dl>
               <div className="mt-10">
                  {/*<a
                     href="#"
                     className="text-base font-medium text-dl-primary-900 hover:text-dl-primary-700"
                     >
                  {' '}
                  Learn more about how we're changing the world <span aria-hidden="true">
                  &rarr;
                  </span>{' '}
                  </a>*/}
               </div>
            </div>
         </div>
      </div>

      <div className='who-we-are-four-box pt-20 pb-60'>

      <div className='wrapper-box who-we-are-four-box-wrapper'>


      <div className="grid grid-cols-2 gap-x-4 gap-y-8">


        <div className='who-we-are-four-box-inner'>

            <h5>Integridad</h5>
        </div>

        <div className='who-we-are-four-box-inner'> <h5>Responsabilidad
Social</h5></div>

        <div className='who-we-are-four-box-inner'> <h5>Innovación</h5></div>

        <div className='who-we-are-four-box-inner'> <h5>Cercanía</h5></div>


        </div>


        </div>




      </div>
   </div>
</div>
</>
);
}