export const DoctorIcon = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_737_881)">
      <path
        d="M14.9814 1.21265H12.7983V2.56304H14.3062V5.57891C14.3062 7.85005 12.4584 9.69761 10.1875 9.69761H9.87241C7.60127 9.69761 5.75371 7.84981 5.75371 5.57891V2.56304H7.26165V1.21265H5.07852C4.70559 1.21265 4.40332 1.51492 4.40332 1.88784V5.57891C4.40332 8.59455 6.85677 11.048 9.87241 11.048H10.1875C13.2031 11.048 15.6566 8.59455 15.6566 5.57891V1.88784C15.6566 1.51492 15.3543 1.21265 14.9814 1.21265Z"
        fill="black"
      />
      <path
        d="M12.7309 0.244934C12.3579 0.244934 12.0557 0.547215 12.0557 0.920154V2.81077C12.0557 3.18371 12.3579 3.48599 12.7309 3.48599C13.1038 3.48599 13.4061 3.18371 13.4061 2.81077V0.920154C13.4061 0.547215 13.1038 0.244934 12.7309 0.244934Z"
        fill="black"
      />
      <path
        d="M7.3969 0.244934C7.02396 0.244934 6.72168 0.547215 6.72168 0.920154V2.81077C6.72168 3.18371 7.02396 3.48599 7.3969 3.48599C7.76984 3.48599 8.07212 3.18371 8.07212 2.81077V0.920154C8.07212 0.547215 7.76984 0.244934 7.3969 0.244934Z"
        fill="black"
      />
      <path
        d="M10.75 14.1768V10.3507H9.39957V14.1768H7.21643C5.45418 14.1768 4.02051 15.6105 4.02051 17.3725C4.02051 19.1347 5.4643 20.5684 7.23894 20.5684H7.55403C9.31629 20.5684 10.75 19.1347 10.75 17.3725V15.5272H13.0456V14.1768H10.75ZM9.39957 17.3723C9.39957 18.3898 8.57155 19.2178 7.55403 19.2178H7.23894C6.19149 19.2178 5.3709 18.4073 5.3709 17.3723C5.3709 16.3547 6.19892 15.527 7.21643 15.527H9.39957V17.3723Z"
        fill="black"
      />
      <path
        d="M14.5091 12.7363C13.3427 12.7363 12.3936 13.6853 12.3936 14.8521C12.3936 16.0184 13.3425 16.9676 14.5091 16.9676C15.6757 16.9676 16.6249 16.0184 16.6249 14.8521C16.6249 13.6855 15.6759 12.7363 14.5091 12.7363ZM14.5091 15.6169C14.0873 15.6169 13.744 15.2736 13.744 14.8519C13.744 14.4298 14.0873 14.0866 14.5091 14.0866C14.9311 14.0866 15.2744 14.4298 15.2744 14.8519C15.2744 15.2736 14.9311 15.6169 14.5091 15.6169Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_737_881">
        <rect width="20.3233" height="20.3233" fill="white" transform="translate(0.161133 0.244934)" />
      </clipPath>
    </defs>
  </svg>
);
