import { useEffect, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { add, eachDayOfInterval, endOfMonth, format, getDay, isEqual, isSameDay, isSameMonth, isToday, parse, parseISO, startOfToday, startOfTomorrow, subDays } from 'date-fns';
import es from 'date-fns/locale/es';
import AppointmentItem from './appointmentItem/AppointmentItem';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

let colStartClasses = ['', 'col-start-2', 'col-start-3', 'col-start-4', 'col-start-5', 'col-start-6', 'col-start-7'];

export default function AppointmentCalendar(props) {
  const { availableAppointments, setSelectedAppointment, selectedAppointment, queryAppointmentHistory } = props;

  let tomorrow = startOfTomorrow();
  let today = startOfToday();
  let [selectedDay, setSelectedDay] = useState(tomorrow);
  let [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'));
  let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date());

  // useEffect(() => {
  //   const startDate = format(subDays(new Date(selectedDay), 1), 'yyyy-MM-dd');
  //   const endDate = format(subDays(new Date(selectedDay), 1), 'yyyy-MM-dd');

  //   // const endDate = format(new Date(selectedDay), 'yyyy-MM-dd');
  //   queryAppointmentHistory(startDate, endDate);
  // }, [selectedDay]);

  if (!availableAppointments.availableTimesResponse) {
    return null;
  }

  let days = eachDayOfInterval({ start: firstDayCurrentMonth, end: endOfMonth(firstDayCurrentMonth) });

  // function previousMonth() {
  //   let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
  //   setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
  // }

  // function nextMonth() {
  //   let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
  //   setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
  // }

  function previousMonth() {
    const firstDayPreviousMonth = add(firstDayCurrentMonth, { months: -1 });
    if (firstDayCurrentMonth >= tomorrow) {
      let startDate;
      let endDate;
      setCurrentMonth(format(firstDayPreviousMonth, 'MMM-yyyy'));
      // if the month selected is the same as tomorrow
      if (isSameMonth(firstDayPreviousMonth, tomorrow)) {
        startDate = tomorrow;
        endDate = endOfMonth(tomorrow);
      } else {
        startDate = firstDayPreviousMonth;
        endDate = endOfMonth(firstDayPreviousMonth);
      }

      queryAppointmentHistory(startDate.toISOString().slice(0, 10), endDate.toISOString().slice(0, 10));
    }
  }

  function nextMonth() {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
    const startDate = firstDayNextMonth;
    const endDate = endOfMonth(firstDayNextMonth);

    queryAppointmentHistory(startDate.toISOString().slice(0, 10), endDate.toISOString().slice(0, 10));
  }

  let selectedDayAppointments = availableAppointments?.availableTimesResponse?.filter((appointment) => isSameDay(parseISO(`${appointment.date}T${appointment.appTime}`), selectedDay));


  return (
    <div className="pt-16 mb-20">
      <div className="max-w-full mx-auto px-20 appoinment-calender-box">
        <div className="md:grid md:grid-cols-2 ">
          <div className="bg-white me-5 py-7 px-7 rounded-lg ">
            <div className="flex items-center">
              <h2 className="flex-auto font-semibold capitalize text-bold text-black text-xl">{format(firstDayCurrentMonth, 'MMMM yyyy', { locale: es })}</h2>
              <button type="button" onClick={previousMonth} className="-my-1.5 flex flex-none items-center justify-center p-1.5 hover:text-gray-500 text-black">
                <span className="sr-only">Previous month</span>
                <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
              </button>
              <button onClick={nextMonth} type="button" className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 hover:text-gray-500 text-black">
                <span className="sr-only">Next month</span>
                <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
              </button>
            </div>
            <div className="grid grid-cols-7 mt-10 text-xs leading-6 text-center text-black  font-semibold">
              <div className="text-base">D</div>
              <div className="text-base">L</div>
              <div className="text-base">M</div>
              <div className="text-base">M</div>
              <div className="text-base">J</div>
              <div className="text-base">V</div>
              <div className="text-base">S</div>
            </div>
            <div className="grid grid-cols-7 mt-2 text-sm ">
              {days.map((day, dayIdx) => (
                <div key={dayIdx} className={classNames(dayIdx === 0 && colStartClasses[getDay(day)], 'py-1.5')}>
                  <button
                    type="button"
                    onClick={() => setSelectedDay(day)}
                    disabled={day <= today}
                    className={classNames(
                      isEqual(day, selectedDay) && 'text-white',
                      // !isEqual(day, selectedDay) && isToday(day) && 'text-dl-red',
                      !isEqual(day, selectedDay) && !isToday(day) && isSameMonth(day, firstDayCurrentMonth) && 'text-gray-900',
                      !isEqual(day, selectedDay) && !isToday(day) && !isSameMonth(day, firstDayCurrentMonth) && 'text-gray-400',
                      isEqual(day, selectedDay) && isToday(day) && 'bg-dl-red',
                      isEqual(day, selectedDay) && !isToday(day) && 'bg-sky-500',
                      !isEqual(day, selectedDay) && 'hover:bg-gray-200',
                      (isEqual(day, selectedDay)) && 'font-semibold',
                      day <= today && 'cursor-not-allowed text-lg	',
                      'mx-auto flex h-8 w-8 items-center justify-center rounded-full active-btn-number'
                    )}
                  >
                    <time dateTime={format(day, 'yyyy-MM-dd')}>{format(day, 'd')}</time>
                  </button>
                  <div className="flex mt-1 justify-center gap-1">
                    <div className="w-1 h-1">{availableAppointments.availableTimesResponse.some((appointment) => isSameDay(parseISO(appointment.date), day)) && <div className="w-1 h-1 rounded-full bg-sky-500"></div>}</div>
                    <div className="w-1 h-1">{availableAppointments.blockedSlots.some((slot) => isSameDay(parseISO(slot.date), day)) && <div className="w-1 h-1 rounded-full bg-red-500"></div>}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <section className="mt-12 md:mt-0 md:pl-14 border-l border-black ml-8">
            <div className="mb-4 ml-6">
              <h2 className="  text-black font-bold text-xl">
                Horarios disponibles para <time dateTime={format(selectedDay, 'yyyy-MM-dd')}>{format(selectedDay, 'MMM dd, yyy')}</time>
              </h2>
              <p className="font-normal text-black text-md">Seleccione una hora</p>
            </div>
            <ul className="!block time-slot-name-text ">
              {selectedDayAppointments.length > 0 ? (
                selectedDayAppointments.map((appointment) => (
                  <AppointmentItem
                    isSelected={selectedAppointment !== null && appointment.id === selectedAppointment.id && appointment.appTime === selectedAppointment.appTime}
                    appointment={appointment}
                    key={appointment.apptID}
                    setSelectedAppointment={setSelectedAppointment}
                    blockedSlots={availableAppointments?.blockedSlots}
                  />
                ))
              ) : (
                <p className='ml-6'>No hay citas para este día.</p>
              )}
            </ul>
          </section>
        </div>
        <div className="py-7">
          <div className="gap-20">
          <div className="flex items-center gap-20 mt-3">
            <div className="p-1 m-1 w-9  h-3 rounded-lg bg-sky-500 "></div> <span className='text-black font-medium	'> Espacio disponible para citas</span>
          </div>
          <div className="flex items-center gap-20 mt-3">
            <div className="p-1 m-1 w-9  h-3 rounded-lg  bg-red-500"></div> <span className='font-medium	'>Sin dis ponibilidad de citas</span>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}
