import Header from './nav/header/Header';
import Footer from './nav/footer/Footer';
import { Navigate, Outlet } from 'react-router-dom';
import { useInfoStore } from './App';
import Loader from './v2/components/Loader';
import useAuthentication from './api/hooks/useAuthentication';

const Layout = ({ highlight = '', type }) => {
  const { userInfo } = useInfoStore((state) => state);
  const { performUserLogout } = useAuthentication();

  let showContent;
  const token = localStorage.getItem('BearerToken');
  if (type === 'public') {
    showContent = true;
  }
  if (type === 'private') {
    if (token) {
      showContent = userInfo ? true : false;
    } else {
      return <Navigate to="/" replace />;
    }
  }
  return showContent ? (
    <>
      <Header highlight={highlight} user={userInfo} onSignOut={performUserLogout} />
      <Outlet />
      <Footer />
    </>
  ) : (
    <Loader />
  );
};

export default Layout;
