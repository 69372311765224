import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Documentos } from '../../../v2/services/Documentos';
const AWS_BASE_URL = process.env.AWS_BASE_URL;

export default function EditDocDialog({ open, setClose, title, bodyText, actionButtonText, actionButtonFunction, info, setShowSuccessModal }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [documentName, setDocumentName] = useState("");
    const [doctorName, setDoctorName] = useState("");
    const [error, setError] = useState('');
    
    const handleSubmit = async () => {
        if (documentName === "") {
            setError('Document name is required.');
            return;
        }

        try {
            const { document_link, idUser, usertype, name, idPatient, id } = info;
            const extension = `${document_link.replace('https://doctolink-documents.s3.us-east-2.amazonaws.com/', '').split('.')[1]}`;
            const newDocumentName = `${documentName}.${extension}`;
            const oldDocumentName = `${name}.${extension}`;
            const response = await Documentos.rename({ document_link, oldDocumentName, newDocumentName, idUser, userType: usertype, idPatient, id });
            actionButtonFunction(selectedFile, selectedCategory, documentName, doctorName);
            setError('');
            setClose(false);
            setShowSuccessModal(true);
          } catch (err) {
            console.log(err);
            setError(err.response.data.message);
          }
    };
    useEffect(() => {
        setDocumentName(info.name)
    }, [])
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                            {title}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">{bodyText}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <input
                                        type="text"
                                        value={documentName}
                                        onChange={(e) => setDocumentName(e.target.value)}
                                        placeholder="Document Name"
                                        className="block w-full text-sm text-gray-500 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                                {error && (
                                    <div className="mt-2 text-sm text-red-600">{error}</div>
                                )}
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
                                        onClick={handleSubmit}
                                    >
                                        {actionButtonText}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
 