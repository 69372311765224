import { useGoogleLogin } from '@react-oauth/google';
import React, { useEffect, useState } from 'react';
import Axios from '../../../v2/services/Axios';
import { addMinutes, mergeDateTime } from '../../../common/helpers/helper';

const AddEventToCalendar = ({ selectedAppointment, docData, setErrorMessage, setSuccessMessage, appointmentId, showUpdateCalendarBtn, updatedData, appointment }) => {
    const [appointmentAdded, setAppointmentAdded] = useState(false);
    const [accessToken, setAccesssToken] = useState("")

    // const login = useGoogleLogin({
    //     scope: 'https://www.googleapis.com/auth/calendar.events',
    //     onSuccess: (codeResponse) => {
    //         console.log("access token", codeResponse?.access_token)
    //         setAccesssToken(codeResponse?.access_token)
    //     },
    //     onError: (error) => {
    //         setAccesssToken("")
    //         console.log("google auth error", error)
    //     }
    // });
    // const login = useGoogleLogin({
    //     scope: 'https://www.googleapis.com/auth/calendar.events',
    //     onSuccess: (codeResponse) => {
    //         console.log("access token", codeResponse);
    //         setAccesssToken(codeResponse?.access_token);
    //     },
    //     onError: (error) => {
    //         setAccesssToken("");
    //         console.log("google auth error", error);
    //     },
    //     accessType: 'offline', // Request a refresh token
    //     prompt: 'consent', // Force the consent screen to ensure a refresh token is generated
    // });

    const login = useGoogleLogin({
        scope: 'https://www.googleapis.com/auth/calendar.events',
        onSuccess: (codeResponse) => {
            console.log("Authorization code:", codeResponse);
            setAccesssToken(codeResponse.code)
        },
        onError: (error) => {
            console.log("Google auth error", error);
        },
        flow: 'auth-code', // Requesting an authorization code instead of an access token
        accessType: 'offline', // Request offline access to get a refresh token
        prompt: 'consent', // Force the consent screen to ensure a refresh token is generated
    });



    const addEvent = async () => {
        try {
            const payload = {
                title: `Appointment with Dr ${docData?.fullName}`,
                start_date: mergeDateTime(selectedAppointment?.date, selectedAppointment?.appTime),
                end_date: addMinutes(mergeDateTime(selectedAppointment?.date, selectedAppointment?.appTime), 30),
                code: accessToken,
                idAppointment: appointmentId
            }
            const res = await Axios.post("auth/create-google-event", payload)
            setAppointmentAdded(true)
            // setSuccessMessage("Appointment has been successfully added to your Google Calendar")
            setSuccessMessage("Cita agregada al calendario de Google con éxito")
            console.log("res", res)
        } catch (error) {
            console.log(error)
            setErrorMessage("Hay un problema al agregar el evento a su calendario. Inténtelo de nuevo más tarde.")
        } finally {
            setAccesssToken("")

        }

    }

    const updateEvent = async () => {
        try {

            console.log("update payload aoi", appointment)
            const payload = {
                title: `Appointment with Dr ${appointment?.doctorFullName}`,
                start_date: mergeDateTime(updatedData?.appDate, updatedData?.appTime),
                end_date: addMinutes(mergeDateTime(updatedData?.appDate, updatedData?.appTime), 30),
                idAppointment: appointment?.appointmentID,
                access_token: accessToken,
                eventId: appointment?.eventId

            }

            const res = await Axios.post("auth/update-google-event", payload)
            setAppointmentAdded(true)
        } catch (error) {
            console.log("error ", error)
        }


    }


    useEffect(() => {
        if (accessToken?.length > 0 && !showUpdateCalendarBtn) {
            addEvent()
        }
        else if (accessToken?.length > 0 && showUpdateCalendarBtn) {
            updateEvent()
        }
    }, [accessToken, showUpdateCalendarBtn])




    let classes = showUpdateCalendarBtn
        ? `inline-flex w-full justify-center rounded-md border border-transparent ${appointmentAdded ? "bg-gray-400" : "bg-blue-600 hover:bg-blue-700"}  px-4 py-2 text-base font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm mb-2`
        : `inline-flex mb-2 w-full justify-center rounded-full border border-transparent  ${appointmentAdded ? "bg-gray-500" : "bg-sky-400"} px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:text-sm `
    return (
        <>

            {!showUpdateCalendarBtn
                ?
                <button
                    type="button"
                    className={classes}
                    onClick={() => login()}
                    disabled={appointmentAdded}
                >
                    {appointmentAdded ? "Cita agregada a Google Calendar" : "Agregar cita al calendario de Google"}
                </button>
                : null
            }
           

        </>
    );
};

export default AddEventToCalendar;
