export default function ProfileFormHeader(props) {
    const { title, initialText, secondaryText, textColor = 'text-gray-900' } = props;

    return (
       
        <figcaption>
            <h4 className={`text-lg leading-6 font-medium hh ${textColor}`}>{title}</h4>
            {initialText && <h5 >{initialText}</h5>}
            {secondaryText && <p className="mt-1 text-sm text-gray-500">{secondaryText}</p>}
        </figcaption>
       
    );
}
