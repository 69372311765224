import WhiteCardContainer from '../../../../common/components/containers/forms/WhiteCardContainer';
import FormFieldsContainer from '../../../../common/components/containers/forms/FormFieldsContainer';
import HalfRow from '../../../../common/components/formElements/HalfRow';
import FormButton from '../../../../common/components/formElements/FormButton';
import FormFieldsFooter from '../../../../common/components/containers/forms/FormFieldsFooter';
import FormSelectDrop from '../../../../common/components/formElements/FormSelectDrop';
import CustomRedAlert from '../../../../common/components/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../../common/components/customSuccessModal/CustomSuccessModal';
import FormResetButton from '../../../../common/components/formElements/FormResetButton';
import FormDatePicker from '../../../../common/components/formElements/FormDatePicker';
import { ClockIcon } from '@heroicons/react/outline';
import { Controller, useForm } from 'react-hook-form';
import { format, setHours, setMinutes, differenceInMinutes } from 'date-fns';
import { useEffect, useState } from 'react';
import { SchedulesS } from '../../../services/DoctorProfile';

import ProfileFormHeader from '../../../../doctorUser/components/ProfileFormHeader/ProfileFormHeader';
import SchedulesList from '../../../../doctorUser/components/doctorOwnProfile/schedules/components/schedulesList/SchedulesList';
import { CxCard, CxTabButton, CxWrap, Line } from '../../../components/Sx';
import Loader from '../../../components/Loader';
import { Listbox, Transition, Label, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/react';

const statusOptions = [
  { id: true, label: 'Activo' },
  { id: false, label: 'Inactivo' }
];

// const dayOptions = [
//   { id: 'Lunes', label: 'Lunes' },
//   { id: 'Martes', label: 'Martes' },
//   { id: 'Miercoles', label: 'Miércoles' },
//   { id: 'Jueves', label: 'Jueves' },
//   { id: 'Viernes', label: 'Viernes' },
//   { id: 'Sabado', label: 'Sábado' },
//   { id: 'Domingo', label: 'Domingo' }
// ];

const dayOptions = [
  'Lunes',
  'Martes',
  'Miercoles',
  'Jueves',
  'Viernes',
  'Sabado',
  'Domingo'
];

const intervalOptions = [
  { id: 15, label: 15 },
  { id: 30, label: 30 },
  { id: 45, label: 45 },
  { id: 60, label: 60 },
]

export default function Schedules(props) {
  const [schedules, setSchedules] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorBody, setErrorBody] = useState('');
  const [successBody, setSuccessBody] = useState('');
  const [defaultValues, setDefaultValues] = useState({});
  const [selectedSchedules, setSelectedSchedules] = useState(null);
  // const isEditing = JSON.stringify(defaultValues) !== '{}';
  const [isEditing, setEditing] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { register, handleSubmit, formState, watch, control, reset, setValue, getValues } = useForm(defaultValues);
  const { errors, isDirty } = formState;

  useEffect(() => {
    setIsLoading(true);
    SchedulesS.getAllSchedules()
      .then((res) => {
        setSchedules(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    setIsLoading(true);

    if (selectedSchedules) {
      if (typeof selectedSchedules.openHours1 === 'string' && typeof selectedSchedules.openHours2 === 'string') {
        const now = new Date();
        now.setHours(selectedSchedules.openHours1.substr(0, selectedSchedules.openHours1.indexOf(':')));
        now.setMinutes(selectedSchedules.openHours1.substr(selectedSchedules.openHours1.indexOf(':') + 1));
        now.setSeconds(0);

        const now2 = new Date();
        now2.setHours(selectedSchedules.openHours2.substr(0, selectedSchedules.openHours2.indexOf(':')));
        now2.setMinutes(selectedSchedules.openHours2.substr(selectedSchedules.openHours2.indexOf(':') + 1));
        now2.setSeconds(0);

        selectedSchedules.openHours1 = now;
        selectedSchedules.openHours2 = now2;
      }
      setDefaultValues(selectedSchedules);
      setEditing(true);
      reset(selectedSchedules);
      setValue('status', selectedSchedules.status ? 'true' : 'false', { shouldValidate: true });
      setValue('dayName', selectedSchedules.dayName, { shouldValidate: true });
      setValue('openHours1', selectedSchedules.openHours1, { shouldValidate: true });
      setValue('openHours2', selectedSchedules.openHours2, { shouldValidate: true });
      setValue('interval', selectedSchedules.interval, { shouldValidate: true });
    }

    setIsLoading(false);
  }, [reset, selectedSchedules, setValue]);

  const resetState = () => {
    setShowSuccessModal(false);
    setShowErrorModal(false);
    setErrorBody('');
    setSuccessBody('');
    setDefaultValues({});
    setSelectedSchedules(null);
    setEditing(false)
    reset({ openHours1: '', openHours2: '' });
    setValue('status', '', { shouldValidate: true });
    setValue('dayName', [], { shouldValidate: true });
    setValue('interval', '', { shouldValidate: true });
  };

  const _createErrorMessage = (errorMessage) => {
    setShowErrorModal(true);
    setErrorBody(errorMessage);
  };

  const closeErrorMessage = () => {
    setShowErrorModal(false);
    setErrorBody('');
  };

  const _createSuccessMessage = (errorMessage) => {
    setShowSuccessModal(true);
    setSuccessBody(errorMessage);
  };

  const saveSchedules = (data) => {
    console.log(data, 'schedule form data');
    const hoursInterval = differenceInMinutes(
        new Date(data.openHours2).setSeconds(0, 0),
        new Date(data.openHours1).setSeconds(0, 0)
    );
    const areHoursValid = hoursInterval >= parseInt(data.interval);
    console.log(hoursInterval, areHoursValid, 'are hours valid');
    if (hoursInterval < 0) {
        _createErrorMessage('La hora de finalización debe ser mayor que la hora de inicio.');
        return;
    }
    if (!areHoursValid) {
        _createErrorMessage('La diferencia entre la hora de inicio y la hora de finalización debe ser igual o mayor que el intervalo seleccionado.');
        return;
    }
    console.log(data.openHours1, data.openHours2, 'data');
    data.openHours1 = format(data.openHours1, 'HH:mm');
    data.openHours2 = format(data.openHours2, 'HH:mm');
    if (!data) {
      _createErrorMessage('No se pudo obtener información válida para guardar. Inténtelo de nuevo.');
      return;
    }
    if (JSON.stringify(defaultValues) === '{}') {
      executeCreateSchedules(data);
    } else {
      const adjustedData = { ...data, idSchedule: data.id, status: data.status === 'true' };
      doUpdateSchedules(adjustedData);
    }
  };

  const executeCreateSchedules = async (newSchedules) => {
    const createNewSchedules = await SchedulesS.createSchedules({ ...newSchedules, interval: parseInt(newSchedules.interval) });
    if (createNewSchedules) {
      _createSuccessMessage('¡Se ha guardado el registro con éxito!');
    }
    setRefresh(!refresh);
  };

  const doUpdateSchedules = async (updatedSchedulesObject) => {
    const executeUpdatedSchedules = await SchedulesS.updateSchedules({ ...updatedSchedulesObject, interval: parseInt(updatedSchedulesObject.interval) });
    if (executeUpdatedSchedules) {
      _createSuccessMessage('¡Se ha actualizado el registro con éxito!');
    }
    setRefresh(!refresh);
  };

  const onCloseSuccessMessage = () => {
    resetState();
  };

  const handleDaySelection = (value) => {
    if (!isSelected(value)) {
        const daysSelected = daysArray(getValues('dayName'));
        const latestSelectedDays = new Set([
          ...daysSelected,
          dayOptions.find((el) => el === value)
        ]);
        setValue('dayName', Array.from(latestSelectedDays));
    } 
    else {
        handleDeselect(value);
    }
    setIsOpen(true);
  }

  const handleDeselect = (value) => {
    const latestSelectedDays = daysArray(getValues('dayName')).filter((el) => el !== value);
    setValue('dayName', latestSelectedDays);
    setIsOpen(true);
  }

  const isSelected = (value) => {
    return daysArray(getValues('dayName'))?.find((el) => el === value) ? true : false;
  }

  const daysArray = (days) => {
    if (days) {
        return Array.isArray(days) ? days : [days];
    } else {
        return [];
    }
  }

  if (isLoading) return <Loader />;

  return (
    <form action="#" method="POST" onSubmit={handleSubmit(saveSchedules)}>
      <CxWrap>
        <CxCard>
          <ProfileFormHeader
            title={!isEditing ? 'Nuevo Horario ' : 'Actualizar Horario'}
            initialText={`Ingresa Horarios generales en los que atiendes pacientes. 
                                Estos NO son los espacios de consulta, solamente es de carácter informativo.`}
          />
        </CxCard>
        <Line />
        <CxCard>
          <div className="grid grid-cols-6 gap-6 gap-x-32">
            {/* <div class="col-start-1 col-end-4 ">
              <FormSelectDrop
                label={'Dia'}
                identifier={'dayName'}
                options={dayOptions}
                defaultValue={defaultValues.dayName}
                helperText={'Requerido'}
                registration={register('dayName', { required: true })}
                errorMessage={errors.dayName ? 'Este campo es requerido' : null}
              />
            </div> */}
            <div className="col-start-1 col-end-6">
              <HalfRow>
                <Controller name="dayName" control={control} defaultValue={defaultValues.dayName} rules={{ required: "Este campo es requerido" }} render={({ field, value, fieldState: { error } }) => (
                    <>
                        <Listbox
                            as="div"
                            className="space-y-1"
                            value={value}
                            onChange={(value) => {
                                // field.onChange([value]);
                                handleDaySelection(value);
                            }}
                            open={isOpen}
                            >
                            {() => (
                                <>
                                <Label className="block text-sm leading-5 font-medium text-gray-700">
                                    Dia
                                </Label>
                                <div className="relative">
                                    <span className="inline-block w-full rounded-md shadow-sm">
                                    <ListboxButton
                                        className="cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                                        onClick={() => setIsOpen(!isOpen)}
                                        open={isOpen}
                                    >
                                        <span className="block truncate font-normal">
                                        {daysArray(getValues('dayName'))?.length < 1
                                            ? "Seleccionar días"
                                            : `Días seleccionados (${daysArray(getValues('dayName')).length})`}
                                        </span>
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <svg
                                            className="h-5 w-5 text-gray-400"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            stroke="currentColor"
                                        >
                                            <path
                                            d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            />
                                        </svg>
                                        </span>
                                    </ListboxButton>
                                    </span>

                                    <Transition
                                    unmount={false}
                                    show={isOpen}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                    className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10"
                                    >
                                    <ListboxOptions
                                        static
                                        className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                                    >
                                        {dayOptions.map((day, index) => {
                                        const selected = isSelected(day);
                                        return (
                                            <ListboxOption key={day} value={day}>
                                            {({ active }) => (
                                                <div
                                                className={`${
                                                    active
                                                    ? "text-white bg-blue-600"
                                                    : "text-gray-900"
                                                } cursor-default select-none relative py-2 pl-8 pr-4`}
                                                >
                                                <span
                                                    className={`${
                                                    selected ? "font-semibold" : "font-normal"
                                                    } block truncate`}
                                                >
                                                    {day}
                                                </span>
                                                {selected && (
                                                    <span
                                                    className={`${
                                                        active ? "text-white" : "text-blue-600"
                                                    } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                                                    >
                                                    <svg
                                                        className="h-5 w-5"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                    >
                                                        <path
                                                        fillRule="evenodd"
                                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                        clipRule="evenodd"
                                                        />
                                                    </svg>
                                                    </span>
                                                )}
                                                </div>
                                            )}
                                            </ListboxOption>
                                        );
                                        })}
                                    </ListboxOptions>
                                    </Transition>
                                    <div className="pt-1 text-sm font-normal">
                                    {getValues('dayName')?.length > 0 && (
                                        <>Días seleccionados: {(Array.isArray(getValues('dayName')) ? getValues('dayName') : [getValues('dayName')]).join(", ")}</>
                                    )}
                                    </div>
                                </div>
                                </>
                            )}
                        </Listbox>
                        {!errors.dayName && (
                            <p className="mt-2 text-sm font-normal" id={`dayName-description`}>
                                Requerido
                            </p>
                        )}
                        {errors.dayName ? <p className="mt-2 text-sm text-red-700">Este campo es requerido</p> : null}
                    </>
                )} />
              </HalfRow>
            </div>
            <div className="col-start-6 col-end-12">
              <HalfRow>
                <Controller
                    control={control}
                    name="interval"
                    rules={{ required: true }}
                    render={({ field }) => (
                        <FormSelectDrop
                            label={'Intervalo'}
                            identifier={'interval'}
                            options={intervalOptions}
                            defaultValue={defaultValues.interval}
                            helperText={'Requerido'}
                            registration={register('interval', { required: true })}
                            errorMessage={errors.interval ? 'Este campo es requerido' : null}
                            onChange={(value) => field.onChange(value)}
                        />
                    )}
                />
                {console.log(errors, 'errors')}
              </HalfRow>
            </div>

            <div class="col-start-1 col-end-6">
              <Controller
                control={control}
                name="openHours1"
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <FormDatePicker
                      identifier="openHours1"
                      label="Seleccionar hora de inicio"
                      errorMessage={errors.openHours1 ? 'Este campo es requerido' : null}
                      helperText={'Requerido'}
                      iconComponent={<ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                      datePickerProps={{
                        onKeyDown: (e) => {
                          e.preventDefault();
                        },
                        selected: field.value,
                        dateFormat: 'HH:mm:ss',
                        onChange: (date) => field.onChange(date),
                        showTimeSelect: true,
                        showTimeSelectOnly: true,
                        timeIntervals: 15,
                        timeCaption: 'Hora',
                        dropdownMode: 'select',
                        minTime: setHours(setMinutes(new Date(), 0), 7),
                        maxTime: setHours(setMinutes(new Date(), 0), 20)
                      }}
                    />
                  );
                }}
              />
            </div>
            <div class="col-start-6 col-end-12">
              <Controller
                control={control}
                name="openHours2"
                rules={{ required: true }}
                render={({ field }) => (
                  <FormDatePicker
                    identifier="openHours2"
                    label="Seleccionar hora final"
                    errorMessage={errors.openHours2 ? 'Este campo es requerido' : null}
                    helperText={'Requerido'}
                    iconComponent={<ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                    datePickerProps={{
                      onKeyDown: (e) => {
                        e.preventDefault();
                      },
                      selected: field.value,
                      dateFormat: 'HH:mm:ss',
                      onChange: (date) => field.onChange(date),
                      showTimeSelect: true,
                      showTimeSelectOnly: true,
                      timeIntervals: 15,
                      timeCaption: 'Hora',
                      dropdownMode: 'select',
                      minTime: setHours(setMinutes(new Date(), 0), 7),
                      maxTime: setHours(setMinutes(new Date(), 0), 20)
                    }}
                  />
                )}
              />
            </div>
            {isEditing && (
              <HalfRow>
                <FormSelectDrop
                  label={'Estado'}
                  identifier={'status'}
                  options={statusOptions}
                  defaultValue={defaultValues.status}
                  helperText={'Requerido'}
                  registration={register('status', { required: true })}
                  errorMessage={errors.status ? 'Este campo es requerido' : null}
                />
              </HalfRow>
            )}
          </div>
         
        
        </CxCard>

        <hr class="mt-2  pt-2 "></hr>

        <div className="flex justify-end gap-2  px-10 py-4">
            <CxTabButton label="Cancelar" variant="outlined" onClick={resetState} />
            <CxTabButton label={isEditing ? 'Actualizar' : 'Guardar'} disabled={isEditing && !isDirty} />
          </div>
      </CxWrap>
      {/* <div className="px-4 py-3  text-right sm:px-6">
        <FormResetButton onClick={resetState} label={'Cancelar'} />
        <FormButton label={isEditing ? 'Actualizar' : 'Guardar'} type={'submit'} disabled={isEditing && !isDirty} />
      </div> */}

      {showErrorModal && <CustomRedAlert open={true} title={'Ocurrió un error.'} bodyText={errorBody} onClose={closeErrorMessage} />}

      {showSuccessModal && (
        <CustomSuccessModal
          title={isEditing ? 'Registro actualizado con éxito' : 'Nuevo registro guardado con éxito'}
          bodyText={successBody}
          buttonText={'Aceptar'}
          buttonAction={onCloseSuccessMessage}
          open={showSuccessModal}
          onClose={onCloseSuccessMessage}
        />
      )}
      <div className="ml-1 mt-5">
        <div className="ml-5">
          <ProfileFormHeader title={'Horarios existentes'} fontFamily="font-aristotelica"/>
        </div>
        {schedules.length === 0 ? <p className="ml-4 mt-4">No hay horarios para mostrar</p> : <SchedulesList items={schedules} onClick={setSelectedSchedules} />}
      </div>
    </form>
  );
}
