import { format, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import SimpleDataTable from '../../../../../../doctorAppointmentManager/components/appointmentHistory/components/simpleDataTable/SimpleDataTable';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ExperienceList({ items, onClick }) {
  const columns = useMemo(() => {
    return [
      { Header: 'Título', accessor: 'col1' },
      { Header: 'Descripción', accessor: 'col2' },
      { Header: 'Creación', accessor: 'col3' },
      { Header: 'Estado', accessor: 'col4' },
      { Header: '', accessor: 'col5' }
    ];
  }, []);

  const completedTable = useMemo(() => {
    return items.map((item) => {
      return {
        col1: item.experienceName,
        col2: item.experienceDetail,
        col3: format(parseISO(item.creation), 'yyyy-MM-dd H:mm'),
        col4: (
          <span className={classNames('inline-flex rounded-full text-xs font-semibold leading-5 px-8 py-1', item.status ? 'bg-green-100' : 'bg-red-100', item.status ? 'text-green-800' : 'text-red-800')}>{item.status ? 'Activo' : 'Inactivo'}</span>
        ),
        col5: (
          <a href="#" onClick={(e) => _handleClick(e, item)} className="text-dl-primary-900 hover:text-dl-accent">
            Editar<span className="sr-only">, {item.experienceName}</span>
          </a>
        )
      };
    });
  }, []);

  const completedTableInstance = useTable(
    { columns: columns, data: completedTable },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!,
    useSortBy,
    usePagination
  );

  const _handleClick = (event, selectedItem) => {
    event.preventDefault();
    onClick && onClick(selectedItem);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-2">
      <div className="mt-4 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-6">
            {items.length ? <SimpleDataTable tableInstance={completedTableInstance} /> : null}

            {/* <div className="shadow ring-1 ring-black ring-opacity-5 rounded-[24px]">
               <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gradient-to-r from-blue-700 to-blue-400">
                  <tr>
                    <th scope="col" className="py-3.5 px-5 text-left text-sm font-semibold text-white sm:pl-6">
                      Título
                    </th>
                    <th scope="col" className="px-3 py-5 text-left text-sm font-semibold text-white">
                      Descripción
                    </th>
                    <th scope="col" className="px-3 py-5 text-left text-sm font-semibold text-white">
                      Creación
                    </th>
                    <th scope="col" className="px-3 py-5 text-left text-sm font-semibold text-white">
                      Estado
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Editar</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {items.map((item) => (
                    <tr key={item.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 ">
                        <span className="light-bg-sky py-2 px-8 text-black font-normal rounded-full"> {item.experienceName} </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-black truncate max-w-xs inline-flex">
                        <span className="truncate max-w-[75%] underline">{item.experienceDetail}</span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-black">{format(parseISO(item.creation), 'yyyy-MM-dd H:mm')}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-black">
                        <span className={classNames('inline-flex rounded-full text-xs font-semibold leading-5 px-8 py-1', item.status ? 'bg-green-100' : 'bg-red-100', item.status ? 'text-green-800' : 'text-red-800')}>
                          {item.status ? 'Activo' : 'Inactivo'}
                        </span>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
                        <a href="#" onClick={(e) => _handleClick(e, item)} className="text-dl-primary-900 hover:text-dl-accent">
                          Editar<span className="sr-only">, {item.experienceName}</span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table> 
            </div>*/}
          </div>
        </div>
      </div>
    </div>
  );
}
