import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import hLogo from '../../../images/dl-h-logo.png';
import { useInfoStore } from '../../../App';

export default function CleanSession(props) {
  const { setUserInfo } = useInfoStore((state) => state);
  const { onSignOut } = props;
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userType } = state;

  useEffect(() => {
    const path = userType === 'patient' ? '/sign-in' : '/sign-in-doctor';
    onSignOut();
    setTimeout(() => sendToHome(), 3000);
    const sendToHome = () => navigate(path, { replace: true });
    return () => {
      setUserInfo(null);
    };
  }, []);

  return (
    <div className="h-screen flex min-h-full flex-col bg-white pt-16 pb-12">
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-center">
          <a href="/" className="inline-flex">
            <span className="sr-only">DoctoLink</span>
            <img className="h-8 w-auto sm:h-10" src={hLogo} alt="" />
          </a>
        </div>
        <div className="py-16">
          <div className="text-center">
            <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Sesión Inválida.</h1>
            <p className="mt-2 text-base text-gray-500">No se puede continuar con esta sesión, será dirigido a la pantalla de inicio de sesión.</p>
            <div className="mt-6">
              <Link to={`/sign-in${userType === 'doctor' ? '-doctor' : ''}`} className="text-base font-medium text-dl-red hover:text-dl-secondary">
                Ir al inicio
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
