/* eslint-disable no-unused-vars */
import { BriefcaseIcon, ChevronRightIcon, LocationMarkerIcon } from '@heroicons/react/outline';
import useSelectAppointmentMotive from '../../hooks/useSelectAppointmentMotive';
import configurations from '../../../common/utils/configurations';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

const APP_SERVER = process.env.REACT_APP_API_SERVER;

export default function DoctorResultsTabsElement() {
  const { docData, onMotiveSelected } = useSelectAppointmentMotive();

  const navigate = useNavigate()
  const { doctorId, doctorName } = useParams()


  useEffect(() => {
    if (!docData.id && doctorId && doctorName) {
      navigate(`/doctor-results/${doctorId}/${doctorName}`)
    }
  }, [docData, doctorId, doctorName])

  return (
    <div className="min-h-[90vh]  py-10 px-8  max-w-7xl mx-auto">
      {/* Page heading */}
      <header className="bg-dl-primary-900/[.1]   docter-profile rounded-[24px]  pt-40">
        <div className="  xl:flex xl:items-center xl:justify-between docter-profile-inner bg-white  px-12">
          <div className="flex-1 min-w-0">
            {/* <nav className="flex" aria-label="Breadcrumb">
              <ol role="list" className="flex items-center space-x-4">
                <li>
                  <div>
                    <div className="text-sm font-medium text-gray-500 hover:text-gray-700">{docData.speciality}</div>
                  </div>
                </li>
                <li>
                  <div className="flex items-center">
                    <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <div className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{docData.fullName}</div>
                  </div>
                </li>
                <li>
                  <div className="flex items-center">
                    <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <div className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Motivo de consulta</div>
                  </div>
                </li>
              </ol>
            </nav> */}
            {/* <h1 className="mt-2 text-2xl font-bold leading-7 text-dl-accent sm:text-3xl sm:truncate">Motivo de consulta</h1> */}

            {/* Profile */}
            <div role="list" className="mt-5 sm:mt-0 sm:border-t-0">
              <div>
                <div className="flex items-center  sm:px-0">
                  <div className="min-w-0 flex-1 flex ">
                    <div className=" docter-profile-L ">
                      <img className="h-40 w-40 rounded-full group-hover:opacity-75 border-4 border-white" src={docData?.personalImage ? `${APP_SERVER}/${docData?.personalImage}` : configurations?.defaultImage} alt="" />
                    </div>
                    <div className="min-w-0 flex-1 px-10 py-8 md:grid md:grid-cols-2 md:gap-4 docter-profile-R">
                      <div>
                        <p className="truncate text-2xl text-black font-bold mb-3">{docData.fullName}</p>
                        <div className="flex docter-profile-R-inner">
                          <p className="mt-2 flex items-center text-sm mr-5 py-3 px-8 text-black docter-tag bg-cyan-50	rounded-full">
                            {/* <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                            <span className="truncate">{docData.speciality}</span>
                          </p>
                          <p className="mt-2 flex items-center text-sm mr-5 py-3 px-8 text-black docter-tag bg-cyan-50	rounded-full">
                            {/* <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                            <span className="">{docData.address}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main className="pt-8 pb-16">
        <div className="">
          {/* Body tabs */}

          <div className="pt-8 px-4 sm:px-0">
            {/* Stacked list */}

            {/* Description list*/}
            <section aria-labelledby="applicant-information-title dd" className="border-radius-24">
              <div className="bg-white shadow sm:rounded-xl">
                <div className="px-4 py-6 sm:px-6 border-b divide-slate-200 border-black">
                  <h2 id="applicant-information-title" className="text-lg leading-6 font-bold text-black sm:px-6 border-red ">
                    Elige motivo de consulta
                  </h2>
                </div>
                <div className="border-black px-4 py-5 sm:px-6 pt-0">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 mt-5">
                    <div className="sm:col-span-2">
                      <ul role="list" className="mt-5 sm:mt-0 ">
                        {docData.motives?.map((item) => (
                          <li key={item.id}>
                            <div role="button" onClick={() => onMotiveSelected(item)} className="group block">
                              <div className="flex items-center py-5 px-10  bg-stone-50 mb-4">
                                <div className="min-w-0 flex-1 flex items-center">
                                  <div className="min-w-0 flex-1 md:grid md:grid-cols-2 md:gap-4">
                                    <div>
                                      <p className="font-medium truncate text-black text-lg	">{item.motiveName}</p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <ChevronRightIcon className="h-6 w-6 group-hover:text-gray-700 text-black " aria-hidden="true" />
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </dl>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    </div>
  );
}
