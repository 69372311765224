import { Fragment, useState } from 'react';
import hLogo from './../../images/dl-h-logo.png';
import { Popover, Transition, Menu } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import RedAlert from '../../common/components/redAlert/RedAlert';
import Configurations from '../../common/utils/configurations';
import { useInfoStore } from '../../App';
import { handleImageError } from '../../common/helpers/helper';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const APP_SERVER = process.env.REACT_APP_API_SERVER;

export default function Header(props) {
  const { highlight } = props;
  const { userInfo: user } = useInfoStore((state) => state);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [openSignOutModal, setOpenSignOutModal] = useState(false);

  const _confirmLogout = () => {
    setOpenSignOutModal(false);
    const userType = user?.usertype === 1 ? 'patient' : 'doctor';
    navigate('/session-expired', { state: { userType } });
  };

  const _confirmSignOutCancel = () => {
    setOpenSignOutModal(false);
  };

  return (
    <Popover className="relative">
      <div className="absolute inset-0 pointer-events-none header-box-top" aria-hidden="true" />
      <div className="relative z-31">
        <div className={classNames(pathname === '/' ? 'bg-transparent' : 'bg-white', 'header mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10  ')}>
          <div>
            <Link to="/" className="flex">
              <span className="sr-only">DoctoLink</span>
              <img className="h-8 w-auto sm:h-10" src={hLogo} alt="" />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-dl-primary-900">
              <span className="sr-only">Abrir menú</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
            <Popover.Group as="nav" className="flex space-x-10 navlink-list">
              {/* <Link href="#" className={classNames(highlight === 'Home' ? 'text-blue-600' : '', 'text-base font-medium text-gray-500 hover:text-dl-accent')} to={'/'}>
                Inicio
              </Link> */}
              <Link className={classNames(highlight === 'WhatIsDoctolink' ? 'text-blue-600' : '', 'text-base font-medium text-gray-500 hover:text-dl-accent')} to={'/what-is-doctolink'}>
                ¿Qué es Doctolink?
              </Link>
              <Link href="#" className={classNames(highlight === 'WhoWeAre' ? 'text-blue-600' : '', 'text-base font-medium text-gray-500 hover:text-dl-accent')} to={'/who-we-are'}>
                ¿Quiénes somos?
              </Link>
            </Popover.Group>
            {!user && (
              <div className="flex items-center md:ml-12">
                {/* <Link to={'/sign-in'} className={classNames(highlight === 'SignIn' ? 'text-blue-600' : '', 'text-base font-medium text-gray-500 hover:text-dl-accent')}>
                  Iniciar sesión
                </Link> */}
                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className={classNames(highlight === 'SignIn' ? 'text-blue-600' : '', 'text-base font-medium text-gray-500 hover:text-dl-accent')}>Iniciar sesión</Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-40 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <Link to="/sign-in" className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                            Soy Paciente
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link to="/sign-in-doctor" className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                            Soy Doctor
                          </Link>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
                {/* <Link to="/sign-up" className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-dl-primary-900 hover:bg-dl-primary-700">
                  Registro
                </Link> */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-dl-primary-900 hover:bg-dl-primary-700">Registrarse</Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-40 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <Link to="/sign-up" className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                            Soy Paciente
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link to="/sign-up-doctor" className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                            Soy Doctor
                          </Link>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            )}

            {user && (
              <div className="cursor-pointer flex items-center md:ml-12">
                {/* Profile dropdown */}
                <Menu as="div" className="ml-4 relative flex-shrink-0">
                  <div className=" ">
                    <div className="flex items-center documentos-citas-btn">
                      {/* documentos */}
                      {
                        user?.usertype === 1
                        ? <Link to={'/my-documents'} className={`mr-4 text-base font-bold drop-shadow-lg ${pathname === "/my-documents" ? "documentos-btn" : "citas-btn"}`}>
                            Documentos
                          </Link>
                        : null
                      }
                      <Link to={String(user.usertype) === '2' ? '/appointment-manager-doctor' : '/appointment-manager'} className={`mr-4 text-base font-bold drop-shadow-lg ${(pathname === "/appointment-manager" || pathname === "/appointment-manager-doctor") ? "documentos-btn" : "citas-btn"}`}>
                        Citas
                      </Link>
                      <Menu.Button>
                        <figure className="border-4 border-sky-400	rounded-full w-16 h-16">
                          <img className="h-full w-full rounded-full " src={user?.personalImage ? `${APP_SERVER}/${user?.personalImage}` : Configurations?.defaultImage} alt="" onError={handleImageError} />
                        </figure>
                      </Menu.Button>
                    </div>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="z-40 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {String(user.usertype) === '2' && (
                        <Menu.Item>
                          {({ active }) => (
                            <Link to="/doctor-profile" className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                              Perfil Doctor
                            </Link>
                          )}
                        </Menu.Item>
                      )}
                      {String(user.usertype) === '2' && (
                        <Menu.Item>
                          {({ active }) => (
                            <Link to="/appointment-manager-doctor" className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                              Citas
                            </Link>
                          )}
                        </Menu.Item>
                      )}

                      {String(user.usertype) === '1' && (
                        <Menu.Item>
                          {({ active }) => (
                            <Link to="/patient-profile" className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                              Perfil Paciente
                            </Link>
                          )}
                        </Menu.Item>
                      )}
                      {String(user.usertype) === '1' && (
                        <Menu.Item>
                          {({ active }) => (
                            <Link to="/appointment-manager" className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                              Citas
                            </Link>
                          )}
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        {({ active }) => (
                          <span onClick={() => setOpenSignOutModal(true)} className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                            Cerrar sesión
                          </span>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            )}
            <RedAlert open={Boolean(openSignOutModal)} title={`Desea cerrar esta sesión?`} bodyText={'Si confirma esta diálogo entonces pasará a cerrar la sesión que tiene abierta.'} onConfirm={_confirmLogout} onCancel={_confirmSignOutCancel} />
          </div>
        </div>
      </div>

      <Transition as={Fragment} enter="duration-200 ease-out" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="duration-100 ease-in" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
        <Popover.Panel focus className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5 sm:pb-8">
              <div className="flex items-center justify-between">
                <div>
                  <img className="h-8 w-auto" src={hLogo} alt="DoctoLink" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-dl-primary-900">
                    <span className="sr-only">Cerrar menú</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="py-6 px-5">
              <div className="grid grid-cols-2 gap-4">
                {/* <Link className="rounded-md text-base font-medium text-gray-900 hover:text-dl-accent" to={'/'}>
                  Inicio
                </Link> */}
                <Link className="rounded-md text-base font-medium text-gray-900 hover:text-dl-accent" to={'/what-is-doctolink'}>
                  <Popover.Button > ¿Qué es Doctolink?</Popover.Button>
                </Link>
                <Link className="rounded-md text-base font-medium text-gray-900 hover:text-dl-accent" to={'/who-we-are'}>
                  <Popover.Button >  ¿Quiénes somos?</Popover.Button>
                </Link>
                {user && (
                  <>
                    {/* Profile dropdown */}

                    {String(user.usertype) === '2' && (
                      <Link to="/doctor-profile" className="rounded-md text-base font-medium text-gray-900 hover:text-dl-accent">
                        <Popover.Button > Perfil Doctor</Popover.Button>
                      </Link>
                    )}
                    {String(user.usertype) === '2' && (
                      <Link to="/appointment-manager-doctor" className="rounded-md text-base font-medium text-gray-900 hover:text-dl-accent">
                        <Popover.Button > Citas</Popover.Button>
                      </Link>
                    )}

                    {String(user.usertype) === '1' && (
                      <Link to="/patient-profile" className="rounded-md text-base font-medium text-gray-900 hover:text-dl-accent">
                        <Popover.Button > Perfil Paciente  </Popover.Button>
                      </Link>
                    )}
                    {String(user.usertype) === '1' && (
                      <Link to="/appointment-manager" className="rounded-md text-base font-medium text-gray-900 hover:text-dl-accent">
                        <Popover.Button >  Citas </Popover.Button>
                      </Link>
                    )}
                    <span onClick={() => setOpenSignOutModal(true)} className="rounded-md text-base font-medium text-gray-900 hover:text-dl-accent cursor-pointer">
                      Cerrar sesión
                    </span>
                  </>
                )}
              </div>
              {!user && (
                <div className="mt-6 flex flex-col gap-4">
                  <Link to={'/sign-up'} className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-dl-primary-900 hover:bg-dl-primary-700">
                    <Popover.Button >Registro Paciente</Popover.Button>
                  </Link>
                  <Link to={'/sign-up'} className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-dl-primary-900 hover:bg-dl-primary-700">
                    <Popover.Button >Registro Doctor</Popover.Button>
                  </Link>
                  <div className="flex flex-col gap-8 mt-6 text-center text-base font-medium text-dl-accent">
                    &iquest;Ya tienes cuenta de DoctoLink?{' '}
                    <Link className="text-dl-primary-900 hover:text-dl-primary-700" to="/sign-in">
                      <Popover.Button >Iniciar sesión como paciente</Popover.Button>
                    </Link>
                    <Link className="text-dl-primary-900 hover:text-dl-primary-700" to="/sign-in-doctor">
                      <Popover.Button > Iniciar sesión como doctor</Popover.Button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
