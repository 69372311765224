import { useEffect, useState } from 'react';

const API_SERVER = process.env.REACT_APP_API_SERVER

export default function useAds() {
    const [adsToDisplay, setAdsToDisplay] = useState(null);

    const requestAds = async () => {
        const attemptToGetAds = await fetch(`${API_SERVER}/ads/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (attemptToGetAds && attemptToGetAds.status < 400) {
            setAdsToDisplay(await attemptToGetAds.json());
            return true;
        }

        return false;
    };

    const onClickAd = async (idAds) => {
        const attemptToLogin = await fetch(`${API_SERVER}/ads/ad-click-tracker`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ idAds, origin: 'web' })
        });

        if (attemptToLogin && attemptToLogin.status < 400) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        requestAds();
    }, []);

    return {
        adsToDisplay,
        onClickAd
    };
}
