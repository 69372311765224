import WhiteCardContainer from '../../../../common/components/containers/forms/WhiteCardContainer';
import FormFieldsContainer from '../../../../common/components/containers/forms/FormFieldsContainer';
import FormInputField from '../../../../common/components/formElements/FormInputField';
import HalfRow from '../../../../common/components/formElements/HalfRow';
import FullRow from '../../../../common/components/formElements/FullRow';
import FormTextArea from '../../../../common/components/formElements/FormTextArea';
import FormButton from '../../../../common/components/formElements/FormButton';
import FormFieldsFooter from '../../../../common/components/containers/forms/FormFieldsFooter';
import FormSelectDrop from '../../../../common/components/formElements/FormSelectDrop';
import CustomRedAlert from '../../../../common/components/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../../common/components/customSuccessModal/CustomSuccessModal';
import FormResetButton from '../../../../common/components/formElements/FormResetButton';
import useDoctorExperience from '../../../../doctorUser/components/doctorOwnProfile/experience/hooks/useDoctorExperience';
import ProfileFormHeader from '../../../../doctorUser/components/ProfileFormHeader/ProfileFormHeader';
import ExperienceList from '../../../../doctorUser/components/doctorOwnProfile/experience/components/experienceList/ExperienceList';
import { Experiences } from '../../../services/DoctorProfile';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CxCard, CxTabButton, CxWrap, Line } from '../../../components/Sx';
import Loader from '../../../components/Loader';
const statusOptions = [
  { id: true, label: 'Activo' },
  { id: false, label: 'Inactivo' }
];

export default function Experience(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [experiences, setExperiences] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState(null);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorBody, setErrorBody] = useState('');
  const [successBody, setSuccessBody] = useState('');
  const [defaultValues, setDefaultValues] = useState({});
  const [isEditing, setEditing] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { register, handleSubmit, formState, getValues, reset, setValue } = useForm(defaultValues);
  const { errors, isDirty } = formState;

  useEffect(() => {
    setIsLoading(true);
    Experiences.getAllExperiences()
      .then((res) => {
        setExperiences(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    setIsLoading(true);
    if (selectedExperience) {
      setDefaultValues(selectedExperience);
      reset(selectedExperience);
      setEditing(true)
      setValue('status', selectedExperience.status ? 'true' : 'false', { shouldValidate: true });
    }
    setIsLoading(false);
  }, [reset, selectedExperience, setValue]);

  const resetState = () => {
    setShowSuccessModal(false);
    setShowErrorModal(false);
    setErrorBody('');
    setSuccessBody('');
    setDefaultValues({});
    setSelectedExperience(null);
    reset({ experienceName: '', experienceDetail: '' });
    setValue('status', '', { shouldValidate: true });
    setRefresh(!refresh);
    setEditing(false)
  };

  const _createErrorMessage = (errorMessage) => {
    setShowErrorModal(true);
    setErrorBody(errorMessage);
  };

  const _createSuccessMessage = (errorMessage) => {
    setShowSuccessModal(true);
    setSuccessBody(errorMessage);
  };
  const closeErrorMessage = () => {
    setShowErrorModal(false);
    setErrorBody('');
  };

  const onCloseSuccessMessage = () => {
    resetState();
  };

  const saveExperience = (data) => {
    if (!data) {
      _createErrorMessage('No se pudo obtener información válida para guardar. Inténtelo de nuevo.');
      return;
    }
    if (JSON.stringify(defaultValues) === '{}') {
      executeCreateExperience(data);
    } else {
      const adjustedData = { ...data, idExperience: data.id, status: data.status === 'true' };
      doUpdateExperience(adjustedData);
    }
  };

  const executeCreateExperience = async (newExperience) => {
    const createNewExperience = await Experiences.createExperience(newExperience);
    if (createNewExperience) {
      _createSuccessMessage('¡Se ha guardado el registro con éxito!');
    }
  };

  const doUpdateExperience = async (updatedExperienceObject) => {
    const executeUpdatedExperience = await Experiences.updateExperience(updatedExperienceObject);
    if (executeUpdatedExperience) {
      _createSuccessMessage('¡Se ha guardado el registro con éxito!');
    }
  };
  if (isLoading) return <Loader />;
  return (
    <>
      <form onSubmit={handleSubmit(saveExperience)}>
        <CxWrap>
          <CxCard>
            <ProfileFormHeader
              title={!isEditing ? 'Nuevo Experiencia' : 'Actualizar Experiencia'}
              initialText={'Ingresa los datos que te interesa que el paciente conozca sobre tu experiencia.'}
            />
          </CxCard>
          <Line />
          <CxCard>
            <div className="grid grid-cols-6 gap-6">
              <HalfRow>
                <FormInputField
                  identifier={'experienceName'}
                  label={'Título de la experiencia'}
                  type={'text'}
                  placeholder={'Ej. Emergencias en Hospital General (2007-2010)'}
                  helperText={'Requerido'}
                  registration={register('experienceName', { required: true })}
                  errorMessage={errors.experienceName ? 'Este campo es requerido' : null}
                />
              </HalfRow>
              <FullRow>
                <FormTextArea
                  label={'Detalle'}
                  identifier={'experienceDetail'}
                  rows={3}
                  placeholder={'Ej. Experiencia en tratamientos intensivos durante todo mi tiempo en el hospital'}
                  helperText={'Requerido'}
                  registration={register('experienceDetail', { required: true })}
                  errorMessage={errors.experienceDetail ? 'Este campo es requerido' : null}
                />
              </FullRow>
              {isEditing && (
                <HalfRow>
                  <FormSelectDrop
                    label={'Estado'}
                    identifier={'status'}
                    options={statusOptions}
                    defaultValue={defaultValues.status}
                    helperText={'Requerido'}
                    registration={register('status', { required: true })}
                    errorMessage={errors.status ? 'Este campo es requerido' : null}
                  />
                </HalfRow>
              )}
            </div>
            {/* <div className="h-40"></div> */}
            
          </CxCard>

          <hr className="mt-2  pt-2 "></hr>

          <div className="flex justify-end gap-2  px-10 py-4">
              <CxTabButton label="Cancelar" variant="outlined" onClick={resetState} />
              <CxTabButton label={isEditing ? 'Actualizar' : 'Guardar'} disabled={isEditing && !isDirty} />
            </div>

        
        </CxWrap>

       
        {/* <div className="px-4 py-3  text-right sm:px-6">
          <CxTabButton label="Cancelar" variant="outlined" onClick={resetState} />
          <CxTabButton label={isEditing ? 'Actualizar' : 'Guardar'} disabled={isEditing && !isDirty} />
        </div> */}
        {showSuccessModal && <CustomSuccessModal title={'Acción ejecutada con éxito!'} bodyText={successBody} buttonText={'Cerrar'} buttonAction={onCloseSuccessMessage} open={showSuccessModal} onClose={onCloseSuccessMessage} />}
        {showErrorModal && <CustomRedAlert open={true} title={'Ocurrió un error.'} bodyText={errorBody} onClose={closeErrorMessage} />}
      </form>
      <div className="mt-10">
        <div className="">
          {/* <ProfileFormHeader title={'Educación existentes'} /> */}
          <ProfileFormHeader title={'Experiencia existentes'} fontFamily="font-aristotelica" />

        </div>
        {experiences.length === 0 && <p className="ml-4 mt-4">No hay motivos para mostrar</p>}
        {experiences.length > 0 && <ExperienceList items={experiences} onClick={setSelectedExperience} />}
      </div>
    </>
  );
}
