import ErrorBoundary from '../../../common/components/errorBoundaries/ErrorBoundary';
import PersonalInfo from './PersonalInfo';
import DoctorProfileNavigation from '../../../doctorUser/components/doctorProfileNavigation/DoctorProfileNavigation';
import create from 'zustand';
import ProfilePublic from './ProfilePublic';


export const useStore = create((set) => ({
  personal: true,
  info: false,
  tabs: [{ name: 'Perfil Público' }, { name: 'Información Personal' }],
  selectedTabIndex: 1,
  setSelectedTabIndex: (index) => set({ selectedTabIndex: index }),
}));

const DoctorUserProfile = () => {
  const { selectedTabIndex } = useStore((state) => state);

  return (
    <ErrorBoundary>
      <div className="lg:grid lg:grid-cols-12">
        {/* Sidebar */}
        <DoctorProfileNavigation />
        {/* Content */}
        {selectedTabIndex === 1 ? <PersonalInfo /> : null}
        {selectedTabIndex === 2 ? <ProfilePublic /> : null}
      </div>
    </ErrorBoundary>
  );
};

export default DoctorUserProfile;
