import { useEffect, useMemo, useState } from 'react';
import { SecondarySpecialty } from '../../../services/DoctorProfile';
import ControlledFormSelectDrop from '../../../../common/components/formElements/controlled/ControlledFormSelectDrop';
import FormButton from '../../../../common/components/formElements/FormButton';
import { useForm } from 'react-hook-form';
import { CxCard, CxTabButton, CxWrap, Line } from '../../../components/Sx';
import ProfileFormHeader from '../../../../doctorUser/components/ProfileFormHeader/ProfileFormHeader';
import HalfRow from '../../../../common/components/formElements/HalfRow';
import CustomSuccessModal from '../../../../common/components/customSuccessModal/CustomSuccessModal';
import CustomRedAlert from '../../../../common/components/customRedAlert/CustomRedAlert';

export default function SecondarySpecialties(props) {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successBody, setSuccessBody] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorBody, setErrorBody] = useState('');

  const { basicProfile } = props;
  const [primarySpecialty, setPrimarSpeciality] = useState();
  const [secSpecialty, setSecSpecialty] = useState([]);
  const [userSecSpecialty, setUserSecSpecialty] = useState([]);
  const [allSecSpecialty, setAllSecSpecialties] = useState([]);

  // const isEditing = JSON.stringify(defaultValues) !== '{}';
  const [isEditing, setEditing] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [initialData, setInitialData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const { register, handleSubmit, formState, watch, reset, setValue, getValues, setError } = useForm();
  const { errors, isDirty } = formState;

  const _createSuccessMessage = (message) => {
    setShowSuccessModal(true);
    setSuccessBody(message);
  };

  const onCloseSuccessMessage = () => {
    setShowSuccessModal(false);
    setSuccessBody('');
  };

  const _createErrorMessage = (errorMessage) => {
    setShowErrorModal(true);
    setErrorBody(errorMessage);
  };

  const closeErrorMessage = () => {
    setShowErrorModal(false);
    setErrorBody('');
  };

  const saveForm = (values) => {
    console.clear();
    const { idSpecialty, specialityIds } = values;
    const primarySpeciality = parseInt(idSpecialty);

    if (specialityIds.includes(primarySpeciality)) {
      _createErrorMessage('Primary speciality already exists in secondary specialities.');
    } else {
      SecondarySpecialty.saveSecondarySpecialty(values)
        .then(() => _createSuccessMessage('Especialidad   actualizada para perfil.'))
        .then(() => setRefresh(!refresh))
        .catch((err) => console.log(err));
    }
  };

  const fxData = async () => {
    try {
      const response = await SecondarySpecialty.getUserSecondarySpecialty();
      setValue('idSpecialty', response.data.primarySpecialty);
      setPrimarSpeciality(response.data.primarySpecialty);
      const formattedsecSpecialty = response.data.secondarySpecialty.map((specialty) => ({ id: specialty.idSpecialty, label: specialty.name }));
      let specialityIds = formattedsecSpecialty.map((item) => item.id);
      setValue('specialityIds', specialityIds);
      setSelectedItems(formattedsecSpecialty);
      setInitialData(formattedsecSpecialty);

      const getAllSpecialty = await SecondarySpecialty.getAllSecondarySpecialty();
      const allFormattedsecSpecialties = getAllSpecialty.data.map((specialty) => ({ id: specialty.id, label: specialty.name }));
      setAllSecSpecialties(allFormattedsecSpecialties);

      // setUserSecSpecialty(formattedsecSpecialty);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fxData();
  }, [refresh]);

  const fxUpdateItem = (selected) => {
    console.clear();
    let ifExist = selectedItems.find((arr) => arr.id.toString() === selected.toString());
    console.log(selectedItems.find((arr) => arr.id.toString() === selected.toString()));
    console.log({ selectedItems });
    console.log({ selected });
    if (!ifExist) {
      const obj = allSecSpecialty.find((element) => element.id.toString() === selected.toString());
      let arr = [...selectedItems];
      arr.push(obj);
      setSelectedItems(arr);
      let specialityIds = arr.map((item) => item.id);
      setValue('specialityIds', specialityIds);
    }

    // console.log({ allSecSpecialty });
    // console.log(selected);
    // console.log(obj);
    // console.log(arr);
  };

  const fxDeleteItem = (item) => {
    console.log(item);
    setEditing(true);
    const arr = selectedItems.filter((element) => element.id !== item.id);
    console.log(arr);
    setSelectedItems(arr);
    let specialityIds = arr.map((item) => item.id);
    setValue('specialityIds', specialityIds);
  };

  const fxReset = () => {
    setEditing(false);
    let specialityIds = initialData.map((item) => item.id);
    setValue('specialityIds', specialityIds);
    setSelectedItems(initialData);
  };

  const formattedsecSpecialty = useMemo(() => {
    if (!basicProfile || !secSpecialty || !userSecSpecialty) return;

    return secSpecialty
      .filter((lang) => {
        return userSecSpecialty.findIndex((inProfile) => inProfile.label === lang.name) === -1;
      })
      .map((lang) => {
        return { id: lang.id, label: lang.name };
      });
  }, [basicProfile, secSpecialty, userSecSpecialty]);

  const speciality = useMemo(() => {
    return allSecSpecialty.find((item) => item.id.toString() === primarySpecialty.toString());
  }, [allSecSpecialty, primarySpecialty]);
  // console.log({ speciality, primarySpecialty });
  // console.log(getValues());

  const fxhandlePrimarySpeciality = (e) => {
    setPrimarSpeciality(e.target.value)
    setValue("idSpecialty", parseInt(e.target.value))
  }

  return (
    <form onSubmit={handleSubmit(saveForm)}>
      <CxWrap>
        <CxCard>
        {/* Nuevo Especialidaded */}
        
          <ProfileFormHeader title={!isEditing ? 'Especialidad primaria' : 'Actualizar Especialidaded'} initialText={``}  />
        </CxCard>
        <Line />
        <CxCard>
          <div className="grid grid-cols-12 gap-4 pb-8 px-4">
            <HalfRow>
              <ControlledFormSelectDrop label={'Primaria Especialidaded'} identifier={'idSpecialty'} options={allSecSpecialty} value={speciality} emptyValue={'Seleccione'} errorMessage={errors.idSpecialty?.message} onChange={fxhandlePrimarySpeciality} />
            </HalfRow>
          </div>
       
          </CxCard>
          </CxWrap>
          <div className=" flex  pt-10 pl-12">
            <div class="py-2 px-8 !w-auto  flex justify-between  items-center gap-2 shadow-md no-underline rounded-full light-bg-blue text-white font-sans font-semibold text-sm border-blue btn-primary hover:text-white hover:bg-blue-light focus:outline-none active:shadow-none mr-2">
              {speciality?.label}
              <span className="cursor-pointer pl-3 text-xl text-gray-500 font-bold	">x</span>
            </div>
          </div>
          <CxWrap>
          <CxCard>
          <ProfileFormHeader title={!isEditing ? 'Especialidad secundaria' : 'Actualizar Especialidaded'} initialText={``} />
        </CxCard>
        <Line />
          <CxCard>
       
          <div className="grid grid-cols-12 gap-4 pb-8 px-4">
            <HalfRow>
              <ControlledFormSelectDrop label={'Especialidad secundaria'} identifier={'secundariaEspecialidaded'} options={allSecSpecialty} value={formattedsecSpecialty} emptyValue={'Seleccione'}  onChange={(e) => fxUpdateItem(e.target.value)} />
            </HalfRow>
          </div>



          <div className="flex justify-end gap-2">
            <CxTabButton label="Cancelar" variant="outlined" onClick={fxReset} />
            <CxTabButton label={isEditing ? 'Actualizar' : 'Guardar'} />
          </div>
        </CxCard>
      </CxWrap>

      <div className=" flex mt-10 pt-0 pl-12">
            {selectedItems.map((item) => (
              <div class="py-2 px-4 mr-6 !w-auto  flex items-center justify-between gap-2 shadow-md no-underline rounded-full light-bg-blue  text-white font-sans font-semibold text-sm border-blue btn-primary hover:text-white hover:bg-blue-light focus:outline-none active:shadow-none mr-2">
                {item.label}
                <span className="cursor-pointer pl-3 text-xl text-gray-500 font-bold	" onClick={() => fxDeleteItem(item)}>
                  x
                </span>
              </div>
            ))}
          </div>

      {showSuccessModal && <CustomSuccessModal title={'Acción ejecutada con éxito!'} bodyText={successBody} buttonText={'Cerrar'} buttonAction={onCloseSuccessMessage} open={showSuccessModal} onClose={onCloseSuccessMessage} />}
      {showErrorModal && <CustomRedAlert open={true} title={'Ocurrió un error.'} bodyText={errorBody} onClose={closeErrorMessage} />}
    </form>
  );
}
