// Esto vendra del servicio web

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const search = 'search-results?q=';
const projects = [
  { name: 'Anestesiología', initials: 'A', href: search + 'Anestesiología', members: 0, bgColor: 'bg-dl-secondary' },
  { name: 'Cardiología Clínica', initials: 'CC', href: search + 'Cardiología Clínica', members: 12, bgColor: 'bg-dl-secondary' },
  { name: 'Dermatología', initials: 'D', href: search + 'Dermatología', members: 16, bgColor: 'bg-dl-secondary' },
  { name: 'Gastroenterología', initials: 'G', href: search + 'Gastroenterología', members: 8, bgColor: 'bg-dl-secondary' },
  { name: 'Hematología', initials: 'H', href: search + 'Hematología', members: 0, bgColor: 'bg-dl-secondary' },
  { name: 'Medicina Interna', initials: 'MI', href: search + 'Medicina Interna', members: 12, bgColor: 'bg-dl-secondary' },
  { name: 'Nefrología', initials: 'N', href: search + 'Nefrología', members: 16, bgColor: 'bg-dl-secondary' },
  { name: 'Oftalmología', initials: 'O', href: search + 'Oftalmología', members: 8, bgColor: 'bg-dl-secondary' }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Specialty() {
  const [specialities, setSpecialities] = useState([]);
  const [showAllSpecialities, setShowAllSpecialities] = useState(false);
  const API_BASE_URL = process.env.REACT_APP_API_SERVER;

  const getData = async () => {
    await axios.get(`${API_BASE_URL}/specialty/getList`)
       .then((res) => {
          const specialitiesData = res.data.map((item) => ({ ...item, initials: item.name.split(' ').map(el => el.charAt(0).toUpperCase()).join(''), href: search + item.name, bgColor: 'bg-dl-secondary' }))
          setSpecialities(specialitiesData);
          console.log(specialitiesData, 'specialitiesData');
       })
       .catch((error) => {
          console.log(error);
       });
  }

  useEffect(() => {
      getData();
  }, [])

  return (
    <>
      <div className="our-specility pb-12  px-0">
        <div className="wrapper-box">
          <h2 className="common-title blue-title pb-5">Lista de especialidades</h2>
          <ul className=" grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
            {specialities.map((speciality, index) => {
              if (showAllSpecialities ? true : index < 8) {
                return (<Link key={speciality.name} to={speciality.href} className="text-dl-accent font-medium hover:text-gray-600 relative ">
                  <li className="col-span-1 flex shadow-sm rounded-md">
                    <div className={classNames(speciality.bgColor, 'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md hover:bg-dl-accent blue-box-number')}>{speciality.initials}</div>
                    <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                      <div className="flex-1 px-4 py-4 text-md truncate white-box-text">{speciality.name}</div>
                    </div>
                  </li>
    
                  { speciality.description
                    ? <div className='hover-content-box z-10 hidden bg-white absolute top-20  left-0 right-0  rounded-[24px] px-8 py-8 '>
                      <p className='text-sm	text-base	text-center text-black'>{speciality.description}</p>
                    </div>
                    : null
                  }
                </Link>)
              }
            })}
          </ul>
          
        </div>
      </div>
      <div class="relative divider-add">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300 border-line"></div>
        </div>
        <div class="relative flex justify-center ">
            <span onClick={() => setShowAllSpecialities(!showAllSpecialities)} class="bg-dl-accent px-2 py-2 text-white rounded-full add-btn">
              {
                  showAllSpecialities
                  ? <svg class="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><path fill="rgba(255,255,255,1)" d="M19 11H5V13H19V11Z"></path></svg>
                  : <svg class="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path fill="currentColor" fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd"></path>
                    </svg>
              }
            </span>
        </div>
      </div>
    </>
  );
}
