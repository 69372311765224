import imgSlide from './../images/what-is-doctolink3.png';
import imgSlideSecond from './../images/what-is-doctolink2.png';
import imgDcotersgroup from './../images/docter-group-img.png';
import imgShapefour from './../images/shape-4.svg';
import imgShapefive from './../images/Stetoscope.png';
export default function WhatIsDoctolink() {
return (
<>
<div className="">
   <div className="pt-60 pb-60 whta-is-docter-link-box-main">
      <div className="whta-is-docter-link-box">
         <div className="wrapper-box">
            <div className='what-is-docter-link-box-inner'>
               <div class="grid grid-cols-2 h-full">
                  <div className='what-is-docter-link-box-L'>
                     <blockquote className="">
                        <h2 className="docter-team-title">
                           ¿Qué es Doctolink?
                        </h2>
                        <p className="docter-team-sub-title">
                           Innovamos la experiencia en el mundo de la salud de una manera amigable y accesible,
                           creando una plataforma digital en la que el paciente y el médico estén conectados.
                        </p>
                     </blockquote>
                  </div>
                  <div className='what-is-docter-link-box-R'>
                     <figure className="img-box">
                        <img src={imgDcotersgroup} className="" alt="" />
                     </figure>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div className="pt-40 whta-is-docter-link-bottom">
         <div className="what-is-our-goal-box">
            <div className='what-is-our-goal'>
               <figure className="shape-5">
                  <img src={imgShapefive} className="" alt="" />
               </figure>
               <blockquote className="">
                  <h2 className="docter-team-title">
                     ¿Cuál es nuestro objetivo?
                  </h2>
                  <p className="docter-team-sub-title">
                     Nuestra misión es ser la plataforma digital referente en la transformación del mundo
                     de la salud.
                  </p>
               </blockquote>
            </div>
            <figure className="shape-4">
               <img src={imgShapefour} className="" alt="" />
            </figure>
         </div>
      </div>
   </div>
</div>
</>
);
}