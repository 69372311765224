import getAppointmentHistory from '../../../api/getAppointmentHistory';
import { useEffect, useMemo, useState } from 'react';
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter } from 'react-table';

const statusNames = {
  pending: 'Pendiente',
  completed: 'Completada',
  canceled_by_doctor: 'Cancelada por doctor',
  canceled_by_user: 'Cancelada por usuario'
};

export default function useAppointmentHistory() {
  const [pendingAppointments, setPendingAppointments] = useState([]);
  const [completedAppointments, setCompletedAppointments] = useState([]);
  const [cancelledAppointments, setCancelledAppointments] = useState([])
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectionMode, setSelectionMode] = useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState({});

  const queryAppointmentHistory = async () => {
    const getHistory = await getAppointmentHistory(setErrorMessage);

    if (getHistory && getHistory.pending) {
      setPendingAppointments(getHistory.pending);
      setCompletedAppointments(getHistory.past);
      setIsLoading(false);
      return;
    }

    setIsLoading(false);

    setErrorMessage('No se pudo completar la solicitud de citas. Inténtelo de nuevo.');
  };

  useEffect(() => {
    queryAppointmentHistory();
  }, []);

  const _handleClick = (event, selectedItem, mode) => {
    event.preventDefault();
    setSelectedAppointment(selectedItem);
    setSelectionMode(mode)
  };

  const _closeAppointmentView = () => {
    setSelectedAppointment({});
    setSelectionMode(null);
    queryAppointmentHistory();
  };

  const completedTable = useMemo(() => {
    return completedAppointments?.map((appointment) => {
      return {
        col1: appointment.doctorFullName,
        col2: appointment.motiveName,
        col3: appointment.appDate,
        col4: appointment.appTime,
        col5: statusNames[appointment.statusName],
        col6: (
          <>
            <a href="#" onClick={(e) => _handleClick(e, appointment, 'viewOnly')} className="text-dl-primary-900 hover:text-dl-accent">
              Ver Detalles
              <span className="sr-only">, {appointment.userFullName}</span>
            </a>
            {/* <a href="#" className="ml-2 text-dl-primary-900 hover:text-dl-accent" onClick={(e) => _handleClick(e, appointment, 'edit')}>
              editar detalles
            </a> */}
          </>
        )
      };
    });
  }, [completedAppointments]);

  const cancelledTable = useMemo(() => {
    return cancelledAppointments?.map((appointment) => {
      return {
        col1: appointment.doctorFullName,
        col2: appointment.motiveName,
        col3: appointment.appDate,
        col4: appointment.appTime,
        col5: statusNames[appointment.statusName],
        col6: (
          <>
            <a href="#" onClick={(e) => _handleClick(e, appointment, 'viewOnly')}  className="text-dl-primary-900 hover:text-dl-accent">
              Ver Detalles
              <span className="sr-only">, {appointment.userFullName}</span>
            </a>
          </>
        )
      };
    });
  }, [cancelledAppointments]);

  const pendingTable = useMemo(() => {
    return pendingAppointments?.map((appointment) => {
      return {
        col1: appointment.doctorFullName,
        col2: appointment.motiveName,
        col3: appointment.appDate,
        col4: appointment.appTime,
        col5: statusNames[appointment.statusName],
        col6: (
          <>
            <a href="#" onClick={(e) => _handleClick(e, appointment, 'viewOnly')} className="text-dl-primary-900 hover:text-dl-accent align-middle">
              Ver Detalles
              <span className="sr-only">, {appointment.userFullName}</span>
            </a>
            <a href="#" className="ml-2 grey-btn" onClick={(e) => _handleClick(e, appointment, 'reschedule')}>
              Reprogramar
            </a>
          </>
        )
      };
    });
  }, [pendingAppointments]);

  const appointmentColumns = useMemo(() => {
    return [
      {
        Header: 'Doctor',
        accessor: 'col1' // accessor is the "key" in the data
      },
      {
        Header: 'Motivo',
        accessor: 'col2' // accessor is the "key" in the data
      },
      {
        Header: 'Fecha',
        accessor: 'col3' // accessor is the "key" in the data
      },
      {
        Header: 'Hora',
        accessor: 'col4' // accessor is the "key" in the data
      },
      {
        Header: 'Estado',
        accessor: 'col5' // accessor is the "key" in the data
      },
      {
        Header: 'Acciones',
        accessor: 'col6' // accessor is the "key" in the data
      }
    ];
  }, []);

  const completedTableInstance = useTable(
    { columns: appointmentColumns, data: completedTable },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!,
    useSortBy,
    usePagination
  );
  const pendingTableInstance = useTable(
    { columns: appointmentColumns, data: pendingTable },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!,
    useSortBy,
    usePagination
  );
  const cancelledTableInstance = useTable(
    { columns: appointmentColumns, data: cancelledTable },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!,
    useSortBy,
    usePagination
  );

  return {
    pendingAppointments,
    completedAppointments,
    errorMessage,
    queryAppointmentHistory,
    completedTable,
    pendingTable,
    isLoading,
    setIsLoading,
    appointmentColumns,
    completedTableInstance,
    cancelledTableInstance,
    pendingTableInstance,
    selectionMode,
    setSelectionMode,
    _closeAppointmentView,
    selectedAppointment,

  };
}
