import { useStore } from '../../../v2/pages/DoctorProfile/DoctorUserProfile';

const cx = (...classes) => classes.filter(Boolean).join(' ');

export default function DoctorProfileNavigation() {
  const { tabs, setSelectedTabIndex, selectedTabIndex } = useStore((state) => state);

  return (
    <aside className="lg:col-span-2 bg-white side-bar-profile">
      <nav className="space-y-1 ml-3 mt-5">
        <div className="btn-sidebar flex flex-col gap-2 w-9/12 my-[50px] mx-auto">
          {tabs.map((tab, i) => (
            <button className={cx(selectedTabIndex === i + 1 ? 'btn-primary mb-6' : 'btn-out-line', 'flex items-center justify-center !w-full')} onClick={() => setSelectedTabIndex(i + 1)}>
              {tab.name}
            </button>
          ))}
        </div>
      </nav>
    </aside>
  );
}
