import FormInputField from '../../../../common/components/formElements/FormInputField';
import { CxTabButton, CxCard, CxWrap, Line } from '../../../components/Sx';
import { useEffect, useState } from 'react';
import { InfoS } from '../../../services/DoctorProfile';
import CustomSuccessModal from '../../../../common/components/customSuccessModal/CustomSuccessModal';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
const phoneRegExp = /^([+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*|\s*)$/;
const formSchema = z.object({
  contact: z.string().regex(phoneRegExp, { message: 'Numero de telefono invalido' }).max(15, { message: 'El teléfono debe contener como máximo 15 números' }).min(7, { message: 'El teléfono debe contener al menos 7 números' }).or(z.literal("")),
  whatsapp: z.string().regex(phoneRegExp, { message: 'Numero de telefono invalido' }).max(12, { message: 'El teléfono debe contener como máximo 12 números' }).min(7, { message: 'El teléfono debe contener al menos 7 números' }).or(z.literal("")),
  instagram: z.string().url({ message: 'URL invalida' }).or(z.literal("")),
  facebook: z.string().url({ message: 'URL invalida' }).or(z.literal("")),
  twitter: z.string().url({ message: 'URL invalida' }).or(z.literal("")),
  linkedin: z.string().url({ message: 'URL invalida' }).or(z.literal("")),
  website: z.string().url({ message: 'URL invalida' }).or(z.literal("")),
  otherLink: z.string().url({ message: 'URL invalida' }).or(z.literal(""))
});

const Info = () => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState({})
  const [successBody, setSuccessBody] = useState('');
  const { register, handleSubmit, formState, getValues, reset } = useForm({ resolver: zodResolver(formSchema) });
  const { errors } = formState;
  useEffect(() => {
    InfoS.getInfo().then((res) => {
      if (res.data[0]) {
        setData(res.data[0])
        reset({
          contact: res.data[0].contact,
          facebook: res.data[0].facebook,
          instagram: res.data[0].instagram,
          twitter: res.data[0].twitter,
          linkedin: res.data[0].linkedin,
          otherLink: res.data[0].otherLink,
          website: res.data[0].website,
          whatsapp: res.data[0].whatsapp,
        });
      }
    });
  }, [refresh]);

  const _createSuccessMessage = (message) => {
    setShowSuccessModal(true);
    setSuccessBody(message);
  };
  const onCloseSuccessMessage = () => {
    setShowSuccessModal(false);
    setSuccessBody('');
  };
  const saveForm = (values) => {

    const { idDoctorProfile, id } = data
    InfoS.updateInfo({ ...values, idDoctorProfile, id }).then((res) => {
      _createSuccessMessage('Idioma actualizado para el perfil.')
      setRefresh(!refresh);
    });
  };

  return (
    <form onSubmit={handleSubmit(saveForm)}>
      <CxWrap>
        <CxCard>Contacto</CxCard>
        <Line />
        <CxCard>
          <div className="flex flex-col gap-4 w-1/2 pb-8 px-4"  >
            <div className="col-span-2 sm:col-span-6">
              <FormInputField identifier="contact" label="Contacto clínica" type="string" registration={register('contact')} errorMessage={errors.contact?.message} /></div>
            <div className="col-span-2 sm:col-span-6"> <FormInputField identifier="whatsapp" label="Whatsapp" type="string" registration={register('whatsapp')} errorMessage={errors.whatsapp?.message} /></div>
          </div>
        </CxCard>
      </CxWrap>
      <CxWrap>
        <CxCard>Redes Sociales</CxCard>
        <Line />
        <CxCard>
          <div className="flex flex-col gap-4 w-1/2 pb-8 px-4"  >
            <div className="col-span-2 sm:col-span-6">  <FormInputField identifier="instagram" label="Instagram" type="string" registration={register('instagram')} errorMessage={errors.instagram?.message} /></div>
            <div className="col-span-2 sm:col-span-6">  <FormInputField identifier="facebook" label="Facebook" type="string" registration={register('facebook')} errorMessage={errors.facebook?.message} /></div>
            <div className="col-span-2 sm:col-span-6">  <FormInputField identifier="twitter" label="Twitter" type="string" registration={register('twitter')} errorMessage={errors.twitter?.message} /></div>
            <div className="col-span-2 sm:col-span-6">  <FormInputField identifier="linkedin" label="LinkedIn" type="string" registration={register('linkedin')} errorMessage={errors.linkedin?.message} /></div>
          </div>
        </CxCard>
      </CxWrap>
      <CxWrap>
        <CxCard>Pagina personal</CxCard>
        <Line />
        <CxCard>
          <div className="flex flex-col gap-4 w-1/2 pb-8 px-4"  >
            <div className="col-span-2 sm:col-span-6"> <FormInputField identifier="website" label="Sitio web" type="string" registration={register('website')} errorMessage={errors.website?.message} /> </div>
            <div className="col-span-2 sm:col-span-6">   <FormInputField identifier="otherLink" label="Otro link" type="string" registration={register('otherLink')} errorMessage={errors.otherLink?.message} /> </div>
          </div>
        </CxCard>
      </CxWrap>
      <div className=" pt-12 pr-12  text-right ">
        <CxTabButton label="Actualizar" />
      </div>

      {showSuccessModal && (
        <CustomSuccessModal
          title='Registro actualizado con éxito'
          bodyText={successBody}
          buttonText={'Aceptar'}
          buttonAction={onCloseSuccessMessage}
          open={showSuccessModal}
          onClose={onCloseSuccessMessage}
        />
      )}
    </form>
  );
};

export default Info;
