import { CardWrap } from './DoctorResultsTabsElement';

const Info = ({ doctorData }) => {

  return (
    <>
      <CardWrap>
        <div className="px-4 py-4 sm:px-6 border-b divide-slate-200 border-black flex items-center  insurance-information-head">
          <h2 id="applicant-information-title" className="text-lg leading-6 font-bold text-black sm:px-6 border-red horarios-title">
            Contacto 1
          </h2>
        </div>
        <dl className="content-list-box flex items-center justify-between px-6 py-4">
          <div className="inner-content px-6">
            <p>{doctorData.contact}</p>
          </div>
        </dl>
      </CardWrap>
      {/* <CardWrap>
        <div className="px-4 py-4 sm:px-6 border-b divide-slate-200 border-black flex items-center  insurance-information-head">
          <h2 id="applicant-information-title" className="text-lg leading-6 font-bold text-black sm:px-6 border-red horarios-title">
            Contacto 2
          </h2>
        </div>
        <dl className="content-list-box flex items-center justify-between px-6 py-4">
          <div className="inner-content px-6">
            <p>{doctorData.patientContactPhone2}</p>
          </div>
        </dl>
      </CardWrap> */}
      <CardWrap>
        <div className="px-4 py-4 sm:px-6 border-b divide-slate-200 border-black flex items-center  insurance-information-head">
          <h2 id="applicant-information-title" className="text-lg leading-6 font-bold text-black sm:px-6 border-red horarios-title">
            Permiso
          </h2>
        </div>
        <dl className="content-list-box flex items-center justify-between px-6 py-4">
          <div className="inner-content px-6">
            <p>{doctorData.governmentPermitInfo}</p>
          </div>
        </dl>
      </CardWrap>
      <CardWrap>
        <div className="px-4 py-4 sm:px-6 border-b divide-slate-200 border-black flex items-center  insurance-information-head">
          <h2 id="applicant-information-title" className="text-lg leading-6 font-bold text-black sm:px-6 border-red horarios-title">
            Redes Sociales
          </h2>
        </div>
        <dl className="content-list-box  px-6 py-4">
          <div className="inner-content px-6 flex items-center gap-7 flex-wrap">
            {doctorData.whatsapp ?
              <span className='flex items-center flex-row gap-4'>
                <a href={`https://api.whatsapp.com/send?phone=${doctorData.whatsapp}`} target='_blank'>
                  <img src="/defaultIcons/whatsapp.png" className="h-[40px]" />
                </a>
              </span>
              : ""
            }
            {doctorData.facebook ?
              <span className='flex items-center flex-row gap-4'>
                <a href={doctorData.facebook} target='_blank'>
                  <img src="/defaultIcons/facebook.png" className="h-[40px]" />
                </a>
              </span>
              : ""
            }
            {doctorData.instagram ?
              <span className='flex items-center flex-row gap-4'>
                <a href={doctorData.instagram} target='_blank'>
                  <img src="/defaultIcons/instagram.png" className="h-[40px]" />
                </a>
              </span>
              : ""
            }
            {doctorData.twitter ?
              <span className='flex items-center flex-row gap-4'>
                <a href={doctorData.twitter} target='_blank'>
                  <img src="/defaultIcons/twitter.png" className="h-[40px]" />
                </a>
              </span>
              : ""
            }
            {doctorData.linkedin ?
              <span className='flex items-center flex-row gap-4'>
                <a href={doctorData.linkedin} target='_blank'>
                  <img src="/defaultIcons/linkedin.png" className="h-[40px]" />
                </a>
              </span>
              : ""
            }
          </div>
        </dl>
      </CardWrap>
      <CardWrap>
        <div className="px-4 py-4 sm:px-6 border-b divide-slate-200 border-black flex items-center  insurance-information-head">
          <h2 id="applicant-information-title" className="text-lg leading-6 font-bold text-black sm:px-6 border-red horarios-title">
            Sitio web
          </h2>
        </div>
        <dl className="content-list-box  px-6 py-4">
          <div className="inner-content px-6 flex items-center gap-7 flex-wrap">
            {doctorData.website ?
              <span className='flex items-center flex-row gap-4'>
                <a href={doctorData.website} target='_blank'>
                  {doctorData.website}
                </a>
              </span>
              : ""
            }
          </div>
        </dl>
      </CardWrap>
      <CardWrap>
        <div className="px-4 py-4 sm:px-6 border-b divide-slate-200 border-black flex items-center  insurance-information-head">
          <h2 id="applicant-information-title" className="text-lg leading-6 font-bold text-black sm:px-6 border-red horarios-title">
            Otro enlace
          </h2>
        </div>
        <dl className="content-list-box  px-6 py-4">
          <div className="inner-content px-6 flex items-center gap-7 flex-wrap">
            {doctorData.otherLink ?
              <span className='flex items-center flex-row gap-4'>
                <a href={doctorData.otherLink} target='_blank'>
                  {doctorData.otherLink}
                </a>
              </span>
              : ""
            }
          </div>
        </dl>
      </CardWrap>
    </>
  );
};
export default Info;
