import { useEffect, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { add, eachDayOfInterval, endOfMonth, format, getDay, isEqual, isSameDay, isSameMonth, isToday, parse, parseISO, startOfToday } from 'date-fns';
import CalendarSkeleton from '../../../../components/appointmentCalendar/CalendarSkeleton';
import es from 'date-fns/locale/es';
import { Appointment } from '../../../services/Appointment';
import { useInfoStore } from '../../../../App';
import { useNavigate } from 'react-router-dom';
import Today from '../../../components/Today';
import configurations from '../../../../common/utils/configurations';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

let colStartClasses = ['', 'col-start-2', 'col-start-3', 'col-start-4', 'col-start-5', 'col-start-6', 'col-start-7'];

const AppointmentPending = ({ isHomeRoute }) => {
  const { userInfo } = useInfoStore((state) => state);
  let today = startOfToday();
  const navigate = useNavigate();
  const [schedules, setSchedules] = useState([]);
  let [selectedDay, setSelectedDay] = useState(today);
  let [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'));
  let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date());

  const [showDetail, setShowDetail] = useState(false);
  const [patient, setPatient] = useState({});
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    Appointment.getAppointment()
      .then((res) => setSchedules([...res.data.pending, ...res.data.pendingWithoutUser]))
      .then(() => setLoading(false))
      .catch((e) => console.log(e));
  }, [selectedDay]);

  if (isLoading) {
    return <CalendarSkeleton />;
  }

  let days = eachDayOfInterval({ start: firstDayCurrentMonth, end: endOfMonth(firstDayCurrentMonth) });

  function previousMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
  }

  function nextMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
  }

  const fxItem = (item) => {
    console.log(item,"item");
    setShowDetail(true);
    setPatient(item);
  };

  const checkActiveSlot = (item) => {
    console.log(item);
    if (item.apptID && item.apptID === patient.apptID) {
      return true;
    } else if (item.idappt_without_user && item.idappt_without_user === patient.idappt_without_user) {
      return true;
    }
    return false;
  };
  let selectedDayAppointments = schedules.filter((appointment) => isSameDay(parseISO(`${appointment.appDate}T${appointment.appTime}`), selectedDay));

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-10 side-bar-right bg-dl-screen-lightblue">
      {/* calendar */}
      <div className="">
        <Today />
        <div className=" calender-box box-radious mt-12">
          <div className="md:grid md:grid-cols-2">
            <div className="bg-white me-5 py-7 px-7 rounded-lg ">
              <div className="flex items-center calender-top-head">
                <h2 className="flex-auto font-bold text-gray-900 capitalize calender-title mb-0">{format(firstDayCurrentMonth, 'MMMM yyyy', { locale: es })}</h2>
                <button type="button" onClick={previousMonth} className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Previous month</span>
                  <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                </button>
                <button onClick={nextMonth} type="button" className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Next month</span>
                  <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                </button>
              </div>
              <div className="grid grid-cols-7 mt-10 text-xl leading-6 text-center text-gray-500 week-name-list">
                {['D', 'L', 'M', 'M', 'J', 'V', 'S'].map((item, i) => (
                  <div key={i}>{item}</div>
                ))}
              </div>
              <div className="grid grid-cols-7 mt-2 text-xl">
                {days.map((day, dayIdx) => (
                  <div key={day.toString()} className={classNames(dayIdx === 0 && colStartClasses[getDay(day)], 'py-1.5')}>
                    <button
                      type="button"
                      // disabled={day < today}
                      onClick={() => {
                        setSelectedDay(day);
                        setShowDetail(false);
                      }}
                      className={classNames(
                        isEqual(day, selectedDay) && 'text-white',
                        !isEqual(day, selectedDay) && isToday(day) && 'text-dl-red',
                        !isEqual(day, selectedDay) && !isToday(day) && isSameMonth(day, firstDayCurrentMonth) && 'text-gray-900',
                        !isEqual(day, selectedDay) && !isToday(day) && !isSameMonth(day, firstDayCurrentMonth) && 'text-gray-400',
                        isEqual(day, selectedDay) && isToday(day) && 'bg-dl-red',
                        isEqual(day, selectedDay) && !isToday(day) && 'bg-dl-accent',
                        !isEqual(day, selectedDay) && 'hover:bg-gray-200',
                        (isEqual(day, selectedDay) || isToday(day)) && 'font-semibold',
                        // day < today && 'cursor-not-allowed text-gray-300 text-lg	',
                        'mx-auto flex h-8 w-8 items-center justify-center rounded-full active-btn-number'
                      )}
                    >
                      <time dateTime={format(day, 'yyyy-MM-dd')}>{format(day, 'd')}</time>
                    </button>

                    <div className="w-2 h-2 mx-auto mt-1">{schedules.some((appointment) => isSameDay(parseISO(appointment.appDate), day)) && <div className="w-2 h-2 rounded-full bg-sky-500"></div>}</div>
                  </div>
                ))}
              </div>
            </div>
            <section className="mt-12 md:mt-0 md:pl-14 border-l border-black ml-8">
              {/* <h2 className="agenda-text-heading">
                  <time className='agenda-time' dateTime={format(selectedDay, 'yyyy-MM-dd')}>{format(selectedDay, 'MMM dd, yyy')}</time>
                  Horarios disponibles{' '}
                </h2> */}
              {/* <ul className="time-slot-name-text">
                {selectedDayAppointments.length > 0 ? (
                  selectedDayAppointments.map((appointment) => <AppointmentItem appointment={appointment} key={appointment.apptID} setSelectedAppointment={setSelectedAppointment} onRescheduleAppointment={onRescheduleAppointment} />)
                ) : (<p className="no-time-show">Seleccione una hora</p>)}
              </ul> */}

              <ul className="time-slot-name-text">
                {selectedDayAppointments.length ? (
                  selectedDayAppointments.map((item, i) => (
                    <li key={i} onClick={() => fxItem(item)} className={checkActiveSlot(item) ? 'active' : ''}>
                      <span>{item.userFullName}</span>
                      <p>{item.appTime}</p>
                    </li>
                  ))
                ) : (
                  <p className="no-time-show">Seleccione una hora</p>
                )}
              </ul>
            </section>
          </div>
        </div>

        {showDetail ? (
          <div className="flex information-box-info common-box-white mt-10 ">
            <div className="name-docter-info-box-L flex">
              <figure>
                <span>
                  <img className="h-full w-full rounded-full " src={configurations?.defaultImage} alt="" />       
                </span>
              </figure>
              <figcaption className="information-box-info-left">
                <h4 className="title-info">{patient.userFullName}</h4>
                <span className="blue-tag">{patient.appTime}</span>
              </figcaption>
            </div>
            <hr className="border-right"></hr>
            <div className="name-docter-info-box-C">
              <h4 className="title-info">Detalles:</h4>
              <ul className="Detalles-list">
                <li className="mb-2">
                  <p>fecha de nacimiento: {patient.birthDate}</p>
                </li>
                <li>
                  <p>Motivo de la consulta: {patient.motiveDescription}</p>
                </li>
              </ul>
            </div>
            <div className="w-1/5 flex flex-col gap-2 items-center">
              <button className="btn btn-primary mb-3" onClick={() => navigate('/doctor-appointment-detail', { state: { patient } })}>
                Ver detalles
              </button>
              <button className="btn btn-primary grey-btn" onClick={() => navigate('/doctor-appointment-detail', { state: { patient, type: 'reschedule' } })}>
                Reprogramar
              </button>
            </div>
          </div>
        ) : null}
        {!isHomeRoute &&
          (userInfo?.profileType === 'withAppointment' ? (
            <div className="flex  justify-center mt-20">
              <button className="btn btn-primary btn-sm" onClick={() => navigate('/selected-appointment-motive-doctor')}>
                Agendar nueva cita
              </button>
            </div>
          ) : null)}
      </div>
      {/* details */}
    </div>
  );
};

export default AppointmentPending;
