import listicon from '../../../images/list-icon.svg';
import paymenticon from '../../../images/payment-icon.svg';
import insuranceinfo from '../../../images/insurance-information.svg';
import { CardWrap } from './DoctorResultsTabsElement';

const Tarifas = ({ doctorData }) => {
  return (
    <>
      <CardWrap>
        <div className="px-4 py-4 sm:px-6 border-b divide-slate-200 border-black flex items-center  consulta-box-head">
          <figure className="consulta-icon">
            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1150_532)">
                <path
                  d="M24.0648 1.57138H20.5199V3.76408H22.9684V8.6611C22.9684 12.3489 19.9681 15.3488 16.2807 15.3488H15.7691C12.0813 15.3488 9.08137 12.3485 9.08137 8.6611V3.76408H11.5299V1.57138H7.98502C7.37948 1.57138 6.88867 2.06219 6.88867 2.66773V8.6611C6.88867 13.5577 10.8725 17.5415 15.7691 17.5415H16.2807C21.1774 17.5415 25.1611 13.5577 25.1611 8.6611V2.66773C25.1611 2.06219 24.6703 1.57138 24.0648 1.57138Z"
                  fill="black"
                />
                <path d="M20.4108 0C19.8053 0 19.3145 0.490811 19.3145 1.09635V4.16612C19.3145 4.77166 19.8053 5.26247 20.4108 5.26247C21.0163 5.26247 21.5071 4.77166 21.5071 4.16612V1.09635C21.5071 0.490811 21.0163 0 20.4108 0Z" fill="black" />
                <path d="M11.7487 0C11.1432 0 10.6523 0.490811 10.6523 1.09635V4.16612C10.6523 4.77166 11.1432 5.26247 11.7487 5.26247C12.3542 5.26247 12.845 4.77166 12.845 4.16612V1.09635C12.845 0.490811 12.3542 0 11.7487 0Z" fill="black" />
                <path
                  d="M17.1945 22.6218V16.4092H15.0018V22.6218H11.457C8.5955 22.6218 6.26758 24.9497 6.26758 27.8108C6.26758 30.6723 8.61193 33.0002 11.4935 33.0002H12.0051C14.8666 33.0002 17.1945 30.6723 17.1945 27.8108V24.8145H20.9221V22.6218H17.1945ZM15.0018 27.8104C15.0018 29.4626 13.6573 30.8071 12.0051 30.8071H11.4935C9.79271 30.8071 8.46027 29.4911 8.46027 27.8104C8.46027 26.1582 9.80477 24.8141 11.457 24.8141H15.0018V27.8104Z"
                  fill="black"
                />
                <path
                  d="M23.2981 20.2828C21.4044 20.2828 19.8633 21.8236 19.8633 23.7181C19.8633 25.6118 21.404 27.1529 23.2981 27.1529C25.1923 27.1529 26.7333 25.6118 26.7333 23.7181C26.7333 21.8239 25.1926 20.2828 23.2981 20.2828ZM23.2981 24.9599C22.6132 24.9599 22.056 24.4026 22.056 23.7177C22.056 23.0325 22.6133 22.4752 23.2981 22.4752C23.9833 22.4752 24.5406 23.0325 24.5406 23.7177C24.5406 24.4026 23.9833 24.9599 23.2981 24.9599Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_1150_532">
                  <rect width="33" height="33" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </figure>
          <h2 id="applicant-information-title" className="text-lg leading-6 font-bold text-black sm:px-6 border-red horarios-title">
            Consulta
          </h2>
        </div>
        <dl className="content-list-box flex items-center justify-between px-6 py-4">
          <div className="inner-content px-6">
            <p>${doctorData.consultationCost}</p>
          </div>
        </dl>
      </CardWrap>
      <CardWrap>
        <div className="px-4 py-4 sm:px-6 border-b divide-slate-200 border-black flex items-center  examenes-box-head">
          <figure className="examenes-icon">
            <img className="" src={listicon} alt="" />
          </figure>
          <h2 id="applicant-information-title" className="text-lg leading-6 font-bold text-black sm:px-6 border-red horarios-title">
            Examenes
          </h2>
        </div>
        <dl className="content-list-box flex items-center justify-between px-6 py-4">
          <div className="inner-content px-6">
            <p>${doctorData.examCost}</p>
          </div>
        </dl>
      </CardWrap>
      <CardWrap>
        <div className="px-4 py-4 sm:px-6 border-b divide-slate-200 border-black flex items-center  payment-methods-head">
          <figure className="payment-methods-icon">
            <img className="" src={paymenticon} alt="" />
          </figure>
          <h2 id="applicant-information-title" className="text-lg leading-6 font-bold text-black sm:px-6 border-red horarios-title">
            Formas de pago
          </h2>
        </div>
        <dl className="content-list-box flex items-center justify-between px-6 py-4">
          <div className="inner-content px-6">
            <p>{doctorData.paymentMethods}</p>
          </div>
        </dl>
      </CardWrap>
      <CardWrap>
        <div className="px-4 py-4 sm:px-6 border-b divide-slate-200 border-black flex items-center  insurance-information-head">
          <figure className="insurance-information-icon">
            <img className="" src={insuranceinfo} alt="" />
          </figure>
          <h2 id="applicant-information-title" className="text-lg leading-6 font-bold text-black sm:px-6 border-red horarios-title">
            Información de seguro
          </h2>
        </div>
        <dl className="content-list-box flex items-center justify-between px-6 py-4">
          <div className="inner-content px-6">
            <p>{doctorData.insurance ? doctorData.insurance : 'NA'}</p>
          </div>
        </dl>
      </CardWrap>
    </>
  );
};

export default Tarifas;
