import { useEffect, useMemo, useState } from 'react';
import CustomSuccessModal from '../../../../common/components/customSuccessModal/CustomSuccessModal';
import CustomRedAlert from '../../../../common/components/customRedAlert/CustomRedAlert';
import getLanguagesInProfile from '../../../../doctorUser/api/getLanguagesInProfile';
import ControlledFormSelectDrop from '../../../../common/components/formElements/controlled/ControlledFormSelectDrop';
import useProfileLanguages from '../../../../doctorUser/components/doctorOwnProfile/languages/hooks/useProfileLanguages';
import { Language } from '../../../services/DoctorProfile';
import { CxCard, CxTabButton, CxWrap, Line } from '../../../components/Sx';
import ProfileFormHeader from '../../../../doctorUser/components/ProfileFormHeader/ProfileFormHeader';
import HalfRow from '../../../../common/components/formElements/HalfRow';
import FormButton from '../../../../common/components/formElements/FormButton';
import { useForm } from 'react-hook-form';

export default function Languages(props) {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successBody, setSuccessBody] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorBody, setErrorBody] = useState('');

  const { basicProfile } = props;
  const [languages, setLanguages] = useState([]);
  const [languagesInProfile, setLanguagesInProfile] = useState([]);
  const [allLanguages, setAllLanguages] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const [isEditing, setEditing] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { register, handleSubmit, formState, reset, setValue, getValues } = useForm();
  const { errors, isDirty } = formState;

  // API
  const queryLanguagesInProfile = async () => {
    const getLanguages = await getLanguagesInProfile();
    if (getLanguages) {
      const formattedLanguages = getLanguages.map((language) => ({ id: language.idLanguage, label: language.name, key: language.idLanguageByDoctor }));
      setLanguages(formattedLanguages);
      setLanguagesInProfile(formattedLanguages);
      setInitialData(formattedLanguages);
    }
  };

  const queryAllLanguages = async () => {
    const getAllLanguages = await Language.getAllLanguage();
    if (getAllLanguages) {
      const formattedAllLanguages = getAllLanguages.data.map((language) => ({ id: language.id, label: language.name, key: language.id }));
      setAllLanguages(formattedAllLanguages);
    }
  };

  useEffect(() => {
    queryLanguagesInProfile();
    queryAllLanguages();
  }, []);

  const formattedLanguages = useMemo(() => {
    if (!basicProfile || !languages || !languagesInProfile) return;
    // console.log({languages})
    return languages
      .filter((lang) => {
        return languagesInProfile.findIndex((inProfile) => inProfile.label === lang.name) === -1;
      })
      .map((lang) => {
        // console.log({lang})
        return { id: lang.id, label: lang.name };
      });
  }, [basicProfile, languages, languagesInProfile]);

  const _createSuccessMessage = (message) => {
    setShowSuccessModal(true);
    setSuccessBody(message);
  };

  const _createErrorMessage = (errorMessage) => {
    setShowErrorModal(true);
    setErrorBody(errorMessage);
  };

  const onCloseSuccessMessage = () => {
    setShowSuccessModal(false);
    setSuccessBody('');
  };

  const { closeErrorMessage, onDeleteLanguageInProfile } = useProfileLanguages(queryLanguagesInProfile);

  const saveLang = (values) => {
    Language.saveLanguage(values)
      .then(() => _createSuccessMessage('Idioma actualizado para el perfil.'))
      .then(() => setRefresh(!refresh))
      .catch((err) => console.log(err));
  };

  const fxUpdateLang = async (selected) => {
    let ifExist = languages.find((arr) => arr.id.toString() === selected.toString());
    // console.clear();
    if (!ifExist) {
      const obj = allLanguages.find((lang) => lang.id.toString() === selected);
      const arr = [...languages];
      arr.push(obj);
      setLanguages(arr);
      let languageIds = arr.map((item) => item.id);
      setValue('languageIds', languageIds);
    }

    // let alreadyAddedCheck = languages.find((lang)=> selected === lang.id.toString())
    // console.log(alreadyAddedCheck)
    // if(alreadyAddedCheck.length === 0){
    // console.log(selected)
    // console.log(allLanguages)
    // console.log(obj)

    // const attemptToSave = await Language.saveLanguage(selected);
    // if (attemptToSave) {
    //   _createSuccessMessage('Nueva especialidad agregada a perfil.');
    // }
    // }
  };

  const fxDeleteLang = (item) => {
    setEditing(true);
    const arr = languages.filter((lang) => lang.id !== item.id);
    setLanguages(arr);
    setValue(
      'languageIds',
      arr.map((item) => item.id)
    );
  };

  const fxReset = () => {
    setEditing(false);
    let languageIds = initialData.map((item) => item.id);
    setValue('languageIds', languageIds);
    setLanguages(initialData);
  };

  return (
    <form onSubmit={handleSubmit(saveLang)}>
      <CxWrap>
        <CxCard>
          <ProfileFormHeader title={!isEditing ? 'Idioma' : 'Actualizar Idioma'} initialText={``} />
        </CxCard>
        <Line />
        <CxCard>
          <HalfRow>
            <ControlledFormSelectDrop label={'Idioma'} identifier={'idioma'} emptyValue={'Seleccione'} options={allLanguages} value={formattedLanguages} onChange={(e) => fxUpdateLang(e.target.value)} />
          </HalfRow>
        
          <div className="flex justify-end gap-2 mt-4">
            <CxTabButton label="Cancelar" variant="outlined" onClick={fxReset} />
            <CxTabButton label={isEditing ? 'Actualizar' : 'Guardar'} />
          </div>
        </CxCard>
      </CxWrap>
      <div className=" flex  pt-10 pl-12">
            {languages.map((item) => (
              <div class="py-2 px-4 flex mr-6 justify-center gap-2 shadow-md no-underline rounded-full items-center light-bg-blue  text-white font-sans font-semibold text-sm border-blue btn-primary hover:text-white hover:bg-blue-light focus:outline-none active:shadow-none mr-2">
                {item.label}
                <span className="cursor-pointer pl-3 text-xl text-gray-500 font-bold	" onClick={() => fxDeleteLang(item)}>
                  x
                </span>
              </div>
            ))}
          </div>

      {showSuccessModal && <CustomSuccessModal title={'Acción ejecutada con éxito!'} bodyText={successBody} buttonText={'Cerrar'} buttonAction={onCloseSuccessMessage} open={showSuccessModal} onClose={onCloseSuccessMessage} />}
      {showErrorModal && <CustomRedAlert open={true} title={'Ocurrió un error.'} bodyText={errorBody} onClose={closeErrorMessage} />}

      {showSuccessModal && (
        <CustomSuccessModal
          title={isEditing ? 'Registro actualizado con éxito' : 'Nuevo registro guardado con éxito'}
          bodyText={successBody}
          buttonText={'Aceptar'}
          buttonAction={onCloseSuccessMessage}
          open={showSuccessModal}
          onClose={onCloseSuccessMessage}
        />
      )}
    </form>
  );
}
