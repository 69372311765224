import { Controller, useForm } from 'react-hook-form';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CxCard, CxTabButton, CxWrap, Line } from '../../../components/Sx';
import ProfileFormHeader from '../../../../doctorUser/components/ProfileFormHeader/ProfileFormHeader';
import { Availability, SchedulesS } from '../../../services/DoctorProfile';
import Loader from '../../../components/Loader';
import FormSelectDrop from '../../../../common/components/formElements/FormSelectDrop';
import FormDatePicker from '../../../../common/components/formElements/FormDatePicker';
import { ClockIcon } from '@heroicons/react/outline';
import { setHours, setMinutes, format } from 'date-fns';
import CustomSuccessModal from '../../../../common/components/customSuccessModal/CustomSuccessModal';
import CustomRedAlert from '../../../../common/components/customRedAlert/CustomRedAlert';
import AvailabilityList from '../../../../doctorUser/components/doctorOwnProfile/availability/components/availabilityList/AvailabilityList';

function Quotes() {
  const [isLoading, setIsLoading] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorBody, setErrorBody] = useState('');
  const [successBody, setSuccessBody] = useState('');
  const [isEditing, setEditing] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});
  const [availabilities, setAvailabilities] = useState([]);
  const [selectedAvailability, setSelectedAvailability] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const [schedules, setSchedules] = useState([]);

  const { register, handleSubmit, formState, watch, control, reset, setValue } = useForm(defaultValues);
  const { errors, isDirty } = formState;

  useEffect(() => {
    setIsLoading(true);
    Availability.getAllAvailability().then((res) => {
      setAvailabilities(res.data);
    });
    SchedulesS.getAllSchedules()
      .then((res) => {
        setSchedules(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    setIsLoading(true);

    if (selectedAvailability) {
      if (typeof selectedAvailability.appTime === 'string') {
        const now = new Date();
        now.setHours(selectedAvailability.appTime.substr(0, selectedAvailability.appTime.indexOf(':')));
        now.setMinutes(selectedAvailability.appTime.substr(selectedAvailability.appTime.indexOf(':') + 1, 2));
        now.setSeconds(0);
        setEditing(true);
        selectedAvailability.appTime = now;
      }
      setDefaultValues(selectedAvailability);
      reset(selectedAvailability);
      setValue('status', selectedAvailability.status ? 'true' : 'false', { shouldValidate: true });
      setValue('appTime', selectedAvailability.appTime, { shouldValidate: true });
      setValue('idSchedule', selectedAvailability.idSchedule.toString(), { shouldValidate: true });
    }

    setIsLoading(false);
  }, [reset, selectedAvailability, setValue]);


  const appTimeRef = useRef('');
  const scheduleRef = useRef('');

  appTimeRef.current = watch('appTime', '');
  scheduleRef.current = watch('idSchedule', '');

  const _validateAppTime = () => {
    const findSchedule = schedules.find((item) => item.id === parseInt(scheduleRef.current));

    if (!findSchedule) {
      return false;
    }

    const openTime = new Date();
    openTime.setHours(findSchedule.openHours1.substr(0, findSchedule.openHours1.indexOf(':')));
    openTime.setMinutes(findSchedule.openHours1.substr(findSchedule.openHours1.indexOf(':') + 1));
    openTime.setSeconds(0);

    const closeTime = new Date();
    closeTime.setHours(findSchedule.openHours2.substr(0, findSchedule.openHours2.indexOf(':')));
    closeTime.setMinutes(findSchedule.openHours2.substr(findSchedule.openHours2.indexOf(':') + 1));
    closeTime.setSeconds(0);

    return appTimeRef.current > openTime && appTimeRef.current < closeTime;
  };

  const _createErrorMessage = (errorMessage) => {
    setShowErrorModal(true);
    setErrorBody(errorMessage);
  };

  const closeErrorMessage = () => {
    setShowErrorModal(false);
    setErrorBody('');
  };

  const _createSuccessMessage = (errorMessage) => {
    setShowSuccessModal(true);
    setSuccessBody(errorMessage);
  };

  const schedulesAsOptions = useMemo(() => {
    return (
      schedules &&
      schedules
        .filter((item) => item.status)
        .map((item) => ({
          id: item.id,
          label: `${item.dayName}: ${item.openHours1} - ${item.openHours2}`
        }))
    );
  }, [schedules]);

  const resetState = () => {
    setShowSuccessModal(false);
    setShowErrorModal(false);
    setErrorBody('');
    setSuccessBody('');
    setDefaultValues({});
    setSelectedAvailability(null);
    reset({ appTime: '', idSchedule: '' });
    setValue('status', '', { shouldValidate: true });
    setRefresh(!refresh);
    setEditing(false);
  };

  const saveAvailability = (data) => {
    data.appTime = format(data.appTime, 'HH:mm');
    data.idSchedule = parseInt(data.idSchedule);
    if (!data) {
      _createErrorMessage('No se pudo obtener información válida para guardar. Inténtelo de nuevo.');
      return;
    }

    if (JSON.stringify(defaultValues) === '{}') {
      executeCreateAvailability(data);
    } else {
      const adjustedData = { ...data, idAvailability: data.id, status: data.status === 'true' };
      doUpdateAvailability(adjustedData);
    }
  };

  const executeCreateAvailability = async (newAvailability) => {
    setIsLoading(true);
    const createNewAvailability = await Availability.createAvailability(newAvailability);

    if (createNewAvailability) {
      _createSuccessMessage('¡Se ha guardado el registro con éxito!');
    }

    setIsLoading(false);
  };

  const doUpdateAvailability = async (updatedAvailabilityObject) => {
    setIsLoading(true);
    // _createErrorMessage
    const executeUpdatedAvailability = await Availability.updateAvailability(updatedAvailabilityObject);

    if (executeUpdatedAvailability) {
      _createSuccessMessage('¡Se ha actualizado el registro con éxito!');
    }

    setIsLoading(false);
  };

  const onCloseSuccessMessage = () => resetState();

  if (isLoading) return <Loader />;

  return (
    <div>
      {/* Add Availability Form */}
      {/* <form onSubmit={handleSubmit(saveAvailability)}>
        <CxWrap>

        <CxCard> <ProfileFormHeader
              title="Agregar horario disponible para Citas"
              secondaryText="Ingresa horarios específicos en los que atiendes pacientes. Estos son los espacios de consulta, y determinan el intervalo en el que atiendes a tus pacientes."
            />
          
            </CxCard>
            <Line />
          <CxCard>
       
            <div className="grid gap-6">
              <div class="col-start-1 col-end-12 ">
                <FormSelectDrop
                  label={'Horario'}
                  identifier={'idSchedule'}
                  options={schedulesAsOptions}
                  defaultValue={defaultValues.idSchedule}
                  helperText={'Requerido'}
                  registration={register('idSchedule', { required: true })}
                  errorMessage={errors.idSchedule ? 'Este campo es requerido' : null}
                />
              </div>
              <div class="col-start-1 col-end-12 ">
                <Controller
                  control={control}
                  name="appTime"
                  rules={{ required: true, validate: _validateAppTime }}
                  render={({ field }) => {
                    return (
                      <FormDatePicker
                        identifier="appTime"
                        label="Seleccionar hora de cita"
                        errorMessage={errors.appTime ? 'Este campo es requerido. Asegúrese que la hora este dentro del rango.' : null}
                        helperText={'Requerido. Debe estar dentro del rango del horario seleccionado.'}
                        iconComponent={<ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                        datePickerProps={{
                          onKeyDown: (e) => {
                            e.preventDefault();
                          },
                          selected: field.value,
                          dateFormat: 'HH:mm:ss',
                          onChange: (date) => field.onChange(date),
                          showTimeSelect: true,
                          showTimeSelectOnly: true,
                          timeIntervals: 15,
                          timeCaption: 'Hora',
                          dropdownMode: 'select',
                          minTime: setHours(setMinutes(new Date(), 0), 6),
                          maxTime: setHours(setMinutes(new Date(), 0), 20)
                        }}
                      />
                    );
                  }}
                />
              </div>
            </div>
           
           
          </CxCard>

          <hr class="mt-2  pt-2 "></hr>

          <div className="flex justify-end gap-2  px-10 py-4">
              <CxTabButton label="Cancelar" variant="outlined" onClick={resetState} />
              <CxTabButton label={isEditing ? 'Actualizar' : 'Guardar'} disabled={isEditing && !isDirty} />
            </div>
        </CxWrap>
        {showErrorModal && <CustomRedAlert open={true} title={'Ocurrió un error.'} bodyText={errorBody} onClose={closeErrorMessage} />}

        {showSuccessModal && (
          <CustomSuccessModal
            title={isEditing ? 'Registro actualizado con éxito' : 'Nuevo registro guardado con éxito'}
            bodyText={successBody}
            buttonText={'Aceptar'}
            buttonAction={onCloseSuccessMessage}
            open={showSuccessModal}
            onClose={onCloseSuccessMessage}
          />
        )}
      </form> */}
      {/* table */}
      <div className="ml-1">
        <div className="ml-5">
          <ProfileFormHeader title={'Disponibilidades existentes'} fontFamily="font-aristotelica"/>
        </div>
        {availabilities.length === 0 ? <p className="ml-4 mt-4">No hay disponibilidades para mostrar</p> : <AvailabilityList items={availabilities} schedules={schedules} onClick={setSelectedAvailability} />}
      </div>
    </div>
  );
}

export default Quotes;
