import ErrorBoundary from '../errorBoundaries/ErrorBoundary';
import { EyeIcon, DocumentDownloadIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { useEffect, useState } from 'react';
import AddDocDialog from './AddDocDialog';
import { Documentos } from '../../../v2/services/Documentos';
import EditDocDialog from './EditDocDialog';
import { formatDate } from '../../utils/formattedData';
import CustomSuccessModal from '../customSuccessModal/CustomSuccessModal';
import { useLocation, useParams } from 'react-router-dom';
import DeleteConfirmationModal from '../deleteConfirmationModal/DeleteConfirmationModal';
import ConfirmationModal from '../confirmationModal/ConfirmationModal';

export default function MyDocuments({ user }) {
  const [openModel, setOpenModel] = useState(false);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [categories, setCategories] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const { idPatient, patientName } = useParams();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [documentId, setDocumentId] = useState();
  const [doc, setDoc] = useState();
  const [successTitle, setSuccessTitle] = useState();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(false)
  const urlCache = new Map();
  const location = useLocation()


  const onCloseSuccessMessage = () => {
    setShowSuccessModal(false);
    setSuccessTitle('');
  };
  const handleAddDocument = () => {
    setOpenModel(true);
    setSuccessTitle('Documento cargado exitosamente');
  };
  const handleEditDocument = (document) => {
    setDoc(document);
    setOpenEditModel(true);
    setSuccessTitle('Nombre del documento actualizado exitosamente');
  };

  const handleCategoryChange = (e, document) => {
    const idCategory = e.target.value;
    setSelectedCategory(idCategory);
    setOpenConfirmModal(true);
    setDoc({ ...document, userType: user.usertype, idCategory, idPatient });
    setError('');
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchDocuments();
  }, [refresh]);

  async function fetchCategories() {
    try {
      const response = await Documentos.getCategories();
      const data = response.data;
      setCategories(data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  }

  async function fetchDocuments() {
    try {
      const response = await Documentos.list({ ...user, idPatient: idPatient ?? '' });
      const data = response.data;
      setDocuments(data);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  }

  async function updateDocCategory(body) {
    try {
      const response = await Documentos.update(body);
      if (response) {
        fetchDocuments();
        setOpenConfirmModal(false);
        setShowSuccessModal(true);
        setSuccessTitle('Categoría de documento actualizada correctamente');
      }
    } catch (error) {
      console.error('Error updating document category:', error);
      setError(error.response.data.message)
    }
  }

  async function view(Key) {
    const url = await getPresignedUrl(Key, 'view');
    window.open(url, '_blank');
  }

  async function getPresignedUrl(Key, Action) {
    const cachedUrl = urlCache.get(Key + Action);

    if (cachedUrl && cachedUrl.expiration > Date.now()) {
      return cachedUrl.url;
    }

    try {
      const { data } = await Documentos.url(Key, Action);
      const expirationTime = Date.now() + ((Action === 'view' ? 900 : 60) * 1000);
      urlCache.set(Key + Action, { url: data, expiration: expirationTime });
      return data;
    } catch (error) {
      console.error('error in getting url:', error);
      return '';
    }
  }

  async function download(Key) {
    const url = await getPresignedUrl(Key, 'download');

    const link = document.createElement('a');
    link.href = url;
    link.download = Key.split('/')[idPatient ? 3 : 2]; // or set a specific filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async function editName() {

  }

  async function deleteDocument() {
    try {
      const response = await Documentos.delete(documentId);
      fetchDocuments();
      setOpenDeleteModal(false);
      setSuccessTitle('Documento eliminado exitosamente');
      setShowSuccessModal(true);
    } catch (error) {
      console.error('Error deleting document:', error)
    }
  }

  return (
    <ErrorBoundary>
      <>
        <div className="my-document-bg">
          <div className="wrapper-box">
            <div className="my-document-head">
              <h1>{idPatient ? `${patientName}'s documentos` : 'Mis documentos'}</h1>
              <div className="documentos-add-btn">
                <a href="javascript:;" onClick={handleAddDocument}>
                  <svg color="green" height={25} width={25} style={{ marginRight: '5px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12 12.5858L16.2426 16.8284L14.8284 18.2426L13 16.415V22H11V16.413L9.17157 18.2426L7.75736 16.8284L12 12.5858ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z"></path>
                  </svg>
                  <span>Agregar un documento</span>
                </a>
              </div>
            </div>

            <div className="table-bg">
              <table className="table">
                <thead>
                  <tr>
                    <th>Nombre del documento</th>
                    <th>Fecha</th>
                    <th>Nombre del doctor</th>
                    <th>Categoría</th>
                    <th>Detalles</th>
                  </tr>
                </thead>
                <tbody>
                  {documents.map((document) => (
                    <tr key={document.id}>
                      <td>{document.name}</td>
                      <td>{formatDate(document.creation, 'YYYY-MM-DD')}</td>
                      <td>{document.doctor_name}</td>
                      <td>
                        <div className="category-dropdown">
                          <select value={document.idCategory} onChange={(e) => handleCategoryChange(e, document)} className="block w-full mt-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                            {categories.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                            ))}
                          </select>
                          {/* <button>{document.category}</button> */}
                        </div>
                      </td>
                      <td>
                        <Menu>
                          <MenuButton className="inline-flex items-center gap-2 rounded-md bg-gray-800 py-1.5 px-3 text-sm/6 font-semibold text-black bg-white shadow-inner shadow-white/10 focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white">
                            ...
                          </MenuButton>
                          <MenuItems
                            transition
                            anchor="right start"
                            className="w-52 h-30 shadow-lg bg-white origin-top-right rounded-xl border border-white/5 p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
                          >
                            <MenuItem>
                              <button onClick={() => view(document.document_link)} className="text-black bg-white group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">
                                <EyeIcon height={25} width={25} color="#00a9f6" />
                                Ver
                                <kbd className="ml-auto hidden font-sans text-xs text-white/50 group-data-[focus]:inline">⌘E</kbd>
                              </button>
                            </MenuItem>
                            <MenuItem>
                              <button onClick={() => download(document.document_link)} className="text-black bg-white group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">
                                <DocumentDownloadIcon height={25} width={25} color="#00a9f6" />
                                Descargar
                                <kbd className="ml-auto hidden font-sans text-xs text-white/50 group-data-[focus]:inline">⌘E</kbd>
                              </button>
                            </MenuItem>
                            <MenuItem>
                              <button className="text-black bg-white group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10" onClick={() => handleEditDocument(document)}>
                                <PencilIcon height={25} width={25} color="#00a9f6" />
                                Editar nombre
                                <kbd className="ml-auto hidden font-sans text-xs text-white/50 group-data-[focus]:inline">⌘E</kbd>
                              </button>
                            </MenuItem>
                            <MenuItem>
                              <button onClick={() => { setOpenDeleteModal(true); setDocumentId(document.id) }} className="text-black bg-white group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">
                                <TrashIcon height={25} width={25} color="#de4229" />
                                Borrar
                                <kbd className="ml-auto hidden font-sans text-xs text-white/50 group-data-[focus]:inline">⌘E</kbd>
                              </button>
                            </MenuItem>
                          </MenuItems>
                        </Menu>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {openModel ? (
          <AddDocDialog
            open={openModel}
            setShowSuccessModal={setShowSuccessModal}
            setClose={() => { setOpenModel(false); fetchDocuments() }}
            title="Agregar documento"
            bodyText="Todos los campos requeridos"
            actionButtonText="Subir documento"
            categories={categories}
            actionButtonFunction={() => {
              setOpenModel(false);
            }}
            userInfo={{ ...user, idPatient }}
            refresh={refresh}
            setRefresh={setRefresh}
            appointmentId={location?.state?.apptID}
          />
        ) : null}
        {openEditModel ? (
          <EditDocDialog
            open={openEditModel}
            setShowSuccessModal={setShowSuccessModal}
            setClose={() => { setOpenEditModel(false); fetchDocuments() }}
            title="Editar nombre del documento"
            bodyText="Todos los campos requeridos"
            actionButtonText="Editar"
            actionButtonFunction={() => {
              setOpenEditModel(false);
            }}
            info={{ ...user, idPatient, ...doc }}
          />
        ) : null}

        {showSuccessModal && (
          <CustomSuccessModal
            title={successTitle}
            // bodyText={'successBody'}
            buttonText={'Aceptar'}
            open={true}
            buttonAction={onCloseSuccessMessage}
          // onClose={onCloseSuccessMessage}
          />
        )}

        {openDeleteModal && (
          <DeleteConfirmationModal
            open={Boolean(openDeleteModal)}
            onClose={() => setOpenDeleteModal(false)}
            onCancel={() => setOpenDeleteModal(false)}
            onConfirm={() => deleteDocument()}
            title={'¿Quieres eliminar este documento?'}
            bodyText={
              'Esta acción es permanente. Si desea agregarlo nuevamente, deberá agregarlo usando el botón Agregar documento.'
            }
          />
        )}

        {openConfirmModal && (
          <ConfirmationModal
            open={Boolean(openConfirmModal)}
            onClose={() => setOpenConfirmModal(false)}
            onCancel={() => setOpenConfirmModal(false)}
            onConfirm={updateDocCategory}
            title={'¿Quieres actualizar la categoría del documento?'}
            info={doc}
            error={error}
          />
        )}
      </>
    </ErrorBoundary>
  );
}
