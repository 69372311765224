import { useState } from 'react';
import fetchAuthenticatedUser from '../fetchAuthenticatedUser';
import { getCookie, removeCookie, setCookie } from '../utils/cookie';
import fetchAuthenticatedDoctor from '../fetchAuthenticatedDoctor';
import axios from 'axios';
import { useInfoStore } from '../../App';

const API_SERVER = process.env.REACT_APP_API_SERVER;

export default function useAuthentication() {
  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const { userInfo, setUserInfo } = useInfoStore((state) => state);

  const checkIfLoggedIn = async () => {
    const getAuthUser = await fetchAuthenticatedUser();

    if (getAuthUser && getAuthUser.id && getAuthUser.usertype === 1) {
      // setUserInfo(getAuthUser);
      return getAuthUser;
    } else {
      purge();
      setUserInfo(null);
    }

    return false;
  };

  const checkIfDoctorLoggedIn = async () => {
    const getAuthUser = await fetchAuthenticatedDoctor();
    if (getAuthUser && getAuthUser.id && getAuthUser.usertype === 2) {
      // setUserInfo(getAuthUser);
      return getAuthUser;
    } else {
      purge();
      setUserInfo(null);
    }

    return false;
  };

  const purge = () => {
    localStorage.removeItem('BearerToken');
    localStorage.removeItem('userType');
    // removeCookie(process.env.REACT_APP_SESSION_NAME);
    // removeCookie('usertype');
  };

  const getUserType = () => {
    return localStorage.getItem('userType');
  };
  // return getCookie('usertype');

  const logoutUser = () => {
    purge();
  };
  // window.location.replace(LOGIN);

  const performUserLogin = async (data) => {
    const { email, firstName, lastName, password, apiUrl, token } = data;
    const reqObj = apiUrl === 'signin' ? { email, password } : { firstName, lastName, email, token };
    const attemptToLogin = await axios.post(`${API_SERVER}/auth/${apiUrl}`, reqObj);

    if (attemptToLogin && attemptToLogin.status < 400) {
      const loginResult = attemptToLogin.data;
      localStorage.setItem('BearerToken', loginResult.access_token);
      localStorage.setItem('userType', loginResult.usertype);
      // setCookie(process.env.REACT_APP_SESSION_NAME, loginResult.access_token);
      // setCookie('usertype', loginResult.usertype);
      setAuthenticatedUser(loginResult);
      setUserInfo(loginResult);
    }

    return attemptToLogin;
  };

  const performDoctorUserLogin = async (data) => {
    const { email, firstName, lastName, password, apiUrl, token } = data;
    const reqObj = apiUrl === 'signin' ? { email, password } : { firstName, lastName, email, token };
    const attemptToLogin = await axios.post(`${API_SERVER}/doctor-data/${apiUrl}`, reqObj);

    if (attemptToLogin && attemptToLogin.status < 400) {
        const loginResult = attemptToLogin.data;
        localStorage.setItem('BearerToken', loginResult.access_token);
        localStorage.setItem('userType', loginResult.usertype);
        // setCookie(process.env.REACT_APP_SESSION_NAME, loginResult.access_token);
        // setCookie('usertype', loginResult.usertype);
      setAuthenticatedUser(loginResult);
      setUserInfo(loginResult);
    }
    return attemptToLogin;
  };

  const performUserLogout = async () => {
    logoutUser();
  };

  const requestUserForgotPasswordRequest = async (userName) => {
    const attemptToRequestReset = await fetch(`${API_SERVER}/auth/request-password-reset?email=${userName}`, { method: 'GET', headers: { 'Content-Type': 'application/json' } });

    if (attemptToRequestReset && attemptToRequestReset.status < 400) {
      return true;
    }

    return attemptToRequestReset;
  };

  const requestDoctorForgotPasswordRequest = async (userName) => {
    const attemptToRequestReset = await fetch(`${API_SERVER}/doctor-data/request-password-reset?email=${userName}`, { method: 'GET', headers: { 'Content-Type': 'application/json' } });

    if (attemptToRequestReset && attemptToRequestReset.status < 400) {
      return true;
    }

    return attemptToRequestReset;
  };

  const submitContactUs = async (data) => {
    let attemptToSumbit = await axios.post(`${API_SERVER}/contact-us`, { ...data });
    if (attemptToSumbit && attemptToSumbit.status == 201) {
       attemptToSumbit = attemptToSumbit.data;
    }

    return attemptToSumbit;
  };


  return { authenticatedUser, userInfo, checkIfLoggedIn, logoutUser, performUserLogin, performUserLogout, performDoctorUserLogin, checkIfDoctorLoggedIn, getUserType, requestUserForgotPasswordRequest, requestDoctorForgotPasswordRequest ,submitContactUs};


}
