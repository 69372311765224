import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { useState } from 'react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const FormComboBox = ({ label, data, value, onChange, className, multiple, disabled }) => {
    const [query, setQuery] = useState('');

    const filteredData =
        query === ''
            ? data
            : data.filter((element) => {
                  return element.label.toLowerCase().includes(query.toLowerCase());
              });

    return (
        <Combobox as="div" value={value} onChange={onChange} className={className} multiple={multiple} disabled={disabled}>
            <Combobox.Label className="block text-sm font-medium text-gray-700">{label}</Combobox.Label>
            <div className="">
                <Combobox.Input
                    className="w-full rounded-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-dl-primary-500 focus:outline-none focus:ring-1 focus:ring-dl-primary-500 sm:text-sm"
                    onChange={(event) => setQuery(event.target.value)}
                    displayValue={(element) => element?.value}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>

                {filteredData.length > 0 && (
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredData.map((element) => (
                            <Combobox.Option
                                disabled={element.disabled}
                                key={element.id}
                                value={element}
                                className={({ active }) =>
                                    classNames(
                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                        active ? 'bg-dl-secondary text-white' : 'text-gray-900'
                                    )
                                }
                            >
                                {({ active, selected }) => (
                                    <>
                                        <span className={classNames('block truncate', selected && 'font-semibold')}>
                                            {element.label}
                                        </span>

                                        {selected && (
                                            <span
                                                className={classNames(
                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                    active ? 'text-white' : 'text-dl-accent'
                                                )}
                                            >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        )}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    );
};

export default FormComboBox;
