import React, { useEffect, useState } from 'react';
import Loader from '../../../components/Loader';
import { DoctorProfile } from '../../../services/DoctorProfile';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { useInfoStore } from '../../../../App';
import { useNavigate } from 'react-router-dom';
import create from 'zustand';
import configurations from '../../../../common/utils/configurations';

const APP_SERVER = process.env.REACT_APP_API_SERVER;
export const docStore = create((set) => ({
  docData: null,
  setDocData: (data) => set({ docData: data })
}));

const SelectAppointmentMotiveDoctor = () => {
  const { userInfo } = useInfoStore((state) => state);
  const { docData, setDocData } = docStore((state) => state);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    DoctorProfile.getOwnProfile(userInfo.id)
      .then((res) => setDocData(res.data))
      .then(() => setLoading(false));
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div className="min-h-[90vh]  py-10 px-8  max-w-7xl mx-auto">
      {/* Page heading */}
      <header className="bg-dl-primary-900/[.1]   docter-profile  rounded-[24px]  pt-40">
        <div className="  xl:flex xl:items-center xl:justify-between docter-profile-inner bg-white  px-12">
          <div className="flex-1 min-w-0">
            {/* Profile */}
            <div role="list" className="mt-5 sm:mt-0 sm:border-t-0">
              <div>
                <div className="flex items-center  sm:px-0">
                  <div className="min-w-0 flex-1 flex ">
                    <div className=" docter-profile-L ">
                      <img className="h-40 w-40 rounded-full group-hover:opacity-75 border-4 border-white	" src={docData?.personalImage ? `${APP_SERVER}/${docData?.personalImage}` : configurations?.defaultImage} alt="" />
                    </div>
                    <div className="min-w-0 flex-1 px-10 py-8 md:grid md:grid-cols-2 md:gap-4 docter-profile-R">
                      <div>
                        <p className=" font-medium  truncate text-2xl text-black  mb-3">{docData.fullName}</p>
                        <div className="flex ">
                          <p className="mt-2 flex items-center text-sm mr-5 py-3 px-8 text-black docter-tag bg-cyan-50	rounded-full">
                            <span className="truncate">{docData.speciality}</span>
                          </p>
                          <p className="mt-2 flex items-center text-sm mr-5 py-3 px-8 text-black docter-tag bg-cyan-50	rounded-full">
                            <span className="">{docData.address}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main className="pt-8 pb-16">
        <div className="">
          {/* Body tabs */}

          <div className="pt-8 px-4 sm:px-0">
            {/* Stacked list */}

            {/* Description list*/}
            <section aria-labelledby="applicant-information-title dd" className="border-radius-24">
              <div className="bg-white shadow sm:rounded-xl">
                <div className="px-4 py-6 sm:px-6 border-b divide-slate-200 border-black">
                  <h2 id="applicant-information-title" className="text-lg leading-6 font-bold	 text-black sm:px-6 border-red ">
                    Elige motivo de consulta
                  </h2>
                </div>
                <div className="border-black px-4 py-5 sm:px-6 pt-0">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 mt-5">
                    <div className="sm:col-span-2">
                      <ul role="list" className="mt-5 sm:mt-0 ">
                        {docData.motives?.map((item) => (
                          <li key={item.id}>
                            <div role="button" className="group block" onClick={() => navigate('/create-appointment-by-doctor', { state: { motive: item } })}>
                              <div className="flex items-center py-5 px-10  bg-stone-50 mb-4">
                                <div className="min-w-0 flex-1 flex items-center">
                                  <div className="min-w-0 flex-1 md:grid md:grid-cols-2 md:gap-4">
                                    <div>
                                      <p className="font-medium  truncate text-black text-lg	">{item.motiveName}</p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <ChevronRightIcon className="h-6 w-6  group-hover:text-gray-700 text-black " aria-hidden="true" />
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </dl>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SelectAppointmentMotiveDoctor;
