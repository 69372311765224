import { z } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { CxCard, CxTabButton, CxWrap, Line } from '../../components/Sx';
import FormInputField from '../../../common/components/formElements/FormInputField';
import { KeyIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { PublicProfileS } from '../../services/ProfilePublic';
import DatePicker from 'react-datepicker';
import { parseISO } from 'date-fns';
import Loader from '../../components/Loader';
import InputMask from 'comigo-tech-react-input-mask/lib/react-input-mask.development';
import CustomSuccessModal from '../../../common/components/customSuccessModal/CustomSuccessModal';
import defaultImage from '../../../images/profile.png';
import { useInfoStore } from '../../../App';

const date = new Date();
const MIN_AGE = 18;
const MAX_DATE = new Date(date.setFullYear(date.getFullYear() - MIN_AGE)).toJSON().slice(0, 10);

const Password = {
  REGEX: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
  MESSAGE: 'Password must contain at least one uppercase letter, one number, and one symbol',
  MIN: 'Password must be at least 8 characters long'
};

const Document = {
  REGEX: /^\d{8}-\d{1}$/,
  MESSAGE: 'Incorrect format'
};
const MAX_FILE_SIZE = 5000000;
const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];

const formSchema = z
  .object({
    personalImage: z
      .any()
      .refine((file) => file?.size <= MAX_FILE_SIZE, `Max image size is 5MB.`)
      .refine((file) => ACCEPTED_IMAGE_TYPES.includes(file?.type), 'Only .jpg, .jpeg, .png and .webp formats are supported.'),
    firstName: z.string(),
    lastName: z.string(),
    phone: z.string(),
    address: z.string(),
    documentType: z.string(),
    personalDocument: z.string(),
    birthDate: z.date(),
    email: z.string(),
    oldPassword: z.string().min(8, { message: Password.MIN }).regex(Password.REGEX, { message: Password.MESSAGE }).optional().or(z.literal('')),
    newPassword: z.string().min(8, { message: Password.MIN }).regex(Password.REGEX, { message: Password.MESSAGE }).optional().or(z.literal('')),
    confPassword: z.string().min(8, { message: Password.MIN }).regex(Password.REGEX, { message: Password.MESSAGE }).optional().or(z.literal(''))
  })
  .refine((data) => data.newPassword === data.confPassword, { path: ['confPassword'], message: 'Passwords do not match' });
// .refine((data) => data.documentType === 'dui' ? Document.REGEX.test(data.personalDocument) : true, { path: ['personalDocument'], message: Document.MESSAGE });

const APP_SERVER = process.env.REACT_APP_API_SERVER;
const ErrMsg = ({ message }) => (message ? <p className="mt-2 text-sm text-red-700">{message}</p> : null);

function ProfilePublic() {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successBody, setSuccessBody] = useState('');

  const [previewImage, setPreviewImage] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const { register, handleSubmit, formState, getValues, reset, control, setValue, trigger } = useForm({ resolver: zodResolver(formSchema) });
  const { errors } = formState;

  const [docTypes, setDocTypes] = useState([]);
  const [documentType, setDocumentType] = useState('');

  const { userInfo, setUserInfo } = useInfoStore((state) => state);

  const _createSuccessMessage = (message) => {
    setShowSuccessModal(true);
    setSuccessBody(message);
  };

  const onCloseSuccessMessage = () => {
    setShowSuccessModal(false);
    setSuccessBody('');
  };

  const saveForm = (values) => {
    const { oldPassword, newPassword } = values;
    let updatePassword = false;
    if (oldPassword) {
      if (newPassword) {
        updatePassword = true;
      }
    }
    let formData = new FormData();
    Object.keys(values).map((item) => {
      formData.append(item, values[item]);
    });
    formData.append('updatePassword', JSON.stringify(updatePassword));

    PublicProfileS.updateProfile(formData)
      .then(() => _createSuccessMessage('Horario actualizado para el perfil.'))
      .then(() => setRefresh(!refresh))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    PublicProfileS.documentTypes().then((res) => {
      let arr = res.data.map((item) => ({ id: item.id, label: item.documentName }));
      setDocTypes(arr);
    });
    setIsLoading(true);
    PublicProfileS.getProfile(userInfo.id)
      .then((res) => {
        console.clear();

        reset({
          firstName: res.data.firstName ?? '',
          lastName: res.data.lastName ?? '',
          phone: res.data.phone ?? '',
          address: res.data.address ?? '',
          documentType: res.data.documentType ?? '',
          personalDocument: res.data.personalDocument ?? '',
          birthDate: parseISO(res.data.birthDate) ?? '',
          email: res.data.email ?? '',
          personalImage: res.data.personalImage ?? ''
        });
        // setPreviewImage(process.env.REACT_APP_MEDIA_SERVER + '/' + res.data.personalImage);
        const img = res.data.personalImage ? `${APP_SERVER}/${res.data.personalImage}` : defaultImage;
        setPreviewImage(img);
        setDocumentType(res.data.documentType);
      })
      .then(() => setIsLoading(false));
    console.clear();
  }, [refresh]);


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setValue('personalImage', file);
    trigger('personalImage', { shouldValidate: true });
    setPreviewImage(URL.createObjectURL(file));
  };

  if (isLoading) return <Loader />;

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-10 side-bar-right bg-dl-screen-lightblue ">
      <div className="">
        <div className="profile-top-content">
          <h4>Bienvenido Doctor</h4>
          <p>Ingresa la siguiente información para con tu perfil personal, tus pacientes NO podrán ver esta información.</p>
        </div>
      </div>
      <form onSubmit={handleSubmit(saveForm)}>
        <CxWrap>
          <CxCard>Modificar la foto de perfil</CxCard>
          <Line />
          <CxCard>
            <div className="flex flex-col items-center">
              <div className="mb-4">
                <img src={previewImage} alt="Preview" className="mt-4 max-w-full h-32 w-32 rounded-full" />
              </div>
              <div className="rounded-full border border-gray-100 bg-white p-3 shadow-md w-1/3">
                <label htmlFor="upload" className="flex flex-col items-center gap-2 cursor-pointer">
                  <span className="text-gray-600 font-medium">Importar foto de perfil</span>
                </label>
                <input id="upload" type="file" accept="image/*" onChange={handleImageChange} className="hidden" />
              </div>
              <ErrMsg message={errors.personalImage?.message} />
            </div>
          </CxCard>
        </CxWrap>

        <CxWrap>
          <CxCard>Datos generales</CxCard>
          <Line />
          <CxCard>
            <div className="grid grid-cols-2 gap-4 ">
              <div className='mb-3'>
                <FormInputField identifier="firstName" label="Nombres" type="string" registration={register('firstName')} errorMessage={errors.firstName?.message} />
              </div>
              <div>
                <FormInputField identifier="lastName" label="Apellidos" type="string" registration={register('lastName')} errorMessage={errors.lastName?.message} />
              </div>
            </div>
            <div className="grid grid-cols-6 gap-4">
              <div className="col-start-1 col-end-7">
                <FormInputField identifier="phone" label="Télefono" type="string" registration={register('phone')} errorMessage={errors.phone?.message} />
              </div>
              <div className="col-start-1 col-end-7">
                <FormInputField identifier="address" label="Dirección" type="string" registration={register('address')} errorMessage={errors.address?.message} />
              </div>
            </div>
          </CxCard>
        </CxWrap>
        <CxWrap>
          <CxCard>Documentación</CxCard>
          <Line />
          <CxCard>
            <div className="grid grid-cols-2 gap-4 ">
            <div className='mb-4'>
                <label htmlFor={'documentType'} className="block font-medium mb-2 text-sm text-black">
                  Tipo de documento de identidad
                </label>
                <select
                  {...register('documentType')}
                  onChange={(e) => {
                    setDocumentType(e.target.value);
                    reset({ personalDocument: '' });
                  }}
                  className="mt-1 block w-full bg-white border border-gray-300 rounded-full shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-500 focus:border-dl-primary-500 sm:text-sm font-normal	"
                >
                  {docTypes.map((item) => (
                    <option key={item.id} value={item.label}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                {/* <FormInputField identifier="personalDocument" label="Número de documento" type="string" registration={register('personalDocument')} errorMessage={errors.personalDocument?.message} /> */}
                <label className="block text-sm font-medium text-gray-700 mb-2">Numero de documento</label>
                <InputMask
                  identifier="personalDocument"
                  type="text"
                  name="personalDocument"
                  id="personalDocument"
                  autoComplete="family-name"
                  label="Numero de documento"
                  className="mt-1 block w-full border border-gray-300 rounded-full shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-700 focus:border-dl-primary-700 sm:text-sm"
                  mask={documentType === 'DUI' ? '9999999-9' : ''}
                  {...register('personalDocument')}
                />
                {errors.personalDocument?.message ? <p className="mt-2 text-sm text-red-700">{errors.personalDocument?.message}</p> : null}
              </div>
            </div>
            <div className="grid grid-cols-6 gap-4">
              <div className="col-start-1 col-end-7 ">
                <Controller
                  control={control}
                  name="birthDate"
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value}
                      value={field.value}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => field.onChange(date)}
                      maxDate={new Date(MAX_DATE)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="DD/MM/AAAA"
                      className="mt-1 block w-full border border-gray-300 rounded-full shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-700 focus:border-dl-primary-700 sm:text-sm"
                    />
                  )}
                />
              </div>
            </div>
          </CxCard>
        </CxWrap>
        <CxWrap>
          <CxCard>Cuenta</CxCard>
          <Line />
          <CxCard>
            <div className="grid grid-cols-6 gap-4">
              <div className="col-start-1 col-end-7 ">
                <FormInputField identifier="email" label="Correo" type="string" registration={register('email')} errorMessage={errors.email?.message} disabled={true} />
              </div>
              <div className="col-start-1 col-end-4 ">
                <FormInputField type="password" iconComponent={<KeyIcon className="h-5 w-5 text-gray-400" />} identifier="oldPassword" label="Contraseña" registration={register('oldPassword')} errorMessage={errors.oldPassword?.message} />
              </div>
              <div className="col-start-1 col-end-4 ">
                <FormInputField iconComponent={<KeyIcon className="h-5 w-5 text-gray-400" />} identifier="newPassword" label="Nueva contraseña" type="password" registration={register('newPassword')} errorMessage={errors.newPassword?.message} />
              </div>
              <div className="col-start-1 col-end-4 ">
                <FormInputField
                  iconComponent={<KeyIcon className="h-5 w-5 text-gray-400" />}
                  identifier="confPassword"
                  label="Confirmar contraseña"
                  type="password"
                  registration={register('confPassword')}
                  errorMessage={errors.confPassword?.message}
                />
              </div>
            </div>
          </CxCard>
        </CxWrap>
        <div className="px-4 py-3  text-right sm:px-6">
          <CxTabButton label="Actualizar" />
        </div>
      </form>
      {showSuccessModal && <CustomSuccessModal title={'Acción ejecutada con éxito!'} bodyText={successBody} buttonText={'Cerrar'} buttonAction={onCloseSuccessMessage} open={showSuccessModal} onClose={onCloseSuccessMessage} />}
    </div>
  );
}

export default ProfilePublic;
