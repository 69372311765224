import { CalendarIcon, LocationMarkerIcon } from '@heroicons/react/outline';
import AppointmentCalendar from '../appointmentCalendar/AppointmentCalendar';
import FormTextArea from '../../../common/components/formElements/FormTextArea';
import useSelectAppointmentDate from '../../hooks/useSelectAppointmentDate';
import ErrorModal from '../../../common/components/customRedAlert/CustomRedAlert';
import { format, parseISO } from 'date-fns';
import Today from '../../../v2/components/Today';
import { formatDate } from '../../../common/utils/formattedData';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
export default function CalendarAppointmentDateSelectionElement() {
  const { docData, availableAppointments, setSelectedAppointment, selectedAppointment, queryAppointmentHistory, additionalInfo, onSetAdditionalInfo, onCreateDoctorAppointment, onCloseErrorModal, error } = useSelectAppointmentDate();
  const parsedAppointment = selectedAppointment !== null ? parseISO(`${selectedAppointment.date}T${selectedAppointment.appTime}`) : null;

  const navigate = useNavigate()
  const { doctorId, doctorName } = useParams()


  useEffect(() => {
    if (!docData.id && doctorId && doctorName) {
      navigate(`/doctor-results/${doctorId}/${doctorName}`)
    }
  }, [docData, doctorId, doctorName])

  return (
    <div className={' max-w-7xl mx-auto'}>
      <div className="py-8 px-8">
        {/* <div className="mb-4">
          <div>
            <div className="relative flex space-x-6 py-6 xl:static">
              <img src={docData.personalImage} alt="doctor-image" className="h-14 w-14 flex-none rounded-full" />
              <div className="flex-auto">
                <h3 className="pr-10 font-semibold text-gray-900 xl:pr-0">{docData.fullName}</h3>
                <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                  <div className="flex items-start space-x-3">
                    <dt className="mt-0.5">
                      <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd>{docData.speciality}</dd>
                  </div>
                  <div className="mt-2 flex items-start space-x-3 xl:mt-0 xl:ml-3.5 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                    <dt className="mt-0.5">
                      <LocationMarkerIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd>{docData.address}</dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div> */}

        <Today />
        {/* 
       <h2 className="text-lg font-semibold text-gray-900">Seleccione una fecha disponible para realizar la cita</h2>
       */}
        <div className="">
          <AppointmentCalendar selectedAppointment={selectedAppointment} availableAppointments={availableAppointments} setSelectedAppointment={setSelectedAppointment} queryAppointmentHistory={queryAppointmentHistory} />
        </div>
        <div className='flex items-center justify-center'>
        {parsedAppointment !== null ? (
          <div className="text-center w-2/3  bg-green-100 border-green-500 border-l-8 py-5 rounded-lg mb-10">
            <small className="text-green-600 text-base">Fecha seleccionada:</small>
            <p className="text-green-600 font-bold text-lg">
              { formatDate(selectedAppointment.date) }
              {','}
              <time className="font-bold" dateTime={parsedAppointment}>
                {format(parsedAppointment, 'h:mm a')}
              </time>
            </p>
          </div>
        ) : null}
        </div>
        
        <div className="flex items-center  max-w-full bg-white border-l-8 border-sky-500  py-8 px-8  rounded-l-md rounded-r-full mb-20">
          <div className="flex-1 pr-10 mr-10 border-r border-black">
            <FormTextArea
              label={'Información adicional'}
              identifier={'educationDetail'}
              rows={3}
              value={additionalInfo}
              onChange={onSetAdditionalInfo}
              helperText="Requerido"
              placeholder={'Agregue información adicional que considere importante para la cita'}
            />
          </div>
          <div>
            <button
              disabled={parsedAppointment === null || additionalInfo === ''}
              onClick={onCreateDoctorAppointment}
              type="button"
              className="rounded-full disabled:opacity-75 focus:outline-none  w-full  border border-transparent py-3 px-8 text-sm font-medium text-white shadow hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 bg-stone-500	 "
            >
             Confirmar
            </button>
          </div>
        </div>
      </div>
      <ErrorModal title="Ha ocurrido un error" bodyText={error} mainActionText="Cerrar" onMainAction={onCloseErrorModal} open={error !== ''} onClose={onCloseErrorModal} />
    </div>
  );
}
