import moment from "moment";
import configurations from "../utils/configurations";
import { format, parseISO } from 'date-fns';

export const handleImageError = (event, img) => {
    event.target.src = img ? img : configurations?.defaultImage;
};

export const mergeDateTime = (date, time) => {
    const dateTimeString = `${date}T${time}`;

    return new Date(dateTimeString);
};

export const addMinutes = (dateTime, minutes) => {
    const result = new Date(dateTime);
    result.setMinutes(result.getMinutes() + minutes);
    return result;
};


export const formatDateToTimeString = (date) => {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
        throw new Error("Invalid Date object");
    }

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
}

export const formatDate = (date, dateFormat = 'yyyy-MM-dd') => {

    return format(date, dateFormat)
}

export const mergeDateAndformatTime = (date, time, format) => {
    const mergedDate = mergeDateTime(date, time)
    const formattedTime = moment(mergedDate).format(format)
    console.log(formattedTime)
    return formattedTime
}