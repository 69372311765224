import React, { useEffect, useState } from 'react';
import ProfileFormHeader from '../../../ProfileFormHeader/ProfileFormHeader';
import HalfRow from '../../../../../common/components/formElements/HalfRow';
import FormInputField from '../../../../../common/components/formElements/FormInputField';
import { CalendarIcon, MailIcon, PhoneIcon, UsersIcon, ClockIcon } from '@heroicons/react/outline';
import FullRow from '../../../../../common/components/formElements/FullRow';
import FormTextArea from '../../../../../common/components/formElements/FormTextArea';
import useAppointmentDetails from './hooks/useAppointmentDetails';
import CustomRedAlert from '../../../../../common/components/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../../../common/components/customSuccessModal/CustomSuccessModal';
import { LocationMarkerIcon } from '@heroicons/react/solid';
import { CxWrap } from '../../../../../v2/components/Sx';
import { CxCard } from '../../../../../v2/components/Sx';
import { Line } from '../../../../../v2/components/Sx';
import { DoctorIcon } from '../../../../../v2/components/Icons';
import ReactDatePicker from 'react-datepicker';
import { formatDate, formatDateToTimeString, mergeDateTime } from '../../../../../common/helpers/helper';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import getDoctorAvailableDate from '../../../../../selectAppointmentDate/api/getDoctorAvailableDate';
import SuccessConfirmation from '../../../../../common/components/successConfirmation/SuccessConfirmation';
import { useNavigate } from 'react-router-dom';
import AddEventToCalendar from '../../../../../selectAppointmentDate/components/selectAppointmentDate/AddEventToCalendar';
import { GoogleOAuthProvider } from '@react-oauth/google';


export default function HistoryAppointmentDetails(props) {
  const { appointment, onCancel, selectionMode, usertype, onSuccessfulSave } = props;
  console.log(usertype, selectionMode, 'usertype');

  const navigate = useNavigate()
  const [openReturnAlert, setOpenReturnAlert] = useState(false);
  const [rating, setRating] = useState('');

  const { handleSubmit, register, errors, isDirty, getValues, setValue, closeErrorMessage, onCloseSuccessMessage, showSuccessModal, showErrorModal, errorBody, successBody, onSaveSubmit, appointmentData, monthData, availableAppointmentsDate, showUpdateCalendarBtn, allAvailableAppointments, handleMonthChange, handleDateChange, availableTime, handleReschedule, successModalOpen, loading } = useAppointmentDetails(appointment, onSuccessfulSave, selectionMode);

  if (!selectionMode) {
    return null;
  }


  const _onReturnToAppointments = () => {
    if (isDirty) {
      setOpenReturnAlert(true);
    } else {
      onCancel();
    }
  };

  const _onConfirmCancelAppointment = () => {
    setOpenReturnAlert(false);
    onCancel();
  };

  const _onCancelConfirmationForClosing = () => {
    setOpenReturnAlert(false);
    onCloseSuccessMessage()
  };
  const _onsaveSuccesfullConfirmationClick = () => {
    onCloseSuccessMessage()
    onCancel();
  }










  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button
      className="w-full text-left border border-gray-300 px-3 py-2 min-h-[38px]   text-sm font-normal rounded-full"
      onClick={onClick}
      ref={ref}
      disabled={selectionMode !== 'reschedule'}


    >
      {value || ""}
    </button>
  ));




  return (
    <form onSubmit={handleSubmit(onSaveSubmit)}>
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        {selectionMode === 'viewOnly' && <ProfileFormHeader title={'Detalles de la cita'} textColor={'text-blue-800'} />}
        {selectionMode === 'complete' && <ProfileFormHeader title={'Completar Cita'} textColor={'text-blue-800'} />}
        {selectionMode === 'reschedule' && <ProfileFormHeader title={'Solicitar reprogramación de cita'} textColor={'text-dl-red'} />}

        <CxWrap>
          <CxCard>
            <div className="flex items-center justify-start gap-2">
              <DoctorIcon /> Detalles de la cita
            </div>
          </CxCard>
          <Line />
          <CxCard>
            <div className="grid grid-cols-6 gap-6">
              <HalfRow>
                {/* iconComponent={<UsersIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />} */}
                <FormInputField identifier="doctorFullName" label="Nombre del Doctor" type="string" registration={register('doctorFullName', { required: true })} disabled />
              </HalfRow>
              <HalfRow>
                {/* iconComponent={<CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />} */}
                <FormInputField identifier="Fecha de nacimiento" label="Fecha de nacimiento" type="string" registration={register('birthDate', { required: true })} disabled />
              </HalfRow>
              <HalfRow>
                {/* iconComponent={<CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />} */}
                {/* <FormInputField identifier="Fecha de cita" label="Fecha de cita" type="string" registration={register('appDate', { required: true })} disabled /> */}
                <label htmlFor={"Fecha de cita"} className="block text-sm font-medium text-dl-accent mb-2 text-sm text-black">
                  Fecha de cita
                </label>

                <ReactDatePicker
                  selected={getValues()?.appDate ? new Date(getValues()?.appDate) : new Date()}
                  onChange={date => {
                    handleDateChange(date)

                  }}
                  disabled={selectionMode !== 'reschedule'}
                  includeDates={availableAppointmentsDate}
                  minDate={new Date()}
                  onMonthChange={handleMonthChange}
                  className={` block w-full border border-gray-300 rounded-full  py-2 px-3 focus:outline-none focus:ring-dl-primary-400 focus:border-dl-primary-400 sm:text-sm font-normal h-10	`}
                />
                {errors?.appDate ? <p className="mt-2 text-sm text-red-700">{errors?.appDate?.message}</p> : null}
              </HalfRow>
              <HalfRow>
                {/* iconComponent={<ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />} */}
                {/* <FormInputField identifier="Hora de cita" label="Hora de cita" type="string" registration={register('appTime', { required: true })} disabled /> */}
                <label htmlFor={"Fecha de cita"} className="block text-sm font-medium text-dl-accent mb-2 text-sm text-black">
                  Hora de cita
                </label>
                <ReactDatePicker
                  selected={(getValues()?.appDate && getValues()?.appTime) ? mergeDateTime(getValues()?.appDate, getValues()?.appTime) : null}
                  onChange={(date) => {
                    setValue('appTime', formatDateToTimeString(date), { shouldValidate: true })
                  }}
                  disabled={selectionMode !== 'reschedule'}
                  includeTimes={availableTime}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"

                  dateFormat="h:mm aa"
                  className={` block w-full border border-gray-300 rounded-full  py-2 px-3 focus:outline-none focus:ring-dl-primary-400 focus:border-dl-primary-400 sm:text-sm font-normal h-10	`}
                  onKeyDown={(e) => e.preventDefault()} // Prevent typing

                // customInput={<CustomInput />}

                />
                {errors?.appTime ? <p className="mt-2 text-sm text-red-700">{errors?.appTime?.message}</p> : null}
              </HalfRow>
              <HalfRow>
                <FormInputField identifier="Télefono" label="Télefono" type="string" registration={register('userPhone', { required: true })} disabled />
              </HalfRow>
              <HalfRow>
                <FormInputField identifier="Correo electrónico" label="Correo electrónico" type="string" registration={register('userEmail', { required: true })} disabled />
              </HalfRow>
            </div>
          </CxCard>
        </CxWrap>

        <CxWrap>
          <CxCard>
            <div className="flex items-center justify-start gap-2">
              <DoctorIcon /> Consulta
            </div>
          </CxCard>
          <Line />
          <CxCard>
            <div className="flex flex-col gap-2">
              <FullRow>
                <FormInputField identifier="motiveName" label="Motivo de consulta" type="string" registration={register('motiveName', { required: true })} disabled />
                {errors.phone ? <p className="text-red-700 font-light">Este campo no puede ser vacío</p> : null}
              </FullRow>
              <FullRow>
                <FormTextArea label={'Notas del paciente'} identifier={'userNotes'} rows={3} registration={register('userNotes')} disabled />
              </FullRow>
            </div>
          </CxCard>
        </CxWrap>

        {selectionMode === 'viewOnly' ? (
          <CxWrap>
            <CxCard>
              <div className="flex items-center justify-start gap-2">
                <DoctorIcon /> Notas del doctor
              </div>
            </CxCard>
            <Line />
            <CxCard>
              <div>
                <FullRow>
                  <FormTextArea label={'Notas de la cita'} identifier={'doctorNotes'} rows={3} registration={register('doctorNotes', { required: true })} disabled />
                  {errors.doctorNotes ? <p className="text-red-700 font-light text-sm">Este campo no puede ser vacío</p> : null}
                </FullRow>
              </div>
            </CxCard>
          </CxWrap>
        ) : null}

        {selectionMode === 'reschedule' ? (
          <CxWrap>
            <CxCard>
              <div className="flex items-center justify-start gap-2">
                <DoctorIcon /> Mensaje para el doctor
              </div>
            </CxCard>
            <Line />
            <CxCard>
              <div>
                <FullRow>
                  <FormTextArea label={'Notas de la cita'} identifier={'doctorNotes'} rows={3} registration={register('doctorNotes', { required: true })} />
                  {errors.doctorNotes ? <p className="text-red-700 font-light text-sm">Este campo no puede ser vacío</p> : null}
                </FullRow>
              </div>
            </CxCard>
          </CxWrap>
        ) : null}

        <CxWrap>
          <CxCard>
            <div className="flex items-center justify-start gap-2">
              <DoctorIcon /> Estado actual de la cita
            </div>
          </CxCard>
          <Line />
          <CxCard>
            <FullRow>
              <FormInputField identifier="statusName" label="Estado actual de cita" type="string" registration={register('statusName', { required: true })} disabled />
            </FullRow>
          </CxCard>
        </CxWrap>

        {/* { selectionMode === 'edit'
          ? <CxWrap>
            <CxCard>
              <div className="flex items-center justify-start gap-2">
                <DoctorIcon /> califica tu experiencia
              </div>
            </CxCard>
            <Line />
            <CxCard>
              <FullRow>
                <FormTextArea identifier="review" label="revisar" type="string" registration={register('review')} />
              </FullRow>
              <FullRow>
                <label htmlFor="rating" className="mt-2 block font-medium mb-2 text-dl-accent text-black text-sm ">clasificación</label>
                  <span className={`${rating === 'like' ? 'text-3xl' : 'text-2xl'} mr-2 cursor-pointer`} onClick={() => setRating('like')}>👍</span>
                  <span className={`${rating === 'dislike' ? 'text-3xl' : 'text-2xl'} text-2xl mr-2 mt-2 cursor-pointer`} onClick={() => setRating('dislike')}>👎</span>
              </FullRow>
            </CxCard>
          </CxWrap>
          : null
        } */}

        <div className="px-4 py-3 text-right sm:px-6 pb-8">
          <button
            type="button"
            onClick={_onReturnToAppointments}
            className="mr-2 rounded-full border border-gray-300 bg-white py-2 px-6 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-dl-accent focus:ring-offset-2"
          >
            Regresar
          </button>

          {selectionMode === 'complete' && (
            <button
              type="submit"
              className="bg-dl-primary-900 border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dl-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-700"
            >
              Completar Cita
            </button>
          )}

          {selectionMode === 'reschedule' && mergeDateTime(appointment.appDate, appointment?.appTime) >= new Date() && (
            <button
              type="submit"
              disabled={loading}
              className={`bg-dl-red border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-[#f16782] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-accent ${loading && 'bg-gray-500'}`}
            >
              Reprogramar Cita
            </button>
          )}

          {/* {
            selectionMode === 'edit' && (
              <button
              type="submit"
              className="bg-dl-primary-900 border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dl-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-700"
              >
                Actualizar cita
              </button>
            )
          } */}
        </div>
      </div>

      {openReturnAlert && (
        <CustomRedAlert
          open={openReturnAlert}
          title={'¿Desea descartar los cambios?'}
          bodyText={'La cita ha sido modificada, por favor confirme que desea salir de esta vista.'}
          onClose={_onCancelConfirmationForClosing}
          mainActionText={'Salir'}
          onMainAction={_onConfirmCancelAppointment}
          secondaryActionText={'Cancelar'}
          onSecondaryAction={_onCancelConfirmationForClosing}
        />
      )}

      {successModalOpen && <CustomSuccessModal buttonText={"Cerca"} title="" bodyText={"Registro actualizado con éxito"} buttonAction={() =>  onCancel()} open={successModalOpen}  appointment={appointment} updatedData={getValues()}/>}

      {showErrorModal && <CustomRedAlert open={showErrorModal} title={'Ocurrió un error.'} bodyText={errorBody} onClose={closeErrorMessage} />}

      {/* {showSuccessModal && <CustomSuccessModal title={'Registro actualizado con éxito'} bodyText={successBody} buttonText={'Aceptar'} buttonAction={_onsaveSuccesfullConfirmationClick} open={showSuccessModal} onClose={_onsaveSuccesfullConfirmationClick} />} */}


    </form>
  );
}
