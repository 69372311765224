import Axios from './Axios';

export const DoctorProfile = {
  getME: () => Axios.get('/doctor-data/whoami'),
  getProfile: (doctorId) => Axios.get(`/search-doctors/get-doctor-profile/${doctorId}`),
  getOwnProfile: (doctorId) => Axios.get(`/search-doctors/own-doctor-profile/${doctorId}`)
};

export const City = {
  getCityList: () => Axios.get('/cities/getList')
};

export const Language = {
  getAllLanguage: () => Axios.get('/language/getList'),
  saveLanguage: (values) => Axios.post('/doctor-profile/languages', values),
  getUserLanguage: () => Axios.get(`/doctor-profile/language/language-in-profile}`)
};

export const Experiences = {
  getAllExperiences: () => Axios.get('/doctor-profile/all-experiences'),
  createExperience: (values) => Axios.post('/doctor-profile/experience', values),
  updateExperience: (values) => Axios.patch('/doctor-profile/experience', values)
};

export const EducationS = {
  getAllEducations: () => Axios.get('/doctor-profile/all-educations'),
  createEducation: (values) => Axios.post('/doctor-profile/education', values),
  updateEducation: (values) => Axios.patch('/doctor-profile/education', values)
};

export const MotivesS = {
  getAllMotives: () => Axios.get('/doctor-profile/all-motives'),
  createMotives: (values) => Axios.post('/doctor-profile/motives', values),
  updateMotives: (values) => Axios.patch('/doctor-profile/motives', values)
};

export const SchedulesS = {
  getAllSchedules: () => Axios.get('/doctor-profile/all-schedules'),
  createSchedules: (values) => Axios.post('/doctor-profile/schedule', values),
  updateSchedules: (values) => Axios.patch('/doctor-profile/schedule', values)
};

export const SecondarySpecialty = {
  getAllSecondarySpecialty: () => Axios.get('/specialty/getList'),
  saveSecondarySpecialty: (idSpecialty) => Axios.post('/doctor-profile/specialty', idSpecialty),
  getUserSecondarySpecialty: () => Axios.get(`/doctor-profile/specialties-in-profile`)
};

export const Availability = {
  getAllAvailability: () => Axios.get('/doctor-profile/all-availabilities'),
  createAvailability: (values) => Axios.post('/doctor-profile/availability', values),
  updateAvailability: (values) => Axios.patch('/doctor-profile/availability', values)
};

export const InfoS = {
  getInfo: () => Axios.get('/doctor-profile/info'),
  updateInfo: (values) => Axios.patch('/doctor-profile/info', values)
};

export const Own = {
  getProfile: () => Axios.get('/doctor-profile/basic-doctor-profile'),
  updateProfile: (values) => Axios.patch('/doctor-profile/update-main-profile', values)
};
