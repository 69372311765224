export default function ProfileFormHeader(props) {
    const { title, initialText, secondaryText, textColor = 'text-black' } = props;
    const fontFamily = props.fontFamily ? 'aristotelicaFont' : ''
    // aristotelicaFont  font-aristotelica
    return (
        <div>
            <h3 className={`text-lg text-xl font-bold ${textColor} ${fontFamily}`}>{title}</h3>
            {initialText && <p className="mt-1 text-sm text-black font-normal">{initialText}</p>}
            {secondaryText && <p className="mt-1 text-sm text-black">{secondaryText}</p>}
        </div>
    );
}
