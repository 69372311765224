import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { ClockIcon } from '@heroicons/react/outline';
import { add, eachDayOfInterval, endOfMonth, format, getDay, isEqual, isSameDay, isSameMonth, isToday, parse, parseISO, startOfToday, setHours, setMinutes } from 'date-fns';
import FormDatePicker from '../../../../common/components/formElements/FormDatePicker';
import es from 'date-fns/locale/es';

import { z } from 'zod';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Appointment } from '../../../services/Appointment';

import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import SimpleDataTable from '../../../../doctorAppointmentManager/components/appointmentHistory/components/simpleDataTable/SimpleDataTable';
import Today from '../../../components/Today';

const formSchema = z.object({
  reason: z.string({ required_error: 'Requerido' }).nonempty({ message: 'Requerido' }),
  startTime: z.date().optional(),
  endTime: z
    .date()
    .refine(
      (value, data) => {
        if (data?.startTime === undefined) {
          return true; // No comparison needed when startTime is undefined
        }
        return value >= data?.startTime;
      },
      { message: 'Las horas de inicio deben ser posteriores o iguales a las horas de cierre', path: ['endTime'] }
    )
    .optional()
});

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

let colStartClasses = ['', 'col-start-2', 'col-start-3', 'col-start-4', 'col-start-5', 'col-start-6', 'col-start-7'];

const DaySelected = ({ selectedDay }) => <h2 className="font-bold">{'Día seleccionado  ' + format(selectedDay, 'MMM dd, yyy')}</h2>;

const BlockSlot = () => {
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);

  const { register, handleSubmit, formState, control, getValues, reset } = useForm({ resolver: zodResolver(formSchema) });
  const { errors } = formState;

  let today = startOfToday();
  let [selectedDay, setSelectedDay] = useState(today);
  let [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'));
  let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date());
  let days = eachDayOfInterval({ start: firstDayCurrentMonth, end: endOfMonth(firstDayCurrentMonth) });

  function previousMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
  }

  function nextMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
  }

  let selectedDayAppointments = [];

  const saveForm = (values) => {
    values.startTime = values.startTime ? format(values.startTime, 'HH:mm') : undefined;
    values.endTime = values.endTime ? format(values.endTime, 'HH:mm') : undefined;
    Appointment.blockSlot({ ...values, date: format(selectedDay, 'yyyy-MM-dd') })
      .then(() => {
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
        }, 3000);
      })
      .catch((err) => console.log(err));
  };

  const [allblockSlot, setSlots] = useState([]);

  useEffect(() => {
    Appointment.allblockSlot()
      .then((res) => setSlots(res.data))
      .catch((e) => console.log(e));
  }, []);

  const slotsColumns = useMemo(() => {
    return [
      { Header: 'Día', accessor: 'col1' },
      { Header: 'Razón', accessor: 'col2' },
      { Header: 'Hora de inicio', accessor: 'col3' },
      { Header: 'Hora final', accessor: 'col4' }
    ];
  }, []);

  const completedTable = useMemo(() => {
    return allblockSlot.map((slot) => {
      return {
        col1: slot.date,
        col2: slot.reason,
        col3: slot.startTime,
        col4: slot.endTime
      };
    });
  }, [allblockSlot]);

  const completedTableInstance = useTable(
    { columns: slotsColumns, data: completedTable },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!,
    useSortBy,
    usePagination
  );

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-10 side-bar-right bg-dl-screen-lightblue">
      {/* calendar */}
      <div>
        <Today />
      </div>
      <form onSubmit={handleSubmit(saveForm)}>
        <div className="bg-white calender-box box-radious mb-4">
          <div className="md:grid md:grid-cols-2 md:divide-x md:divide-gray-200">
            <div className="md:pr-14 ">
              <div className="flex items-center calender-top-head">
                <h2 className="flex-auto font-bold text-gray-900 capitalize calender-title mb-0">{format(firstDayCurrentMonth, 'MMMM yyyy', { locale: es })}</h2>
                <button type="button" onClick={previousMonth} className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Previous month</span>
                  <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                </button>
                <button type="button" onClick={nextMonth} className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Next month</span>
                  <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                </button>
              </div>
              <div className="grid grid-cols-7 mt-10 text-xl leading-6 text-center text-gray-500 week-name-list">
                {['D', 'L', 'M', 'M', 'J', 'V', 'S'].map((item, i) => (
                  <div key={i}>{item}</div>
                ))}
              </div>
              <div className="grid grid-cols-7 mt-2 text-xl">
                {days.map((day, dayIdx) => (
                  <div key={day.toString()} className={classNames(dayIdx === 0 && colStartClasses[getDay(day)], 'py-1.5')}>
                    <button
                      type="button"
                      disabled={day < today}
                      onClick={() => {
                        setSelectedDay(day);
                        reset({ startTime: undefined, endTime: undefined });
                        //   setShowDetail(false);
                      }}
                      className={classNames(
                        isEqual(day, selectedDay) && 'text-white',
                        !isEqual(day, selectedDay) && isToday(day) && 'text-dl-red',
                        !isEqual(day, selectedDay) && !isToday(day) && isSameMonth(day, firstDayCurrentMonth) && 'text-gray-900',
                        !isEqual(day, selectedDay) && !isToday(day) && !isSameMonth(day, firstDayCurrentMonth) && 'text-gray-400',
                        isEqual(day, selectedDay) && isToday(day) && 'bg-dl-red',
                        isEqual(day, selectedDay) && !isToday(day) && 'bg-dl-accent',
                        !isEqual(day, selectedDay) && 'hover:bg-gray-200',
                        (isEqual(day, selectedDay) || isToday(day)) && 'font-semibold',
                        day < today && 'cursor-not-allowed text-gray-300 text-lg	',
                        'mx-auto flex h-8 w-8 items-center justify-center rounded-full active-btn-number'
                      )}
                    >
                      <time dateTime={format(day, 'yyyy-MM-dd')}>{format(day, 'd')}</time>
                    </button>

                    <div className="w-2 h-2 mx-auto mt-1">{selectedDayAppointments.some((appointment) => isSameDay(parseISO(appointment.appDate), day)) && <div className="w-2 h-2 rounded-full bg-sky-500"></div>}</div>
                  </div>
                ))}
              </div>
            </div>
            <section className="mt-12 md:mt-0 calender-time-box pl-10">
              <div className="mb-4">
                <DaySelected selectedDay={selectedDay} />
              </div>
              <div className="grid grid-cols-6 gap-6 mb-4">
                <div className="col-start-1 col-end-4">
                  <Controller
                    control={control}
                    name="startTime"
                    //   rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <FormDatePicker
                          identifier="startTime"
                          label="Seleccionar hora de inicio "
                          errorMessage={errors?.startTime ? 'Este campo es requerido' : null}
                          iconComponent={<ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                          datePickerProps={{
                            onKeyDown: (e) => {
                              e.preventDefault();
                            },
                            selected: field.value,
                            dateFormat: 'HH:mm:ss',
                            onChange: (date) => field.onChange(date),
                            showTimeSelect: true,
                            showTimeSelectOnly: true,
                            timeIntervals: 15,
                            timeCaption: 'Hora',
                            dropdownMode: 'select',
                            minTime: setHours(setMinutes(new Date(), 0), 7),
                            maxTime: setHours(setMinutes(new Date(), 0), 20)
                          }}
                        />
                      );
                    }}
                  />
                </div>

                <div className="col-start-4 col-end-7">
                  <Controller
                    control={control}
                    name="endTime"
                    render={({ field }) => (
                      <FormDatePicker
                        identifier="endTime"
                        label="Seleccionar hora final"
                        errorMessage={errors?.endTime?.message}
                        iconComponent={<ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                        datePickerProps={{
                          onKeyDown: (e) => {
                            e.preventDefault();
                          },
                          selected: field.value,
                          dateFormat: 'HH:mm:ss',
                          onChange: (date) => field.onChange(date),
                          showTimeSelect: true,
                          showTimeSelectOnly: true,
                          timeIntervals: 15,
                          timeCaption: 'Hora',
                          dropdownMode: 'select',
                          minTime: setHours(setMinutes(new Date(), 0), 7),
                          maxTime: setHours(setMinutes(new Date(), 0), 20)
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="">
                <h4 className="title-info">Razón</h4>
                <textarea {...register('reason')} className=" w-full h-24 resize-none font-normal text-sm border border-gray-300 rounded-xl" placeholder="Ingrese el motivo para bloquear el espacio"></textarea>
                <span className="required">*Requerido</span>
              </div>
            </section>
          </div>
        </div>

        {alert ? (
          <div className="flex items-center justify-center mb-2">
            <div className="green-info-box w-1/2">
              <h5>Blocked slot:</h5>
              <DaySelected selectedDay={selectedDay} />
            </div>
          </div>
        ) : null}

        <div className="flex justify-center mt-20">
          <button type="submit" className="btn btn-primary btn-sm">
            Bloquear espacio
          </button>
        </div>
      </form>

      {/* table */}

      {allblockSlot.length ? <SimpleDataTable tableInstance={completedTableInstance} /> : null}
    </div>
  );
};

export default BlockSlot;
