import { KeyIcon } from '@heroicons/react/outline';

const navigation = [{ name: 'Información Personal', href: '#', icon: KeyIcon, current: true }];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function PatientUserProfileElement(props) {
  const { selectedItem, updateNavigation } = props;

  navigation.forEach((element, index) => {
    navigation[index].current = element.name === selectedItem;
  });

  const _onLinkClick = (event, tabName) => {
    event.preventDefault();
    updateNavigation(tabName);
  };

  return (
    <aside className="lg:col-span-2 bg-white side-bar-profile ss">
      <nav className="space-y-1 ml-3 mt-5">
        <div className="btn-sidebar flex flex-col gap-2 w-9/12 my-[50px] mx-auto">
          {navigation.map((item) => (
            <button key={item.name} className={classNames(item.current ? 'btn-primary' : 'btn-out-line', 'flex items-center justify-center !w-full')} onClick={(e) => _onLinkClick(e, item.name)}>
              <span className="truncate">{item.name}</span>
            </button>
          ))}
        </div>
      </nav>
    </aside>
  );
}
