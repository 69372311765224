import { useEffect, useState } from 'react';
import { Appointment } from '../../../services/Appointment';
import { add, addDays, eachDayOfInterval, endOfMonth, format, getDay, isEqual, isSameDay, isSameMonth, isToday, isWithinInterval, lastDayOfMonth, parse, parseISO, setDate, startOfToday, startOfTomorrow, subDays } from 'date-fns';
import es from 'date-fns/locale/es';
import { useInfoStore } from '../../../../App';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';

import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInputField from '../../../../common/components/formElements/FormInputField';
import Today from '../../../components/Today';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

let colStartClasses = ['', 'col-start-2', 'col-start-3', 'col-start-4', 'col-start-5', 'col-start-6', 'col-start-7'];

const formSchema = z.object({
  doctorNotes: z.string({ required_error: 'Requerido' }).nonempty({ message: 'Requerido' }),
  patientFirstName: z.string({ required_error: 'Requerido' }).nonempty({ message: 'Requerido' }),
  patientLastName: z.string({ required_error: 'Requerido' }).nonempty({ message: 'Requerido' }),
  patientEmail: z.string({ required_error: 'Requerido' }).email({message: 'correo electrónico invalida'}).nonempty({ message: 'Requerido' })
});

const CreateAppointmentByDoctor = () => {
  const { userInfo } = useInfoStore((state) => state);
  const navigate = useNavigate();
  const { state } = useLocation();

  const { register, handleSubmit, formState, getValues, setValue } = useForm({ resolver: zodResolver(formSchema) });
  const { errors } = formState;

  let today = startOfToday();
  let tomorrow = startOfTomorrow();
  let [selectedDay, setSelectedDay] = useState(today);
  let [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'));
  let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date());
  const [items, setItems] = useState([]);
  const [selectedItem, setItem] = useState(null);

  const fxGetAvailabity = (startDate, endDate) => {
    Appointment.getAvailability({ doctorId: userInfo?.id, startDate, endDate })
      .then((res) => setItems(res.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const startDate = format(subDays(new Date(today), 1), 'yyyy-MM-dd');
    const endDate = format(lastDayOfMonth(today), 'yyyy-MM-dd');
    fxGetAvailabity(startDate, endDate);
  }, []);

  let days = eachDayOfInterval({ start: firstDayCurrentMonth, end: endOfMonth(firstDayCurrentMonth) });

  function previousMonth() {
    const firstDayPreviousMonth = add(firstDayCurrentMonth, { months: -1 });
    if (firstDayCurrentMonth >= tomorrow) {
      let startDate;
      let endDate;
      setCurrentMonth(format(firstDayPreviousMonth, 'MMM-yyyy'));
      // if the month selected is the same as tomorrow
      if (isSameMonth(firstDayPreviousMonth, tomorrow)) {
        startDate = tomorrow;
        endDate = endOfMonth(tomorrow);
      } else {
        startDate = firstDayPreviousMonth;
        endDate = endOfMonth(firstDayPreviousMonth);
      }

      fxGetAvailabity(startDate.toISOString().slice(0, 10), endDate.toISOString().slice(0, 10));
    }
  }

  function nextMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));

    const startDate = firstDayNextMonth;
    const endDate = endOfMonth(firstDayNextMonth);
    fxGetAvailabity(startDate.toISOString().slice(0, 10), endDate.toISOString().slice(0, 10));
  }

  const saveForm = async (values) => {
    const { motive } = state;
    const obj = { idAppointmentMotive: motive.id, idAvailability: selectedItem?.id, appointmentDate: format(selectedDay, 'yyyy-MM-dd'), idDoctor: userInfo?.id, ...values };
    await Appointment.createAppointmentfromDoctor(obj)
      .then((res) => navigate('/appointment-successful', { state: { selectedItem } }))
      .catch((err) => console.log({ err }));
  };

  const isTimeBlocked = (date, appTime) => {
    const selectedTime = parseISO(`${date}T${appTime}`);

    for (const slot of items?.blockedSlots) {
      const startTime = parseISO(`${slot.date}T${slot.startTime}`);
      const endTime = parseISO(`${slot.date}T${slot.endTime}`);

      if (isWithinInterval(selectedTime, { start: startTime, end: endTime })) {
        return true;
      }
    }

    return false;
  };

  let selectedDayAppointments = items?.availableTimesResponse?.filter((appointment) => isSameDay(parseISO(`${appointment.date}T${appointment.appTime}`), selectedDay));
  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-10 side-bar-right bg-dl-screen-lightblue create-appoinment">
      {/* calendar */}
      <div className="wrapper-box">
        <Today />
        <div className="bg-white calender-box box-radious">
          <div className="md:grid md:grid-cols-2 md:divide-x md:divide-gray-200">
            <div className="md:pr-14  ">
              <div className="flex items-center calender-top-head">
                <h2 className="flex-auto font-bold text-gray-900 capitalize calender-title mb-0">{format(firstDayCurrentMonth, 'MMMM yyyy', { locale: es })}</h2>
                <button type="button" onClick={previousMonth} className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Previous month</span>
                  <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                </button>
                <button onClick={nextMonth} type="button" className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Next month</span>
                  <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                </button>
              </div>

              <div className="grid grid-cols-7 mt-10 text-xl leading-6 text-center text-gray-500 week-name-list">
                {['D', 'L', 'M', 'M', 'J', 'V', 'S'].map((item, i) => (
                  <div key={i}>{item}</div>
                ))}
              </div>
              <div className="grid grid-cols-7 mt-2 text-xl">
                {days.map((day, dayIdx) => (
                  <div key={day.toString()} className={classNames(dayIdx === 0 && colStartClasses[getDay(day)], 'py-1.5')}>
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedDay(day);
                        setItem(null);
                      }}
                      className={classNames(
                        isEqual(day, selectedDay) && 'text-white',
                        !isEqual(day, selectedDay) && isToday(day) && 'text-dl-red',
                        !isEqual(day, selectedDay) && !isToday(day) && isSameMonth(day, firstDayCurrentMonth) && 'text-gray-900',
                        !isEqual(day, selectedDay) && !isToday(day) && !isSameMonth(day, firstDayCurrentMonth) && 'text-gray-400',
                        isEqual(day, selectedDay) && isToday(day) && 'bg-dl-red',
                        isEqual(day, selectedDay) && !isToday(day) && 'bg-dl-accent',
                        !isEqual(day, selectedDay) && 'hover:bg-gray-200',
                        (isEqual(day, selectedDay) || isToday(day)) && 'font-semibold',
                        'mx-auto flex h-8 w-8 items-center justify-center rounded-full active-btn-number'
                      )}
                    >
                      <time dateTime={format(day, 'yyyy-MM-dd')}>{format(day, 'd')}</time>
                    </button>
                    <div className="flex mt-1 justify-center gap-1">
                      <div className="w-1 h-1">{items?.availableTimesResponse?.some((appointment) => isSameDay(parseISO(appointment.date), day)) && <div className="w-1 h-1 rounded-full bg-sky-500"></div>}</div>
                      <div className="w-1 h-1">{items?.blockedSlots?.some((slot) => isSameDay(parseISO(slot.date), day)) && <div className="w-1 h-1 rounded-full bg-red-500"></div>}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <section className="mt-12 md:mt-0 calender-time-box pl-10">
              <div className="mb-4">
                <h2 className="font-bold">Horarios disponibles para {format(selectedDay, 'MMM dd, yyy')}</h2>
                <p> Seleccione una hora</p>
              </div>

              <ul className="time-slot-name-text">
                {selectedDayAppointments?.length ? (
                  selectedDayAppointments.map((item, i) => (
                    <li
                      key={i}
                      className={classNames(selectedItem && selectedItem?.id === item?.id ? 'active' : '', isTimeBlocked(item.date, item.appTime) ? 'cursor-not-allowed pointer-events-none border border-red-400' : 'border border-blue-400')}
                      onClick={() => setItem(item)}
                    >
                      <span>{item.userFullName}</span>
                      <p className={classNames(isTimeBlocked(item.date, item.appTime) ? 'text-red-500' : '')}>{item.appTime}</p>
                    </li>
                  ))
                ) : (
                  <p className="no-time-show">Seleccione una hora</p>
                )}
              </ul>
            </section>
          </div>
        </div>
      
      {selectedItem ? (
        <div className="flex items-center justify-center">
          <div className="green-info-box w-1/2">
            <h5>Fecha seleccionada:</h5>
            <p>{selectedItem?.dayName + ' ' + selectedItem?.date + ' ' + selectedItem?.appTime}</p>
          </div>
        </div>
      ) : null}

      {/* details */}
      <form onSubmit={handleSubmit(saveForm)}>
        <div className="flex information-box-info common-box-white mt-20 ">
          <div className="information-box-info-L ">
            <div className="grid grid-cols-2 gap-4  mb-8">
              <div className="form-label-box">
                <FormInputField identifier="patientFirstName" label="Nombre del paciente" type="string" registration={register('patientFirstName')} errorMessage={errors.patientFirstName?.message} />
              </div>
              <div>
                <FormInputField identifier="patientLastName" label="Apellido del paciente" type="string" registration={register('patientLastName')} errorMessage={errors.patientLastName?.message} />
              </div>
              <div>
                <FormInputField identifier="patientEmail" label="Correo electrónico del paciente" type="string" registration={register('patientEmail')} errorMessage={errors.patientEmail?.message} />
              </div>
            </div>
            <div>
              <h4 className="title-info">Información adicional</h4>
              <textarea {...register('doctorNotes')} className="form-control" placeholder="Ingrese el motivo de la consulta o información adicional que considere importante para la cita"></textarea>
              <span className="required">*Requerido</span>
            </div>
          </div>
          {/* <div className="information-box-info-R">
            <button type="submit" className="btn btn-primary grey-btn" disabled={!selectedItem}>
              Confirmar
            </button>
          </div> */}
        </div>
        <div className="flex justify-center mt-20">
          <button type="submit" className="btn btn-primary btn-sm" disabled={!selectedItem}>
            Agendar cita
          </button>
        </div>
      </form>
    </div>
    </div>
  );
};

export default CreateAppointmentByDoctor;
