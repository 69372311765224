import { addDays, format, parse, startOfDay } from 'date-fns';

const today = new Date();

function parseTime(timeStr) {
  const [hours, minutes] = timeStr.split(':');
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
}

const AvailableUntil = ({ schedules = [] }) => {
  // Function to get the next available schedule date
  function getNextAvailableScheduleDate() {
    let nextScheduleDate = null;

    // Iterate through each schedule
    for (const schedule of schedules) {
      const scheduleDay = schedule.dayName;
      const scheduleStartTime = parseTime(schedule.openHours1);

      // Find the schedule that comes after today
      if (scheduleDay === format(today, 'EEEE') && today < scheduleStartTime) {
        nextScheduleDate = today;
        break;
      } else if (scheduleDay !== format(today, 'EEEE')) {
        const nextDay = addDays(today, 1);
        const nextDayStart = startOfDay(nextDay);
        const nextScheduleDateStr = `${format(nextDayStart, 'yyyy-MM-dd')} ${schedule.openHours1}`;
        nextScheduleDate = parse(nextScheduleDateStr, 'yyyy-MM-dd HH:mm', new Date());
        break;
      }
    }

    return nextScheduleDate;
  }

  // Usage
  const nextAvailableScheduleDate = getNextAvailableScheduleDate();

  return (
    <div className="appointment bg-sky-50 rounded-xl py-4 px-7 flex items-center mr-8 ">
      <figure>
        <svg width="43" height="39" viewBox="0 0 43 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M29.1047 25.6119H13.1837C12.3847 25.6119 11.7363 26.2639 11.7363 27.0672C11.7363 27.8704 12.3847 28.5224 13.1837 28.5224H29.1047C29.9037 28.5224 30.5521 27.8704 30.5521 27.0672C30.5521 26.2639 29.9037 25.6119 29.1047 25.6119Z"
            fill="black"
          />
          <path
            d="M38.0558 6.98507H34.8947V1.71134C34.8947 0.90806 34.2463 0.256119 33.4474 0.256119C32.6484 0.256119 32 0.90806 32 1.71134V6.98507H10V1.45522C10 0.65194 9.35158 0 8.55263 0C7.75368 0 7.10526 0.65194 7.10526 1.45522V6.98507H3.94421C1.21737 6.98507 -1 9.28433 -1 12.1075V33.8776C-1 36.7007 1.21737 39 3.94421 39H38.05C40.7768 39 42.9942 36.7007 42.9942 33.8776V12.1075C43 9.28433 40.7826 6.98507 38.0558 6.98507ZM3.94421 9.89552H38.05C39.1789 9.89552 40.0995 10.8851 40.0995 12.1075V16.0075H1.89474V12.1075C1.89474 10.8851 2.81526 9.89552 3.94421 9.89552ZM38.0558 36.0896H3.94421C2.81526 36.0896 1.89474 35.1 1.89474 33.8776V18.9179H40.1053V33.8776C40.1053 35.1 39.1847 36.0896 38.0558 36.0896Z"
            fill="black"
          />
        </svg>
      </figure>
      <figcaption className="pl-5 text-center">
        <h4 className="text-md text-black font-bold mb-1">Disponibilidad</h4>
        <p className="text-md">hasta {nextAvailableScheduleDate?.toLocaleDateString()}</p>
      </figcaption>
    </div>
  );
};

export default AvailableUntil;
