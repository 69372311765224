import { NewspaperIcon, PhoneIcon, SupportIcon } from '@heroicons/react/outline';
import img2 from './../../images/young-doctor-supporting-his-patient.jpg';
import docterTeam from './../../images/team-dcoters.png';
import { useEffect, useState } from 'react';
import axios from 'axios';

const API_SERVER = process.env.REACT_APP_API_SERVER;

// const supportLinks = [
//     {
//         name: 'Manual',
//         href: '#',
//         description:
//             'Varius facilisi mauris sed sit. Non sed et duis dui leo, vulputate id malesuada non. Cras aliquet purus dui laoreet diam sed lacus, fames.',
//         icon: PhoneIcon
//     },
//     {
//         name: 'Preguntas frecuentes',
//         href: '#',
//         description:
//             'Varius facilisi mauris sed sit. Non sed et duis dui leo, vulputate id malesuada non. Cras aliquet purus dui laoreet diam sed lacus, fames.',
//         icon: SupportIcon
//     },
//     {
//         name: 'Contáctanos',
//         href: '#',
//         description:
//             'puedes escribirnos al siguiente correo:  doctolinkapp@gmail.com.',
//         icon: NewspaperIcon
//     }
// ];
export default function DoctorInformation({ authenticatedUser }) {
   const [manuals, setManuals] = useState();
   useEffect(() => {
      getManuals();
   }, [])

   const getManuals = async () => {
      const res = await axios.get(`${API_SERVER}/manuals/list`);
      setManuals(res.data);
   }
   return (
      <div className="bg-dl-screen-lightblue pt-12">
         {/* Header */}
         <div className="relative  pt-6 docters-team-bg">
            {/* <img
         // h-full object-cover
         className="w-full h-full object-cover"
         src={img2}
         alt=""
         /> */}
            <div className="wrapper-box">
               <div className='flex docters-team-main-flex'>
                  <div className='docters-team-main-L'>
                     <div className='docters-team-main-L-title-content pb-8 max-w-md'>
                        <h1 className="docters-team-title mb-3 	">
                           El equipo de Doctolink <br></br>esta a tu servicio
                        </h1>
                        <div className="docters-team-title-sub">
                           <p className="text-center text-xl font-light">
                              Conectate para asistirte o darte más detalles
                           </p>
                        </div>
                     </div>
                     {/* Overlapping cards */}
                     {/* 
               <section
                  className="team-docter-card"
                  aria-labelledby="contact-heading"
                  >
                  <h2 className="sr-only" id="contact-heading">
                     Soporte DoctoLink
                  </h2>
                  <div className="grid grid-cols-1  lg:grid-cols-2 lg:gap-y-0 gap-12">
                     {supportLinks.map((link) => (
                     <div key={link.name} className="flex flex-col bg-white rounded-2xl shadow-xl doc-link-box">
                        <div className="flex-1 relative ">
                           <h3 className="text-xl font-medium text-dl-accent pb-5 team-docter-title">{link.name}</h3>
                           <p className="mt-4 text-base text-gray-500">{link.description}</p>
                           <div className="icon-box bg-pinq">
                              <link.icon className="h-10 w-10 text-black" aria-hidden="true" />
                           </div>
                        </div>
                        <div className=" bg-gray-50  rounded-br-2xl  consultar-link">
                           <a
                              href={link.href}
                              className="text-base font-medium text-dl-secondary hover:text-dl-secondary/[.8]"
                              >
                           Consultar<span aria-hidden="true"> &rarr;</span>
                           </a>
                        </div>
                     </div>
                     ))}
                  </div>
               </section>
               */}
                     <section
                        className="team-docter-card"
                        aria-labelledby="contact-heading"
                     >
                        <h2 className="sr-only" id="contact-heading">
                           Soporte DoctoLink
                        </h2>
                        <div className="grid grid-cols-1  lg:grid-cols-2 lg:gap-y-0 gap-16">
                           <a href={!authenticatedUser || authenticatedUser?.usertype == 1 ? `${API_SERVER}/${(manuals ? manuals[0] : [])?.manualUrl}` : `${API_SERVER}/${(manuals ? manuals[2] : [])?.manualUrl}`} target="_blank" rel="noopener noreferrer">

                              <div className="flex flex-col bg-white rounded-2xl shadow-xl doc-link-box rounded-[42px]">

                                 <div className="flex-1 relative ">
                                    <h3 className="text-xl font-medium text-dl-accent pb-5 team-docter-title">Manual</h3>

                                    <div className="icon-box ">
                                       <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M7.74958 8.68613C7.74958 8.47173 7.74958 8.31421 7.74958 8.16106C7.74958 6.58149 7.74077 5.00629 7.75399 3.42671C7.76721 1.80776 8.92647 0.569477 10.5485 0.398831C10.7072 0.385704 10.8659 0.376953 11.0246 0.376953C16.6093 0.376953 22.194 0.376953 27.7831 0.376953C29.4713 0.376953 30.7054 1.3352 31.0096 2.86664C31.0581 3.10292 31.0581 3.34795 31.0581 3.58861C31.0625 9.95941 31.0625 16.3258 31.0581 22.6966C31.0537 25.5014 29.0702 27.466 26.2359 27.466C19.1878 27.4704 12.1398 27.4704 5.09167 27.466C2.59245 27.466 0.723536 25.9258 0.318017 23.5455C0.269531 23.2567 0.273939 22.9548 0.273939 22.6573C0.269531 19.0168 0.269531 15.3807 0.269531 11.7403C0.269531 9.84565 1.43319 8.69488 3.35059 8.6905C4.63767 8.68613 5.92916 8.6905 7.21624 8.6905C7.37492 8.68613 7.52919 8.68613 7.74958 8.68613ZM8.662 25.6895C8.72812 25.6939 8.8339 25.707 8.9441 25.707C14.7448 25.707 20.5455 25.707 26.3461 25.7027C26.6018 25.7027 26.8663 25.6851 27.1131 25.6239C28.4883 25.2913 29.3126 24.1756 29.3126 22.6529C29.3126 16.8247 29.3126 10.9964 29.3126 5.16818C29.3126 4.59936 29.3258 4.03054 29.3082 3.46172C29.2817 2.66099 28.7396 2.13593 27.9329 2.13593C22.2469 2.13155 16.5608 2.13155 10.8747 2.14468C10.5838 2.14468 10.2532 2.27157 10.0064 2.43346C9.55678 2.72662 9.49067 3.21231 9.49067 3.71988C9.49508 9.99004 9.49508 16.2602 9.49067 22.526C9.49067 22.9767 9.49067 23.4492 9.37166 23.878C9.19534 24.495 8.90884 25.0813 8.662 25.6895ZM7.73195 10.432C6.13632 10.432 4.58037 10.4101 3.02441 10.4451C2.40732 10.4582 2.01943 10.9439 2.01943 11.6134C2.01502 15.3807 2.0018 19.1437 2.02825 22.911C2.03706 24.4075 3.20513 25.6064 4.63326 25.6983C6.39639 25.812 7.74517 24.5563 7.74958 22.7667C7.7584 18.7718 7.75399 14.7813 7.74958 10.7864C7.74958 10.6901 7.74077 10.5895 7.73195 10.432Z" fill="black" />
                                          <path d="M19.3902 5.6322C21.1665 5.6322 22.9429 5.6322 24.7192 5.6322C25.4729 5.6322 25.7947 5.94724 25.7947 6.69546C25.7991 8.31442 25.7991 9.93774 25.7947 11.5567C25.7947 12.2874 25.4509 12.6156 24.7104 12.62C21.1709 12.6243 17.6359 12.6243 14.0964 12.62C13.3603 12.62 13.0253 12.283 13.0209 11.5479C13.0165 9.92899 13.0165 8.30566 13.0209 6.68671C13.0253 5.94287 13.3471 5.63658 14.1052 5.63658C15.8683 5.6322 17.627 5.6322 19.3902 5.6322ZM24.0051 7.39555C20.9109 7.39555 17.8695 7.39555 14.8193 7.39555C14.8193 8.55507 14.8193 9.68396 14.8193 10.8216C17.8959 10.8216 20.9461 10.8216 24.0051 10.8216C24.0051 9.66646 24.0051 8.54632 24.0051 7.39555Z" fill="black" />
                                          <path d="M19.4037 16.5535C17.6141 16.5535 15.8289 16.5579 14.0394 16.5491C13.2812 16.5447 12.8228 15.9234 13.1137 15.3065C13.2944 14.9258 13.625 14.7902 14.0261 14.7902C14.5683 14.7858 15.1149 14.7902 15.657 14.7902C18.6191 14.7902 21.5811 14.7902 24.5388 14.7902C24.7107 14.7902 24.8826 14.7902 25.0501 14.8208C25.4952 14.9039 25.795 15.2671 25.7906 15.6871C25.7818 16.1072 25.4688 16.4616 25.028 16.536C24.887 16.5579 24.7415 16.5535 24.6005 16.5535C22.8638 16.5535 21.1359 16.5535 19.4037 16.5535Z" fill="black" />
                                          <path d="M19.4036 18.7279C21.1932 18.7279 22.9872 18.7235 24.7768 18.7323C25.5349 18.7367 25.9801 19.3186 25.7112 19.9312C25.5481 20.3118 25.2308 20.4781 24.8208 20.4781C24.0054 20.4781 23.1899 20.4781 22.3701 20.4781C19.6064 20.4781 16.8427 20.4781 14.0746 20.4781C13.2812 20.4781 12.8051 19.8612 13.1093 19.2355C13.312 18.8154 13.6867 18.7279 14.1143 18.7279C15.8818 18.7323 17.6449 18.7279 19.4036 18.7279Z" fill="black" />
                                       </svg>
                                    </div>
                                 </div>
                                 <div className=" bg-gray-50  rounded-br-2xl  consultar-link mt-12">
                                    <a href={!authenticatedUser || authenticatedUser?.usertype == 1 ? `${API_SERVER}/${(manuals ? manuals[0] : [])?.manualUrl}` : `${API_SERVER}/${(manuals ? manuals[2] : [])?.manualUrl}`} target="_blank">     Consultar<span aria-hidden="true"> &rarr;</span></a>
                                 </div>
                              </div>
                           </a>
                           <a href={`${API_SERVER}/${(manuals ? manuals[1] : [])?.manualUrl}`} target="_blank" rel="noopener noreferrer">
                              <div className="flex flex-col bg-white rounded-2xl shadow-xl doc-link-box rounded-[42px]">
                                 <div className="flex-1 relative ">
                                    <h3 className="text-xl font-medium text-dl-accent pb-5 team-docter-title">Preguntas frecuentes</h3>

                                    <div className="icon-box bg-pinq">
                                       <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M25.235 11.6639C25.235 12.146 25.235 12.6248 25.235 13.1069C25.2216 13.1772 25.1982 13.2442 25.1948 13.3145C25.0743 15.032 24.6222 16.6591 23.7918 18.1657C21.8765 21.6342 18.9667 23.7568 15.0658 24.5068C14.597 24.5971 14.1182 24.6373 13.6427 24.7009C9.55431 24.7009 5.46589 24.7009 1.37746 24.7009C0.801532 24.5034 0.597278 24.0949 0.600626 23.499C0.61402 19.8263 0.607323 16.1569 0.607323 12.4842C0.607323 10.4118 1.03592 8.43315 2.04715 6.6219C4.30733 2.57085 7.7763 0.374585 12.4072 0.090008C14.6272 -0.0472588 16.7467 0.441545 18.6989 1.51624C22.1745 3.42459 24.2941 6.34067 25.0408 10.2411C25.1312 10.7098 25.1714 11.1885 25.235 11.6639ZM2.53267 22.7759C2.66326 22.7759 2.75701 22.7759 2.85077 22.7759C6.13892 22.7759 9.42373 22.7792 12.7119 22.7725C13.2242 22.7725 13.7398 22.7491 14.2455 22.6821C17.393 22.2636 19.8474 20.7035 21.6053 18.082C23.025 15.9661 23.5507 13.6058 23.2226 11.0747C22.8107 7.91756 21.237 5.4568 18.6085 3.69912C16.4889 2.28293 14.1283 1.76734 11.6002 2.08875C8.87458 2.43693 6.64118 3.70247 4.89666 5.80499C3.27937 7.75351 2.52932 10.0234 2.53602 12.5545C2.54271 15.8556 2.53936 19.16 2.53936 22.4611C2.53267 22.5549 2.53267 22.652 2.53267 22.7759Z" fill="black" />
                                          <path d="M11.5936 14.241C11.6003 13.1162 12.0657 12.2324 12.963 11.5628C13.2643 11.3385 13.5523 11.0908 13.8067 10.8163C14.1583 10.4413 14.3324 9.98937 14.1416 9.47048C13.9608 8.97836 13.5355 8.79758 13.0668 8.75072C12.7454 8.71724 12.4005 8.72393 12.0925 8.80763C11.838 8.87793 11.6103 9.0721 11.3961 9.24283C11.2755 9.33657 11.2019 9.49391 11.1048 9.62112C10.8101 10.0095 10.4686 10.1032 10.0334 9.91572C9.62152 9.7383 9.40724 9.34996 9.47755 8.90471C9.58469 8.22178 9.98648 7.74305 10.549 7.38484C11.8581 6.54791 14.0076 6.62156 15.2632 7.53549C16.3045 8.29542 16.6192 9.61108 16.05 10.766C15.742 11.3887 15.3101 11.9043 14.7911 12.3562C14.5266 12.5872 14.2521 12.8115 14.0043 13.0592C13.7097 13.3505 13.5623 13.7187 13.5355 14.1372C13.5222 14.3615 13.5121 14.5858 13.4987 14.8101C13.4853 15.0746 13.3447 15.2687 13.1003 15.319C12.8157 15.3792 12.5177 15.406 12.2264 15.3926C11.915 15.3759 11.7175 15.1717 11.6606 14.8603C11.6271 14.6528 11.617 14.4452 11.5936 14.241Z" fill="black" />
                                          <path d="M11.2788 17.874C11.2788 17.1643 11.8581 16.575 12.5512 16.5784C13.2443 16.5817 13.8337 17.1743 13.837 17.8674C13.8404 18.557 13.2477 19.1563 12.5546 19.1597C11.8581 19.1697 11.2788 18.5838 11.2788 17.874Z" fill="black" />
                                       </svg>
                                    </div>
                                 </div>
                                 <div className=" bg-gray-50  rounded-br-2xl  consultar-link mt-12">
                                    <a href={`${API_SERVER}/${(manuals ? manuals[1] : [])?.manualUrl}`} target="_blank">
                                       Consultar<span aria-hidden="true"> &rarr;</span></a>
                                 </div>
                              </div>
                           </a>
                        </div>
                     </section>
                  </div>
                  <div className='docters-team-main-R'>
                     <figure className=''><img
                        className="w-full h-full object-cover mix-blend-multiply"
                        src={docterTeam}
                        alt=""
                     /></figure>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}