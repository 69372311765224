import React from 'react';
import { CxCard, CxWrap, Line } from '../../components/Sx';
import { useForm } from 'react-hook-form';
import FormInputField from '../../../common/components/formElements/FormInputField';
import FormTextArea from '../../../common/components/formElements/FormTextArea';
import { useLocation, useNavigate } from 'react-router-dom';
import { Appointment } from '../../services/Appointment';
import { useState } from 'react';
const AppointmentDetail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { patient, type } = state;
  const { register, handleSubmit,formState } = useForm({
    defaultValues: {
      birthDate: patient?.birthDate ?? '',
      appDate: patient?.appDate ?? '',
      appTime: patient?.appTime ?? '',
      apptID: patient?.apptID ?patient?.apptID : patient?.idappt_without_user? patient?.idappt_without_user: '',
      doctorFullName: patient?.doctorFullName ?? '',
      doctorNotes: patient?.doctorNotes ?? '',
      idDoctorUser: patient?.idDoctorUser ?? '',
      idUser: patient?.idUser ?? '',
      motiveDescription: patient?.motiveDescription ?? '',
      motiveName: patient?.motiveName ?? '',
      statusName: patient?.statusName ?? '',
      userEmail: patient?.userEmail ?? '',
      userFullName: patient?.userFullName ?? '',
      userNotes: patient?.userNotes ?? '',
      userPhone: patient?.userPhone ?? '',
      cancelInstructions : ''
    }
  });

  const [cancelInstructionsError, setCancelInstructionError] = useState(false)
  const saveForm = (values) => {
    if(type === "reschedule"){
      if(values.cancelInstructions.length<= 0){
        setCancelInstructionError(true)
        return
      }
      setCancelInstructionError(false)
      const cancelAppontment = { cancelInstructions: values.cancelInstructions, idAppointment: values.apptID };
      Appointment.cancelAppointment(cancelAppontment).then(()=>navigate(-1));
    }
    if(type === "add"){
      const {cancelInstructions , ...rest } = values;
      // Appointment.cancelAppointment(cancelAppontment).then(()=>navigate(-1));
    }
  };

  return (
    <div className="bg-dl-screen-lightblue p-20">
      <div className="min-h-full grid grid-flow-col ">
        <div className="col-span-12 md:col-span-8">
          <form className="space-y-6" onSubmit={handleSubmit(saveForm)}>
            <div className="rounded-2xl overflow-hidden mt-0 mb-45">
              <CxWrap>
                <CxCard>Datos del paciente</CxCard>
                <Line />
                <CxCard>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="form-label-box">
                      <FormInputField identifier="userFullName" label="Numbre del paciente" type="string" registration={register('userFullName')} disabled />
                    </div>
                    <div className="form-label-box">
                      <FormInputField identifier="birthDate" label="Fecha de nacimmiento" type="string" registration={register('birthDate')} disabled/>
                    </div>
                    <div className="form-label-box">
                      <FormInputField identifier="appDate" label="Fecha de cita" type="string" registration={register('appDate')} disabled/>
                    </div>
                    <div className="form-label-box">
                      <FormInputField identifier="appTime" label="Hora de cita" type="string" registration={register('appTime')} disabled/>
                    </div>
                    <div className="form-label-box">
                      <FormInputField identifier="userPhone" label="Telefona" type="string" registration={register('userPhone')} disabled/>
                    </div>
                    <div className="form-label-box">
                      <FormInputField identifier="userEmail" label="Correo electronico" type="string" registration={register('userEmail')} disabled/>
                    </div>
                    <div className="form-label-box">
                      <FormInputField identifier="Sexo" label="Sexo" type="string" registration={register('sex')} disabled />
                    </div>
                    <div className="form-label-box">
                      <FormInputField identifier="Tipo de Sangre" label="Tipo de Sangre" type="string" registration={register('blood_type')} disabled />
                    </div>
                  </div>
                </CxCard>
              </CxWrap>
            </div>
            <div className="rounded-2xl overflow-hidden mt-0 mb-45">
              <CxWrap>
                <CxCard>Contacto</CxCard>
                <Line />
                <CxCard>
                  <div className="grid grid-cols-6 gap-4">
                    <div className="col-start-1 col-end-3 ">
                      <FormInputField identifier="Motivo de ls consulta" label="Motivo de ls consulta" type="string" registration={register('motiveName')} disabled/>
                    </div>
                    <div className="col-start-1 col-end-7 ">
                      <FormInputField identifier="Notas del paciente" label="Notas del paciente" type="string" registration={register('userNotes')} disabled />
                    </div>
                  </div>
                </CxCard>
              </CxWrap>
            </div>
            <div className="rounded-2xl overflow-hidden mt-0 mb-45">
              <CxWrap>
                <CxCard>Notas del doctor</CxCard>
                <Line />
                <CxCard>
                  <div className="grid grid-cols-6 gap-4">
                    <div className="col-start-1 col-end-7 ">
                      <FormTextArea identifier="Notas de la cita" label="Notas de la cita" type="string" registration={register('doctorNotes')} disabled/>
                    </div>
                  </div>
                </CxCard>
              </CxWrap>
            </div>
            {type === 'reschedule' ? (
              <div className="rounded-2xl overflow-hidden mt-0 mb-45">
                <CxWrap>
                  <CxCard>Mensaje para el paciente</CxCard>
                  <Line />
                  <CxCard>
                    <div className="grid grid-cols-6 gap-4">
                      <div className="col-start-1 col-end-7 ">
                        <FormTextArea identifier="Notas de la cita" label="Motivo de cancelación" type="string" registration={register('cancelInstructions')} />
                        {cancelInstructionsError ? <p className="text-red-700 font-light">Este campo no puede ser vacío</p> : null}</div>
                    </div>
                  </CxCard>
                </CxWrap>
              </div>
            ) : null}
            <div className="rounded-2xl overflow-hidden mt-0 mb-45">
              <CxWrap>
                <CxCard>Estado actual de la cita</CxCard>
                <Line />
                <CxCard>
                  <div className="grid grid-cols-6 gap-4">
                    <div className="col-start-1 col-end-7 ">
                      <FormInputField identifier="statusName" label="Pendiente" type="string" registration={register('statusName')} disabled/>
                    </div>
                  </div>
                </CxCard>
              </CxWrap>
            </div>
            <div className="text-right">
              <button
                className="bg-white border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-700"
                onClick={() => navigate(-1)}
              >
                Regresar
              </button>
              {type === 'reschedule' ? (
                <button className="ml-2 bg-red-400 border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-700">
                  Reprogramar cita
                </button>
              ) : null}
               {type === 'add' ? (
                <button className="ml-2 btn-primary !w-auto border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-700">
                  Actualizar cita
                </button>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AppointmentDetail;
