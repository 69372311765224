import NavigationElements from "./pageElements/NavigationElements";
import ContactUsFormElement from "./pageElements/ContactUsFormElement";
import ContactUsFAQSElement from "./pageElements/ContactUsFAQSElement";
import useAuthentication from "../api/hooks/useAuthentication";

export default function ContactUsPage(props) {

    const {displayNavigation, onUpdateDisplayNavigation} = props;
    const { submitContactUs } = useAuthentication();
  
    return <>
        <NavigationElements
            displayNavigation={displayNavigation}
            onUpdateDisplayNavigation={onUpdateDisplayNavigation}
        />
        <ContactUsFormElement onContactUsSubmit={submitContactUs}/>
        <ContactUsFAQSElement />
    </>
}
