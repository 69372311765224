import { CardWrap, HorariosIcon } from './DoctorResultsTabsElement';

const Horarios = ({ doctorData }) => {
  return (
    <CardWrap>
      <div className="px-4 py-4 sm:px-6 border-b divide-slate-200 border-black flex items-center  horarios-box-head">
        <HorariosIcon />
        <h2 id="applicant-information-title" className="text-lg leading-6 font-bold text-black sm:px-6 border-red horarios-title">
          Horarios
        </h2>
      </div>
      <div className="border-t border-gray-200 px-12 pt-5 pb-10">
        <dl className="bullet-list-box">
          <ul>
            {doctorData?.schedules?.map((item,i) => (
              <li key={i} className="text-base text-black pb-5">{item.dayName + ' ' + item.openHours1 + ' ' + item.openHours2} </li>
            ))}
            {/* <li className="text-base text-black ">Sábado 9:00 a.m 12:00 p.m</li> */}
          </ul>
        </dl>
      </div>
    </CardWrap>
  );
};

export default Horarios;
