import { useNavigate } from 'react-router-dom';
import AppointmentCalendar from '../../components/appointmentCalendar/AppointmentCalendar';
import usePendingAppointments from '../../doctorAppointmentManager/components/pendingAppointments/hooks/usePendingAppointments';
import Today from '../../v2/components/Today';

const Calendar = () => {
  const navigate = useNavigate();
  const { pendingAppointments, setSelectedAppointment, setSelectionMode } = usePendingAppointments();

  const viewAppointmentDetail = (appointment) => {
    setSelectedAppointment(appointment);
    setSelectionMode('complete');
    navigate('/appointment-manager-doctor');
  };

  const viewAppointmentReschedule = (appointment) => {
    setSelectedAppointment(appointment);
    setSelectionMode('reschedule');
    navigate('/appointment-manager-doctor');
  };

  return (
    <div className="calender-main-page pt-20 pb-20">
      <div className="wrapper-box">
        <div className="name-docter-info-box common-box-white flex">
          <div className="name-docter-info-box-L flex">
            <figure>
              <span></span>
            </figure>
            <figcaption>
              <h4 className="title-info">Nombre del médico</h4>
              <span className="blue-tag"> Oftalmología</span>
            </figcaption>
          </div>
          <hr className="border-right"></hr>
          <div className="name-docter-info-box-R">
            <h4 className="title-info">Selecciona la dirección</h4>
            <ul className="radio-text-list">
              <li>
                <label class="radio-coustom-design mr-5">
                  <input type="radio" checked="checked" name="radio" />
                  <span class="checkmark"></span>
                  <p>Av Manuel Enrique Araujo, número 624, San Salvador, San Salvador</p>
                </label>
              </li>
              <li>
                <label class="radio-coustom-design mr-5">
                  <input type="radio" checked="checked" name="radio" />
                  <span class="checkmark"></span>
                  <p>Av Manuel Enrique Araujo, número 624, San Salvador, San Salvador</p>
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div className="calender-box">
          <Today />
          <h2 className="calender-title">Seleccione una fecha disponible para realizar la cita</h2>
          <AppointmentCalendar pendingAppointments={pendingAppointments} setSelectedAppointment={viewAppointmentDetail} onRescheduleAppointment={viewAppointmentReschedule} />
        </div>
        <div className="flex information-box-info common-box-white mt-20 ">
          <div className="information-box-info-L ">
            <h4 className="title-info">Información adicional</h4>
            <textarea className="form-control" placeholder="Ingrese el motivo de la consulta o información adicional que considere importante para la cita"></textarea>
            <span className="required">*Requerido</span>
          </div>
          <div className="information-box-info-R">
            <button className="btn btn-primary grey-btn">Confirmar</button>
          </div>
        </div>
        <div className="flex information-box-info common-box-white mt-20 ">
          <div className="name-docter-info-box-L flex">
            <figure>
              <span></span>
            </figure>
            <figcaption>
              <h4 className="title-info">Nombre del paciente</h4>
              <span className="blue-tag"> 9:00 a.m.</span>
            </figcaption>
          </div>
          <hr className="border-right"></hr>
          <div className="name-docter-info-box-C">
            <h4 className="title-info">Detalles:</h4>
            <ul className="Detalles-list">
              <li className="mb-2">
                <p>Edad: 38 años</p>
              </li>
              <li>
                <p>Motivo de la consulta: Examen físico anual.</p>
              </li>
            </ul>
          </div>
          <div className="information-box-info-R">
            <button className="btn btn-primary grey-btn">Confirmar</button>
          </div>
        </div>
      </div>
      <div class="modal fade appoinment-successfully-modal" id="appoinment-successfully" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              {/* <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button> */}
            </div>
            <div class="modal-body">
              <div className="appoinment-successfully-inner text-center">
                <div className="top-img-box text-center">
                  <figure className="text-center">
                    <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M47 94C72.9574 94 94 72.9574 94 47C94 21.0426 72.9574 0 47 0C21.0426 0 0 21.0426 0 47C0 72.9574 21.0426 94 47 94ZM64.5294 35.2561C65.516 34.0308 65.3225 32.2377 64.0972 31.251C62.8718 30.2644 61.0787 30.4579 60.092 31.6832L40.2332 56.3466L33.5507 48.0603C32.5632 46.8358 30.7699 46.6436 29.5453 47.6312C28.3207 48.6187 28.1286 50.412 29.1161 51.6366L38.0176 62.6745C38.5586 63.3453 39.3742 63.7352 40.236 63.7348C41.0978 63.7345 41.9131 63.344 42.4536 62.6728L64.5294 35.2561Z"
                        fill="#00B3FF"
                      />
                    </svg>
                  </figure>
                  <h3>La cita ha sido generada exitosamente</h3>
                </div>
                <div className="modal-btn-group flex">
                  <span className="blue-tag">Ir a mis citas pendientes</span>
                  <button className="btn-primary btn-md btn-sm light-bg">Finalizar</button>
                </div>
                <div className="green-info-box">
                  <h5>Fecha seleccionada:</h5>
                  <p>Viernes 20 de enero de 2023, 9:00 a.m</p>
                </div>
                <div className="direction-info">
                  <div className="direction-info-L">
                    <figure>
                      <span></span>
                    </figure>
                    <figcaption>
                      <h4>Nombre del médico</h4>
                      <span className="blue-tag">Oftalmología</span>
                    </figcaption>
                  </div>
                  <div className="direction-info-R">
                    <figure>
                      <svg width="33" height="46" viewBox="0 0 33 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.5 24.9555C20.9551 24.9555 24.5667 21.2867 24.5667 16.7611C24.5667 12.2355 20.9551 8.5668 16.5 8.5668C12.0449 8.5668 8.43335 12.2355 8.43335 16.7611C8.43335 21.2867 12.0449 24.9555 16.5 24.9555Z"
                          fill="#00B3FF"
                        ></path>
                        <path
                          d="M33 16.7611C33 7.50526 25.6117 0 16.5 0C7.38833 0 0 7.50526 0 16.7611C0 24.3893 11.2017 39.1689 15.147 44.1377H6.05C5.544 44.1377 5.13333 44.5548 5.13333 45.0688C5.13333 45.5828 5.544 46 6.05 46H16.1333C16.61 46 16.9987 45.6275 17.0427 45.1508C19.888 41.6384 33 25.0113 33 16.7611ZM16.5 1.86235C24.5887 1.86235 31.1667 8.54445 31.1667 16.7611C31.1667 23.1453 21.604 36.476 16.5 42.8899C11.396 36.476 1.83333 23.1453 1.83333 16.7611C1.83333 8.54445 8.41133 1.86235 16.5 1.86235Z"
                          fill="black"
                        ></path>
                      </svg>
                      <h3>Dirección</h3>
                    </figure>
                    <p>Av. Manuel Enrique Araujo, número 624, San Salvador.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
