import WhiteCardContainer from '../../../../common/components/containers/forms/WhiteCardContainer';

import FormFieldsContainer from '../../../../common/components/containers/forms/FormFieldsContainer';
import ProfileFormHeader from '../../../../doctorUser/components/ProfileFormHeader/ProfileFormHeader';
import FormInputField from '../../../../common/components/formElements/FormInputField';
import HalfRow from '../../../../common/components/formElements/HalfRow';
import FullRow from '../../../../common/components/formElements/FullRow';
import FormTextArea from '../../../../common/components/formElements/FormTextArea';
import FormButton from '../../../../common/components/formElements/FormButton';
import FormFieldsFooter from '../../../../common/components/containers/forms/FormFieldsFooter';
import FormSelectDrop from '../../../../common/components/formElements/FormSelectDrop';
import CustomRedAlert from '../../../../common/components/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../../common/components/customSuccessModal/CustomSuccessModal';
import FormResetButton from '../../../../common/components/formElements/FormResetButton';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { EducationS } from '../../../services/DoctorProfile';
import EducationList from '../../../../doctorUser/components/doctorOwnProfile/education/components/educationList/EducationList';
import { CxCard, CxTabButton, CxWrap, Line } from '../../../components/Sx';
import Loader from '../../../components/Loader';

const statusOptions = [
  { id: true, label: 'Activo' },
  { id: false, label: 'Inactivo' }
];

export default function Education(props) {
  const [education, setEducation] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorBody, setErrorBody] = useState('');
  const [successBody, setSuccessBody] = useState('');
  const [defaultValues, setDefaultValues] = useState({});
  const [selectedEducation, setSelectedEducation] = useState(null);
  // const isEditing = JSON.stringify(defaultValues) !== '{}';
  const [isEditing, setEditing] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { register, handleSubmit, formState, getValues, reset, setValue } = useForm(defaultValues);
  const { errors, isDirty } = formState;

  useEffect(() => {
    setIsLoading(true);
    EducationS.getAllEducations()
      .then((res) => {
        setEducation(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });

    // setErrorMessage('No se pudo completar la solicitud de educación. Inténtelo de nuevo.');
  }, [refresh]);

  useEffect(() => {
    setIsLoading(true);
    if (selectedEducation) {
      setDefaultValues(selectedEducation);
      reset(selectedEducation);
      setEditing(true)
      setValue('status', selectedEducation.status ? 'true' : 'false', { shouldValidate: true });
    }

    setIsLoading(false);
  }, [reset, selectedEducation, setValue]);

  const resetState = () => {
    setShowSuccessModal(false);
    setShowErrorModal(false);
    setErrorBody('');
    setSuccessBody('');
    setDefaultValues({});
    setSelectedEducation(null);
    reset({ educationName: '', educationDetail: '' });
    setValue('status', '', { shouldValidate: true });
    setRefresh(!refresh);
    setEditing(false)
  };

  const _createErrorMessage = (errorMessage) => {
    setShowErrorModal(true);
    setErrorBody(errorMessage);
  };

  const closeErrorMessage = () => {
    setShowErrorModal(false);
    setErrorBody('');
  };

  const _createSuccessMessage = (errorMessage) => {
    setShowSuccessModal(true);
    setSuccessBody(errorMessage);
    // resetState()
  };

  const saveEducation = (data) => {
    if (!data) {
      _createErrorMessage('No se pudo obtener información válida para guardar. Inténtelo de nuevo.');
      return;
    }
    if (JSON.stringify(defaultValues) === '{}') {
      executeCreateEducation(data);
    } else {
      const adjustedData = { ...data, idEducation: data.id, status: data.status === 'true' };
      doUpdateEducation(adjustedData);
    }
  };

  const executeCreateEducation = async (newEducation) => {
    const createNewEducation = await EducationS.createEducation(newEducation);

    if (createNewEducation) {
      _createSuccessMessage('¡Se ha guardado el registro con éxito!');
    }
  };

  const doUpdateEducation = async (updatedEducationObject) => {
    const executeUpdatedEducation = await EducationS.updateEducation(updatedEducationObject);

    if (executeUpdatedEducation) {
      _createSuccessMessage('¡Se ha actualizado el registro con éxito!');
    }
  };

  const onCloseSuccessMessage = () => {
    resetState();
  };

  if (isLoading) return <Loader />;
  return (
  <>
    <form onSubmit={handleSubmit(saveEducation)}>
      <CxWrap>
        <CxCard>
          <ProfileFormHeader
            title={!isEditing ? 'Agregar Educación' : 'Actualizar Educación'}
            initialText={'Ingresa los datos que te interesa que el paciente conozca sobre tu educación.'}
          />
        </CxCard>
        <Line />
        <CxCard>
          <div className="grid grid-cols-6 gap-6">
            <HalfRow>
              <FormInputField
                identifier={'educationName'}
                label={'Título de la educación'}
                type={'text'}
                placeholder={'Ej. Universidad El Salvador - Doctor en Medicina General (1990-1998)'}
                helperText={'Requerido'}
                registration={register('educationName', { required: true })}
                errorMessage={errors.educationName ? 'Este campo es requerido' : null}
              />
            </HalfRow>
            <FullRow>
              <FormTextArea
                label={'Detalle'}
                identifier={'educationDetail'}
                rows={3}
                placeholder={'Ej. Educación principal sobre medicina, con un diplomado en enfermedades virales (obtenido en 1995)'}
                helperText={'Requerido'}
                registration={register('educationDetail', { required: true })}
                errorMessage={errors.educationDetail ? 'Este campo es requerido' : null}
              />
            </FullRow>
            {isEditing && (
              <HalfRow>
                <FormSelectDrop
                  label={'Estado'}
                  identifier={'status'}
                  options={statusOptions}
                  defaultValue={defaultValues.status}
                  helperText={'Requerido'}
                  registration={register('status', { required: true })}
                  errorMessage={errors.status ? 'Este campo es requerido' : null}
                />
              </HalfRow>
            )}
          </div>
          {/* <div className="h-40"></div> */}
          </CxCard>
        <hr className="mt-2  pt-2 "></hr>

        <div className="flex justify-end gap-2  px-10 py-4">
              <CxTabButton label="Cancelar" variant="outlined" onClick={resetState} />
              <CxTabButton label={isEditing ? 'Actualizar' : 'Guardar'} disabled={isEditing && !isDirty} />
            </div>
      
      </CxWrap>
      {showSuccessModal && <CustomSuccessModal title={'Acción ejecutada con éxito!'} bodyText={successBody} buttonText={'Cerrar'} buttonAction={onCloseSuccessMessage} open={showSuccessModal} onClose={onCloseSuccessMessage} />}
      {showErrorModal && <CustomRedAlert open={true} title={'Ocurrió un error.'} bodyText={errorBody} onClose={closeErrorMessage} />}
    </form>
    <div className="ml-1">
        <div className="ml-5">
          <ProfileFormHeader title={'Educación existentes'} fontFamily="font-aristotelica" />
        </div>
        {education.length === 0 && <p className="ml-4 mt-4">No hay motivos para mostrar</p>}
        {education.length > 0 && <EducationList items={education} onClick={setSelectedEducation} />}
    </div>
  </>
  );
}
