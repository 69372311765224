import { useInfoStore } from '../../App';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
const Today = () => {
  // Get today's date
  const today = new Date();
  const day = today.getDate();
  const monthIndex = today.getMonth();
  const year = today.getFullYear();

  const { userInfo: user } = useInfoStore((state) => state);
  const spanishMonths = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

  const location = useLocation();

  const showButton = location.pathname === '/appointment-manager-doctor' || location.pathname === '/appointment-manager';
  const formattedDate = `${day} ${spanishMonths[monthIndex]} ${year}`;
  return (
    <div className="calender-show-date flex-auto mb-12">
      <figure>
        <svg width="76" height="67" viewBox="0 0 76 67" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M67.46 12H62V2.94C62 1.56 60.88 0.44 59.5 0.44C58.12 0.44 57 1.56 57 2.94V12H19V2.5C19 1.12 17.88 0 16.5 0C15.12 0 14 1.12 14 2.5V12H8.54C3.83 12 0 15.95 0 20.8V58.2C0 63.05 3.83 67 8.54 67H67.45C72.16 67 75.99 63.05 75.99 58.2V20.8C76 15.95 72.17 12 67.46 12ZM8.54 17H67.45C69.4 17 70.99 18.7 70.99 20.8V27.5H5V20.8C5 18.7 6.59 17 8.54 17ZM67.46 62H8.54C6.59 62 5 60.3 5 58.2V32.5H71V58.2C71 60.3 69.41 62 67.46 62Z"
            fill="#005CB9"
          ></path>
          <path
            d="M33.0006 54C32.2106 54 31.4406 53.63 30.9506 52.93L26.4506 46.51C25.6606 45.38 25.9306 43.82 27.0606 43.03C28.1906 42.24 29.7506 42.51 30.5406 43.64L33.6506 48.08L46.1306 39.93C47.2906 39.18 48.8406 39.5 49.5906 40.66C50.3406 41.82 50.0206 43.37 48.8606 44.12L34.3606 53.59C33.9406 53.87 33.4706 54 33.0006 54Z"
            fill="#005CB9"
          ></path>
        </svg>
      </figure>
      <figcaption>
        <div className="relative">
          <div className="left-0">
            <h4>Bienvenido a su agenda</h4>
            <h2>Hoy,{formattedDate}</h2>
          </div>
        </div>
      </figcaption>
      {!showButton && (
        <div className=" ml-auto">
          <Link to={String(user.usertype) === '2' ? '/appointment-manager-doctor' : '/appointment-manager'} className="rounded-full citas-btn-out-line flex items-center justify-center !w-36">
            Ir a mis citas
          </Link>
        </div>
      )}
    </div>
  );
};

export default Today;
