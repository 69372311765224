export default function ControlledFormSelectDrop(props) {
  const { label, identifier, autoComplete, options, helperText, errorMessage, defaultValue, value, onChange, disabled, registration } = props;
  var emptyValue = props.emptyValue ? props.emptyValue:' -- Seleccione '
  return (
    <>
      <label htmlFor={identifier} className="block text-sm font-medium text-gray-700 mb-2 text-sm text-black">
        {label}
      </label>
      <select
        id={identifier}
        name={identifier}
        autoComplete={autoComplete}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        disabled={disabled}
        className="mt-1 block w-full bg-white border border-gray-300 rounded-full shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-500 focus:border-dl-primary-500 sm:text-sm font-normal	"
        {...registration}
      >
        <option key="empty-select-value" value="">
          {emptyValue}
        </option>
        {options &&
          options.map((opt) => {
            return (
              <option key={opt.key || opt.id} value={opt.id} disabled={opt.disabled}>
                {opt.label}
              </option>
            );
          })}
      </select>
      {!errorMessage && helperText && (
        <p className="mt-2 text-sm text-gray-500 font-normal text-right text-black" id={`${identifier}-description`}>
        {helperText}
      </p>
        
      )}
      {errorMessage ? <p className="mt-2 text-sm text-red-700">{errorMessage}</p> : null}
    </>
  );
}
